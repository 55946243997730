import React, { FC, useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useInView } from "react-intersection-observer";
import {
  Avatar,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import moment from "moment";
import { IMSkuItem } from "../../models/Sku";

import { useSkus } from "../../hooks/useSkus";
import Loadings from "../../compoment/common/Loadings";
import {
  BRANDS,
  CURRENCY,
  BRANDS_SKU,
  IMAGE_FROM_WEBSITES,
  SKU_VISIBILITY,
  SKU_STATUS,
} from "../../utils/constant";
import { formatePrice } from "../../utils/common";
import dayjs, { Dayjs } from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import axios from "axios";
import { useInfiniteQuery, useQuery } from "react-query";


const StatisticsByBrand: FC = () => {
  const [values, setValues] = useState<{
    specificDate: Dayjs;
  }>({
    specificDate: dayjs("01-06-2023", "DD-MM-YYYY"),
  });

  const handleChange = (e: { target: { name: any; value: any } }) => {
    setValues({
      ...values,
      [e.target.name]: e.target.value,
    });
  };


  const { data, isLoading} = useQuery(['revenueByBrand', values.specificDate], async () => {
    const response = await axios.post(`/api/v1/orders/getAllOrderList`, {
      revenueByBrand: values.specificDate.format("YYYY-MM-DD"),
    });
    return response.data.data;
  });

  console.log(values.specificDate.format("YYYY-MM-DD"), "data")

  const currentDate = new Date();

  // Calculate the date of a week ago
  const lastWeekDate = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    currentDate.getDate() - 7
  );
  const yesterdayDate = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    currentDate.getDate() - 1
  );

  // Format the date as desired
  const formattedLastWeekDate = `${
    lastWeekDate.getMonth() + 1
  }/${lastWeekDate.getDate()}/${lastWeekDate.getFullYear()}`;
  const formattedYesterdayDate = `${
    yesterdayDate.getMonth() + 1
  }/${yesterdayDate.getDate()}/${yesterdayDate.getFullYear()}`;

 
  return (
    <Box sx={{margin:" 10px 10px 10px 10px", textAlign:"center"}}>
      {isLoading ? ( <Box
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          height={"calc( 100vh - 160px)"}
        >
          <Loadings />
        </Box>):(
      <><Typography variant="h6">Revenue By Brand</Typography><TableContainer sx={{ maxHeight: "calc( 100vh - 160px)" }}>
            <Table stickyHeader aria-label="simple table">
              <TableHead>
                <TableRow
                  sx={{
                    "& > th ": {
                      textAlign: "center",
                      fontWeight: 700,
                      backgroundColor: "#514943",
                      color: "white",
                      borderRight: "1px solid #8A837F",
                    },
                    "& > td": {
                      textAlign: "center",
                    }
                  }}
                >
                  <TableCell sx={{ width: "20%" }}>By Brand</TableCell>
                  <TableCell>Vitalisium DE</TableCell>
                  <TableCell>Vitalisium ES</TableCell>
                  <TableCell>Apojilab</TableCell>
                </TableRow>
              </TableHead>
              <TableBody
                sx={{
                  "& > tr": {
                    border: 0,
                  },
                  "& > tr:nth-of-type(even)": {
                    backgroundColor: "#F5F5F5",
                  },
                  "& > tr > td": {
                    textAlign: "center",
                  }
                }}
              >
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <TableRow
                    sx={{ "& > td ": { borderRight: "1px dashed #d6d6d6" } }}
                  >
                    <TableCell>
                      Today : {moment(new Date()).format("MMMM Do YYYY")}
                    </TableCell>
                    <TableCell>{data?.todayNVH_3Revenue??"0"}</TableCell>
                    <TableCell>{data?.todayNVH_4Revenue??"0"}</TableCell>
                    <TableCell>{data?.todayApoRevenue??"0"}</TableCell>
                  </TableRow>
                </LocalizationProvider>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <TableRow
                    sx={{ "& > td ": { borderRight: "1px dashed #d6d6d6" } }}
                  >
                    <TableCell>
                      <DatePicker
                        label="Specific Date"
                        // defaultValue={dayjs(new Date())}
                        value={values.specificDate}
                        onChange={(newValue) => handleChange({
                          target: { name: "specificDate", value: newValue },
                        })} />
                    </TableCell>
                    <TableCell>{data?.specificDateNVH_3Revenue??"0"}</TableCell>
                    <TableCell>{data?.specificDateNVH_4Revenue??"0"}</TableCell>
                    <TableCell>{data?.specificDateApoRevenue??"0"}</TableCell>
                  </TableRow>
                </LocalizationProvider>
                <TableRow sx={{ "& > td ": { borderRight: "1px dashed #d6d6d6" } }}>
                  <TableCell sx={{ textAlign: "center", backgroundColor: "#d6d6d6" }} colSpan={4}>Last Week {formattedLastWeekDate} ~ {formattedYesterdayDate} </TableCell>
                </TableRow>
                <TableRow sx={{ "& > td ": { borderRight: "1px dashed #d6d6d6" } }}>
                  <TableCell>Mon</TableCell>
                  <TableCell>{data?.mondayNVH_3Revenue??"0"}</TableCell>
                  <TableCell>{data?.mondayNVH_4Revenue??"0"}</TableCell>
                  <TableCell>{data?.mondayApoRevenue??"0"}</TableCell>
                </TableRow>
                <TableRow sx={{ "& > td ": { borderRight: "1px dashed #d6d6d6" } }}>
                  <TableCell>Tue</TableCell>
                  <TableCell>{data?.tuesdayNVH_3Revenue??"0"}</TableCell>
                  <TableCell>{data?.tuesdayNVH_4Revenue??"0"}</TableCell>
                  <TableCell>{data?.tuesdayApoRevenue??"0"}</TableCell>
                </TableRow>
                <TableRow sx={{ "& > td ": { borderRight: "1px dashed #d6d6d6" } }}>
                  <TableCell>Wed</TableCell>
                  <TableCell>{data?.wednesNVH_3Revenue??"0"}</TableCell>
                  <TableCell>{data?.wednesNVH_4Revenue??"0"}</TableCell>
                  <TableCell>{data?.wednesApoRevenue??"0"}</TableCell>
                </TableRow>
                <TableRow sx={{ "& > td ": { borderRight: "1px dashed #d6d6d6" } }}>
                  <TableCell>Thu</TableCell>
                  <TableCell>{data?.thursdayNVH_3Revenue??"0"}</TableCell>
                  <TableCell>{data?.thursdayNVH_4Revenue??"0"}</TableCell>
                  <TableCell>{data?.thursdayApoRevenue??"0"}</TableCell>
                </TableRow>
                <TableRow sx={{ "& > td ": { borderRight: "1px dashed #d6d6d6" } }}>
                  <TableCell>Fri</TableCell>
                  <TableCell>{data?.fridayNVH_3Revenue??"0"}</TableCell>
                  <TableCell>{data?.fridayNVH_4Revenue??"0"}</TableCell>
                  <TableCell>{data?.fridayApoRevenue??"0"}</TableCell>
                </TableRow>
                <TableRow sx={{ "& > td ": { borderRight: "1px dashed #d6d6d6" } }}>
                  <TableCell>Sat</TableCell>
                  <TableCell>{data?.saturdayNVH_3Revenue??"0"}</TableCell>
                  <TableCell>{data?.saturdayNVH_4Revenue??"0"}</TableCell>
                  <TableCell>{data?.saturdayApoRevenue??"0"}</TableCell>
                </TableRow>
                <TableRow sx={{ "& > td ": { borderRight: "1px dashed #d6d6d6" } }}>
                  <TableCell>Sun</TableCell>
                  <TableCell>{data?.sundayNVH_3Revenue??"0"}</TableCell>
                  <TableCell>{data?.sundayNVH_4Revenue??"0"}</TableCell>
                  <TableCell>{data?.sundayApoRevenue??"0"}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer></>
      )}
    </Box>
  );
};

export default StatisticsByBrand;
