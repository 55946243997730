import { FC, useState } from "react";
import {
  Box,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import ArrowDropDownCircleOutlinedIcon from "@mui/icons-material/ArrowDropDownCircleOutlined";
import { IMAppliedTax } from "../../../models/Order";
import { CURRENCY } from "../../../utils/constant";
import { formatePrice } from "../../../utils/common";

export interface IOrderTotalsInfo {
  base_shipping_amount: number;
  base_subtotal: number;
  base_subtotal_incl_tax: number;
  base_tax_amount: number;
  base_tax_invoiced: number;
  base_total_due: number;
  base_total_refunded: number;
  base_grand_total: number;
  base_total_paid: number;
  applied_taxes: IMAppliedTax[];
  currencycode: string;
}

interface OrderTotalsInfoProps {
  orderTotalsInfo: IOrderTotalsInfo;
}

const OrderTotals: FC<OrderTotalsInfoProps> = ({ orderTotalsInfo }) => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <Typography variant="h6">Order Totals</Typography>
        <TableContainer>
          <Table aria-label="simple table">
            <TableBody>
              <TableRow>
                <TableCell sx={{ fontWeight: "700" }}>Subtotal</TableCell>
                <TableCell>
                  {CURRENCY[orderTotalsInfo.currencycode]}{" "}
                  {formatePrice(orderTotalsInfo.base_subtotal)}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ fontWeight: "700" }}>
                  Shipping & Handling
                </TableCell>
                <TableCell>
                  {CURRENCY[orderTotalsInfo.currencycode]}{" "}
                  {formatePrice(orderTotalsInfo.base_shipping_amount)}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ fontWeight: "700" }}>
                  {" "}
                  <IconButton
                    aria-label="expand row"
                    size="small"
                    onClick={() => setOpen(!open)}
                  >
                    {open ? (
                      <ArrowDropDownCircleOutlinedIcon />
                    ) : (
                      <ArrowDropDownCircleOutlinedIcon />
                    )}
                  </IconButton>
                  Total Tax
                </TableCell>
                <TableCell>
                  {CURRENCY[orderTotalsInfo.currencycode]}{" "}
                  {formatePrice(orderTotalsInfo.base_tax_amount)}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  style={{ paddingBottom: 0, paddingTop: 0 }}
                  colSpan={2}
                >
                  <Collapse in={open} timeout="auto" unmountOnExit>
                    <Box sx={{ margin: 1 }}>
                      <Table size="small" aria-label="purchases">
                        <TableBody>
                          {orderTotalsInfo.applied_taxes.map((tax, index) => (
                            <TableRow key={index}>
                              <TableCell sx={{ borderBottom: 0 }}>
                                {`${tax.title} ${tax.percent}%`}
                              </TableCell>
                              <TableCell sx={{ borderBottom: 0 }}>
                                {CURRENCY[orderTotalsInfo.currencycode]}{" "}
                                {formatePrice(tax.base_amount)}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </Box>
                  </Collapse>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ fontWeight: "700" }}>Grand Total</TableCell>
                <TableCell>
                  {CURRENCY[orderTotalsInfo.currencycode]}{" "}
                  {formatePrice(orderTotalsInfo.base_grand_total)}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ fontWeight: "700" }}>Total Paid</TableCell>
                <TableCell>
                  {CURRENCY[orderTotalsInfo.currencycode]}{" "}
                  {formatePrice(orderTotalsInfo.base_total_paid)}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ fontWeight: "700" }}>Total Refunded</TableCell>
                <TableCell>
                  {CURRENCY[orderTotalsInfo.currencycode]}{" "}
                  {formatePrice(orderTotalsInfo.base_total_refunded)}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ fontWeight: "700" }}>Total Due</TableCell>
                <TableCell>
                  {CURRENCY[orderTotalsInfo.currencycode]}{" "}
                  {formatePrice(orderTotalsInfo.base_total_due)}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </>
  );
};

export default OrderTotals;
