import { FC } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";

export interface IAccountInfo {
  customerName: string;
  customerEmail: string;
}

interface AccountInfoProps {
  accountInfo: IAccountInfo;
}

const AccountInfo: FC<AccountInfoProps> = ({ accountInfo }) => {
  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <Typography variant="h6">Account Information</Typography>
        <TableContainer>
          <Table aria-label="simple table">
            <TableBody>
              <TableRow >
                <TableCell sx={{ fontWeight: '700' }}>Customer Name</TableCell>
                <TableCell align="right"> {accountInfo.customerName}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ fontWeight: '700' }}>Email</TableCell>
                <TableCell align="right">
                  {" "}
                  {accountInfo.customerEmail}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </>
  );
};

export default AccountInfo;
