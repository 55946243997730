import React, { FC, useState } from "react";
import {
  Box,
  Button,
  TextField,
  FormControl,
  Snackbar,
  FormHelperText,
  FormControlLabel,
  Checkbox,
  Typography,
  createTheme,
  ThemeProvider,
  Grid,
  CssBaseline,
  Paper,
  Avatar,
} from "@mui/material";
import { Link, useSearchParams } from "react-router-dom";
import * as authService from "../../../services/authService";
import { setUser } from "../../../redux/slices/authSlice";
import { Formik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { useAppDispatch } from "../../../redux/store";
import { useNavigate } from "react-router-dom";
import { verify } from "crypto";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";

const defaultTheme = createTheme();
interface LoginProps {}

const Verify: FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [verifyCode, setVerifyCode] = useState("");
  const [message, setMessage] = React.useState("");
  const [searchparams] = useSearchParams();
  const handleChange = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setVerifyCode(event.target.value);
  };

  const handleClick = async () => {
    // console.log(verifyCode)

    const email = searchparams.get("email");
    if (verifyCode) {
      if (email) {
        try {
          authService
            .loginWithVerifyCode(email, verifyCode)
            .then((res: any) => {
              dispatch(setUser(res.user));
            })
            .catch((err) => {
              // console.log(err.response.data.error.message, "err");
              setMessage(err.response.data.error.message);
              dispatch(setUser(null));
            });
        } catch (err: any) {
          // navigate("/orders/");

          setMessage("error");
        }
      }

      // if (response.data.status === "Success") {
      //   setMessage("You are welcome");

      // navigate("/orders/");
      // } else {
      //   setMessage("Wrong!");
      // }
    } else {
      setMessage("Input verify code.");
    }
  };
  return (
    <ThemeProvider theme={defaultTheme}>
      <Grid
        container
        component="main"
        sx={{
          height: "100vh",
          backgroundImage: "url(/assets/bg3.jpg)",
          backgroundRepeat: "no-repeat",
          backgroundColor: (t) =>
            t.palette.mode === "light"
              ? t.palette.grey[50]
              : t.palette.grey[900],
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <CssBaseline />
        <Grid item xs={false} sm={6} md={8} />
        <Snackbar
          open={!!message}
          autoHideDuration={6000}
          onClose={() => {
            setMessage("");
          }}
          message={message}
        />
        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          component={Paper}
          elevation={6}
          square
          sx={{ opacity: "80%", backgroundColor: "#103d7c" }}
        >
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5" sx={{ color: "white" }}>
              Input your verify code
            </Typography>

            <TextField
            className="sign-in-side"
              sx={{width:"90%",marginTop:"18px"}}
              name="verifyCode"
              value={verifyCode}
              fullWidth
              onChange={handleChange}
              id="verifyCode"
              label="Verify Code"
              variant="outlined"
              // sx={{ mb: 1 }}
              autoComplete="email"
              autoFocus
              InputLabelProps={{
                style: { color: "white" },
              }}
            />
            <Box sx={{ display: "flex", justifyContent: "center" , marginTop:"18px", width:"90%"}}>
              <Button
                sx={{width:"100%"}}
                variant="contained"
                // sx={{ mb: 1 }}
                onClick={handleClick}
              >
                Verify
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
};

export default Verify;
