import React, { FC, useEffect, useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import { useInView } from "react-intersection-observer";
import {
  Avatar,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

import { IMSkuItem } from "../../models/Sku";

import { useSkus } from "../../hooks/useSkus";

import Filters from "./Filters";
import Loadings from "../../compoment/common/Loadings";
import { BRANDS, CURRENCY, BRANDS_SKU,IMAGE_FROM_WEBSITES, SKU_VISIBILITY, SKU_STATUS } from "../../utils/constant";
import { formatePrice } from "../../utils/common";
import dayjs, { Dayjs } from 'dayjs';

const Sku: FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const filters = useMemo<{
    //  id: string;
    sku: string;
    name: string;
    brands: string[];
    start_date: Dayjs;
    end_date: Dayjs;
  }>(() => {
    const filters = {
      // id: searchParams.get("id") || "",
      sku: searchParams.get("sku") || "",
      name: searchParams.get("name") || "",
      brands: searchParams.get("brands")?.split(",") || BRANDS,
      start_date: dayjs(searchParams.get("start_date") || "",'YYYY-MM-DD'),
      end_date: dayjs(searchParams.get("end_date") || "",'YYYY-MM-DD'),
    };

    return filters;
  }, [searchParams]);

  const { data, isLoading, isFetchingNextPage, fetchNextPage, hasNextPage } =
    useSkus(filters);

  const { ref, inView } = useInView({ rootMargin: "500px" });

  useEffect(() => {
    if (inView) {
      fetchNextPage();
    }
  }, [inView, fetchNextPage]);
 
  return (
    <Box>
      <Filters />
      {isLoading ? (
        <Box
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          height={"calc( 100vh - 160px)"}
        >
          <Loadings />
        </Box>
      ) : (
        <TableContainer sx={{ maxHeight: "calc( 100vh - 160px)" }}>
          <Table stickyHeader aria-label="simple table">
            <TableHead>
              <TableRow
                 sx={{
                  "& > th ": {
                    fontWeight: 700,
                    backgroundColor: "#514943",
                    color: "white",
                    borderRight: "1px solid #8A837F",
                  },
                }}
              >
                <TableCell>Id</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Thumbnail</TableCell>
                <TableCell>Created At</TableCell>
                <TableCell>Updated At</TableCell>
                <TableCell>Type</TableCell>
                <TableCell>Attribute Set</TableCell>
                <TableCell>SKU</TableCell>
                <TableCell>Price</TableCell>
                {/* <TableCell>Quantity</TableCell>
                <TableCell>Salable Quantity</TableCell> */}
                <TableCell>Visibility</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>WebSites</TableCell>
                {/* <TableCell>Invoice</TableCell>*/
                <TableCell align="center">Action</TableCell> }
              </TableRow>
            </TableHead>
            <TableBody  sx={{
                "& > tr": {
                  border: 0 ,
                },
                "& > tr:nth-of-type(even)": {
                  backgroundColor: "#F5F5F5",
                },
              }}>
              {data?.pages?.map((page: any) => (
                <React.Fragment key={page.currentPage}>
                  {page?.items?.map((sku: IMSkuItem) => (
                    <TableRow
                      key={sku.brand + sku.id}
                      sx={{ "& > td ": { borderRight: "1px dashed #d6d6d6" } }}
                    >
                      {/* <>{console.log(sku, "sku")}</> */}
                      <TableCell scope="row">{sku.id}</TableCell>
                      <TableCell align="left">{sku.name}</TableCell>
                      <TableCell>
                        <>
                        { <img
                          alt="Remy Sharp"
                       //  src={'https://pre-prod.vitalisium.com/media/catalog/product/cache/fa1a458e361943d977bc9ef96d3db31f/p/r/prosta360_1.jpg'}
                          //  src={'https://pre-prod.vitalisium.com/media/catalog/product/cache/fa1a458e361943d977bc9ef96d3db31f'+'${sku.media_gallery_entries?.map((index) => index.file)}'}
                          // src={'https://pre-prod.vitalisium.com/media/catalog/product/cache/fa1a458e361943d977bc9ef96d3db31f' + `${sku.media_gallery_entries?.[0]?.file}`}
                          src={`${IMAGE_FROM_WEBSITES[Number(sku.extension_attributes.website_ids?.[0])] + sku.media_gallery_entries?.[0]?.file}`}
                          width="100px" height="100px"

                        /> }
                     
                        {/* {sku.media_gallery_entries?.[0]?.file} */}
                        </>
                      </TableCell>
                      <TableCell>{sku.created_at}</TableCell>
                      <TableCell>{sku.updated_at}</TableCell>

                      <TableCell sx={{ textTransform: "capitalize" }}>{sku.type_id}</TableCell>
                      <TableCell>{"Default"}</TableCell>
                      <TableCell>{sku.sku}</TableCell>
                      <TableCell>
                        {"€ "+ sku.price}
                      </TableCell>
                      {/* {<TableCell>{"  ddd"}</TableCell>}
                      {<TableCell>{"salable quantity"}</TableCell> } */}
                      <TableCell>{SKU_VISIBILITY[sku.visibility]}</TableCell>
                      <TableCell>{SKU_STATUS[sku.status]}</TableCell>
                      <TableCell>
                        {/* {sku.extension_attributes.wesite_ids?.map((index)=>(
                          index.toString()
                        ))} */}
                      
                        {BRANDS_SKU[Number(sku.extension_attributes.website_ids?.[0])]}

                      </TableCell>
                      {/* <TableCell>{"invoice"}</TableCell> */}
                      <TableCell>
                     
                        <a
                          target={"_blank"}
                          href={`/skuorderdetail/${sku.brand}/${sku.id}`}
                          rel="noreferrer"
                          style={{marginRight:"16px"}}
                         
                        >
                           Order Info 
                        </a>
                        <a
                          target={"_blank"}
                          href={`/skuinvoicedetail/${sku.brand}/${sku.id}`}
                          rel="noreferrer"
                         
                        >
                              Invoice Info
                        </a>
                      </TableCell> 
                    </TableRow>
                  ))}
                </React.Fragment>
              ))}
              {hasNextPage && (
                <TableRow
                  ref={ref}
                  sx={{ height: "248px" }}
                  hidden={!hasNextPage}
                >
                  <TableCell colSpan={99} align="center">
                    {isFetchingNextPage && (
                      <Box display={"flex"} justifyContent={"center"}>
                        <Loadings />
                      </Box>
                    )}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Box>
  );
};

export default Sku;
