import React, { FC, useState } from "react";
import { Outlet, Navigate } from "react-router-dom";
import {
  AppBar,
  Avatar,
  Box,
  Button,
  Card,
  CardMedia,
  Container,
  IconButton,
  Link,
  Menu,
  MenuItem,
  Toolbar,
  Tooltip,
  Typography,
  collapseClasses,
  makeStyles,
} from "@mui/material";
import { useSelector } from "react-redux";
import {
  selectIsLogged,
  selectWaitAuthCheck,
  setUser,
  selectUser,
} from "../redux/slices/authSlice";
import MenuIcon from "@mui/icons-material/Menu";
import AdbIcon from "@mui/icons-material/Adb";
import { useAppDispatch } from "../redux/store";
import { NavLink } from "react-router-dom";
import { userInfo } from "os";
import logo from "../logo.png";

const pages = [
  "ORDERS",
  "INVOICES",
  "SKUS",
  "EFFISYS",
  "EFFISYSRECEPT",
  "STATISTICS",
  "SMART DASHBOARD",
  // "MAINTENANCE"
];
const Main: FC = () => {
  const isLogged = useSelector(selectIsLogged);
  const currentUser = useSelector(selectUser);
  const waitAuthCheck = useSelector(selectWaitAuthCheck);
  const dispatch = useAppDispatch();

  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleLogout = () => {
    handleCloseUserMenu();
    localStorage.removeItem("token");
    dispatch(setUser(null));
  };

  const handleMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();

    const button: HTMLButtonElement = event.currentTarget;
  };

  // console.log(waitAuthCheck, "waitAuthCheck");
  const token = localStorage.getItem("token");
  // if (!isLogged && !waitAuthCheck)
  if (!isLogged && !waitAuthCheck) {
    return <Navigate to={"/auth/login"} />;
  }
  // const useStyles = makeStyles({
  //   media: {
  //     height: 0,
  //     paddingTop: '56.25%', // 16:9 aspect ratio
  //   },
  // });
  // const classes = useStyles();
  return (
    <Box>
      <AppBar position="static">
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <img width={"48px"} src="/assets/logo.png" alt="Logo" />
            {/* <AdbIcon sx={{ display: { xs: "none", md: "flex" }, mr: 1 }} /> */}
            <Typography
              variant="h4"
              noWrap
              sx={{
                paddingLeft: "2px",
                mr: 2,
                display: { xs: "none", md: "flex" },
                fontFamily: "monospace",
                fontWeight: 700,
                letterSpacing: ".3rem",
                color: "inherit",
                textDecoration: "none",
              }}
            >
              BLM
            </Typography>

            <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: "block", md: "none" },
                }}
              >
                {pages.map((page) => (
                  <MenuItem key={page} onClick={handleCloseNavMenu}>
                    <Typography textAlign="center">
                      <NavLink
                        id="linkbar"
                        to={page}
                        style={({ isActive, isPending }) => {
                          return {
                            fontWeight: isActive ? "bold" : "",
                            color: isPending ? "white" : "black",
                            padding: "8px",
                          };
                        }}
                      >
                        {page}
                      </NavLink>
                    </Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
            {/* <img width={"48px"} src="assets/logo.png" alt="Description of the image" /> */}

            {/* <AdbIcon sx={{ display: { xs: "flex", md: "none" }, mr: 1 }} /> */}
            <Typography
              variant="h5"
              noWrap
              sx={{
                mr: 2,
                display: { xs: "flex", md: "none" },
                flexGrow: 1,
                fontFamily: "monospace",
                fontWeight: 700,
                letterSpacing: ".3rem",
                color: "inherit",
                textDecoration: "none",
              }}
            >
              BLM
            </Typography>
            <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex"}, alignItems:"center" }}>
              {pages.map((page) => (
                <Box key={page}>
                  <NavLink
                    id="linkbar"
                    to={page}
                    style={({ isActive, isPending }) => {
                      return {
                        fontWeight: isActive ? "bold" : "",
                        color: isPending ? "white" : "white",
                        padding: "8px",
                      };
                    }}
                  >
                    {page}
                  </NavLink>
                </Box>
              ))}
              <NavLink
              id="linkbar"
              target="_blank"
              to={"https://dashboardblm.thirdbrain.ch/"}
              style={({ isActive, isPending }) => {
                return {
                  fontWeight: isActive ? "bold" : "",
                  color: isPending ? "white" : "white",
                  padding: "8px",
                };
              }}
            >
              {"UPTIME"}
            </NavLink>
            </Box>
            
            <Box sx={{ flexGrow: 0 }}>
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar />
              </IconButton>
              <Menu
                sx={{ mt: "45px" }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                {currentUser && (
                  <MenuItem>
                    <Typography textAlign="center">
                      Current User: {currentUser.email}
                    </Typography>
                  </MenuItem>
                )}
                {currentUser && currentUser.user_role === "administrator" && (
                  <MenuItem>
                    <Link
                      target={"_blank"}
                      color="inherit"
                      underline="none"
                      id="linkbar"
                      href="/admindashboard"
                    >
                      {"Go to Admin Dashboard"}
                    </Link>
                  </MenuItem>
                )}
                <MenuItem onClick={handleLogout}>
                  <Typography textAlign="center">Logout</Typography>
                </MenuItem>
              </Menu>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
      <Outlet />
    </Box>
  );
};

export default Main;
