import { FC, useState, useMemo, useEffect, useRef } from "react";
import { useSearchParams } from "react-router-dom";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import Chart from "chart.js/auto";
import axios from "axios";
import dayjs, { Dayjs } from "dayjs";
import { useQuery } from "react-query";
import moment from "moment";
import Loadings from "../../compoment/common/Loadings";
import { BRANDS, NEWBRANDS, NEWBRANDS1 } from "../../utils/constant";
import { useSelector } from "react-redux";
import {
  selectIsLogged,
  selectWaitAuthCheck,
  setUser,
  selectUser,
} from "../../redux/slices/authSlice";
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 50,
    },
  },
};

interface OrderData {
  _id: Date;
  total_orders: number;
}

interface MACDData {
  macdLine: number[];
  signalLine: number[];
  histogram: number[];
}

const calculateEMA = (data: number[], period: number): number[] => {
  const ema: number[] = [];
  let prevEMA = data[0];

  for (let i = 1; i < data.length; i++) {
    const currentEMA =
      (2 / (period + 1)) * data[i] + ((period - 1) / (period + 1)) * prevEMA;
    ema.push(currentEMA);
    prevEMA = currentEMA;
  }

  return ema;
};

const calculateMACD = (data: OrderData[]): MACDData => {
  const prices = data.map((orderData) => orderData.total_orders);

  const ema12 = calculateEMA(prices, 12);
  const ema26 = calculateEMA(prices, 26);

  const macdLine = ema12.map((ema12Value, index) => ema12Value - ema26[index]);
  const signalLine = calculateEMA(macdLine, 9);
  const histogram = macdLine.map(
    (macdValue, index) => macdValue - signalLine[index]
  );

  return {
    macdLine,
    signalLine,
    histogram,
  };
};

const SmartDashboard: FC = () => {
  const currentUser = useSelector(selectUser);

  const [values, setValues] = useState<{
    specificDate: Dayjs;
    brands: string[];
  }>({
    specificDate: dayjs("01-06-2023", "DD-MM-YYYY"),
    brands: BRANDS,
  });
  const chartRef = useRef<Chart | null>(null);

  const { data, isLoading } = useQuery(
    ["revenueByBrand", values.brands],
    async () => {
      const response = await axios.post(`/api/v1/orders/getMACDOrders`, {
      
        brands: values.brands,
      });
      return response.data.data;
    }
  );

  const handleChange = (e: { target: { name: any; value: any } }) => {
    setValues({
      ...values,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    let orderData: OrderData[] = [];
    if (data?.macdItems !== undefined && currentUser && currentUser.user_role === "administrator") {
      orderData = data?.macdItems?.map((index: any) => {
        return {
          _id: new Date(index._id),
          total_orders: index.total_orders,
        };
      });


      const macdData = calculateMACD(orderData);
  
      const ctx = document.getElementById("chart") as HTMLCanvasElement;

      if (chartRef.current) {
        chartRef.current.destroy();
      }

      // console.log(orderData, "order data");
      chartRef.current = new Chart(ctx, {
        type: "line",
        data: {
          labels: orderData.map((order) =>
            moment(order?._id).format("YYYY-MM-DD")
          ),
          datasets: [
            {
              label: "MACD Line",
              data: macdData.macdLine,
              borderColor: "blue",
              fill: false,
            },
            {
              label: "Signal Line",
              data: macdData.signalLine,
              borderColor: "red",
              fill: false,
            },
            {
              label: "Histogram",
              data: macdData.histogram,
              type: "bar",
              backgroundColor: "rgba(0, 128, 0, 0.5)",
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: true,
          scales: {
            x: {
              display: true,
            },
            y: {
              display: true,
            },
          },
        },
      });
    }

  }, [data?.macdItems, currentUser]);


  return (
    <Box>
 {currentUser && currentUser.user_role === "administrator" ? (
        
    <Box>
      {isLoading ? (
        <Box
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          height={"calc( 100vh - 160px)"}
        >
          <Loadings />
        </Box>
      ) : (
        <>
          <Grid container alignItems={"center"} spacing={"8px"} sx={{paddingTop: "12px"}}>
            <Grid item md={8} sm={8} xs={8} >
              <Typography variant="h6" sx={{ textAlign: "center" }}>
                Smart DashBoard
              </Typography>
            </Grid>
            <Grid item md={3} sm={3} xs={3} >
              <FormControl sx={{ width: "100%" }}>
                <InputLabel id="brands-label" >
                  Brand
                </InputLabel>
                <Select
                  labelId="brands-label"
                  multiple
                  name={"brands"}
                  value={values.brands}
                  onChange={handleChange}
                  input={<OutlinedInput label="Brand" />}
                  type="search"
                  renderValue={(selected: any) =>
                    selected.map((item: any) => NEWBRANDS1[item]).join(", ")
                  }
                  MenuProps={MenuProps}
                >
                  {NEWBRANDS.map((brand) => (
                    <MenuItem key={brand.value} value={brand.value}>
                      <Checkbox checked={values.brands.includes(brand.value)} />
                      <ListItemText primary={brand.label} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={1} sm={1} xs={1}></Grid>
          </Grid>
          <canvas id="chart" />
        </>
      )}
    </Box>
     ) : (
        <Box sx={{ textAlign: "center", marginTop: "200px" }}>
          <Typography variant="h6">
            You can't access this Page. Because you have no pemission.
          </Typography>
        </Box>
      )}
    </Box>

  );
};

export default SmartDashboard;
