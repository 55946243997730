import { FC } from "react";
import { Typography } from "@mui/material";
import { IMAddress } from "../../../models/Order";

interface IBillingAddressInfoProps {
  billingAddressInfo: IMAddress;
}

const BillingAdress: FC<IBillingAddressInfoProps> = ({billingAddressInfo}) => {
  return (
    <>
      <Typography variant="h6"  >
        Billing Address
      </Typography>
      <address>
        {billingAddressInfo.firstname + billingAddressInfo.lastname} <br /> {billingAddressInfo.street?.join(', ')} 
         <br />
        {billingAddressInfo.city + ", " + billingAddressInfo.postcode}
        <br />
        T: {billingAddressInfo.telephone}
      </address>
    </>
  );
};

export default BillingAdress;
