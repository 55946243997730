import { Box, LinearProgress, TableCell, TableRow } from "@mui/material";
import { FC } from "react";
import { useOrder } from "../../hooks/useOrders";
import { formatePrice } from "../../utils/common";
import { BRANDS_FOR_PRODUCTS, CURRENCY } from "../../utils/constant";
import Loadings from "../common/Loadings";

interface OrderRowProps {
  brand?: string;
  orderId: string;
}

const OrderRow: FC<OrderRowProps> = ({ brand = "", orderId = "" }) => {
  const { data: order, isLoading } = useOrder(brand, orderId);

  //   console.log("order", order);

  if(!order){
      return null
  }

  return (
    <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
      {isLoading || !order ? (
        <TableCell colSpan={999} sx={{ textAlign: "center" }}>
          <Box sx={{ width: "100%", height: "20px" }}>
            <LinearProgress />
          </Box>
        </TableCell>
      ) : (
        <>
          <TableCell scope="row">{order.increment_id}</TableCell>
          <TableCell >{order.store_name}</TableCell>
          <TableCell>{order.created_at}</TableCell>
          <TableCell>
            {order.billing_address.firstname +
              " " +
              order.billing_address.lastname}
          </TableCell>
          <TableCell>
            {order.extension_attributes.shipping_assignments?.[0].shipping
              .address.firstname +
              " " +
              order.extension_attributes.shipping_assignments?.[0].shipping
                .address.lastname}
          </TableCell>
          <TableCell sx={{ padding: "10px" }} >
            {CURRENCY[order?.order_currency_code]}{" "}
            {formatePrice(order?.base_grand_total)}
          </TableCell>
          <TableCell >
            {CURRENCY[order?.order_currency_code]}{" "}
            {formatePrice(order?.grand_total)}
          </TableCell>
          <TableCell>
            {CURRENCY[order?.order_currency_code]}{" "}
            {formatePrice(order?.base_total_refunded)}
          </TableCell>
          <TableCell sx={{ textTransform: "capitalize" }}>
            {order.status}
          </TableCell>
          <TableCell>{order.customer_email}</TableCell>
          <TableCell>
            {order.customer_firstname + " " + order.customer_lastname}
          </TableCell>

          <TableCell>
            {
              order.extension_attributes.payment_additional_info?.filter(
                (info) => info.key === "method_title"
              )[0].value
            }
          </TableCell>
          <TableCell>{order.total_invoiced}</TableCell>
          <TableCell>{order.items?.[0].sku}</TableCell>
          <TableCell>
            <a
              target={"_blank"}
              href={`/orderdetail/${BRANDS_FOR_PRODUCTS[order.store_id]}/${
                order.entity_id
              }`}
              rel="noreferrer"
            >
              View
            </a>
          </TableCell>
        </>
      )}
    </TableRow>
  );
};

export default OrderRow;
