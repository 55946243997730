interface CurrencyType {
  [key: string]: string;
}

export const CURRENCY: CurrencyType = {
  USD: "$",
  EUR: "€",
  GBP: "£",
  JPY: "¥",
  CHF: "₣",
};

export const BRANDS = ["NVH_3", "NVH_4", "APO"];
export const BRANDS2 = ["NVH_3", "APO"];
export const BRANDS_FOR_PRODUCTS: {
  [key: number]: string;
} = {
  3: "NVH_3",
  4: "NVH_4",
  1: "APO",
};

export const SKU_VISIBILITY: {
  [key: number]: string;
} = {
  1: "Not Visible Individually",
  2: "Catalog",
  3: "Search",
  4: "Catalog, Search",
};

export const SKU_STATUS: {
  [key: number]: string;
} = {
  1: "Enabled",
  2: "Disabled",
};

export const BRANDS_SKU: {
  [key: number]: string;
} = {
  3: "Vitalisium DE",
  4: "Vitalisium ES",
  1: "Apojilab",
};

export const IMAGE_FROM_WEBSITES: {
  [key: number]: string;
} = {
  3: "https://pre-prod.vitalisium.com/media/catalog/product",
  4: "https://pre-prod.vitalisium.com/media/catalog/product",
  1: "https://dev.apojilab.com/media/catalog/product",
};

export const NEWBRANDS1: {
  [key: string]: string;
} = {
  NVH_3: "Vitalisium DE",
  NVH_4: "Vitalisium ES",
  APO: "Apojilab",
};
export const NEWBRANDS2: {
  [key: string]: string;
} = {
  NVH_3: "Vitalisium",
  APO: "Apojilab",
};
export const NEWBRANDS = [
  {
    value: "NVH_3",
    label: "Vitalisium DE",
  },
  {
    value: "NVH_4",
    label: "Vitalisium ES",
  },
  {
    value: "APO",
    label: "Apojilab",
  },
];
export const NEWBRANDS22 = [
  {
    value: "NVH_3",
    label: "Vitalisium",
  },
  {
    value: "APO",
    label: "Apojilab",
  },
];
