import React, { FC, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useProducts } from "../hooks/useProducts";
import { useInView } from "react-intersection-observer";

import {  IMOrderItem } from "../models/Order";
import Loadings from "../compoment/common/Loadings";
import { useSearchParams } from "react-router-dom";
import { BRANDS } from "../utils/constant";

import OrderRow from "../compoment/sku/OrderRow";

const SkusOrderDetail: FC = () => {

  const { brand, product_id } = useParams();

  const filters = useMemo<{
    brands: string[];
    product_id: string;
  }>(() => {
    const filters = {
      product_id: product_id || "",
      brands: brand ? [brand] : BRANDS,
    };

    return filters;
  }, [brand, product_id]);

  const { data, isLoading, isFetchingNextPage, fetchNextPage, hasNextPage } =
    useProducts(filters);

  const { ref, inView } = useInView({ rootMargin: "500px" });


  useEffect(() => {
    if (inView) {
      fetchNextPage();
    }
  }, [inView, fetchNextPage]);
  return (
    <Box sx={{ py: "16px" }}>
      {isLoading ? (
        <Box
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          height={"calc( 100vh - 160px)"}
        >
          <Loadings />
        </Box>
      ) : (
        <TableContainer sx={{ maxHeight: "calc( 100vh - 160px)" }}>
          <Table stickyHeader aria-label="simple table">
            <TableHead>
              <TableRow
                sx={{
                  "& > th ": {
                    fontWeight: 700,
                    backgroundColor: "#514943",
                    color: "white",
                    borderRight: "1px solid #8A837F",
                  },
                }}
              >
                <TableCell>Id</TableCell>
                <TableCell>Purchase Point</TableCell>
                <TableCell>Purchase Date</TableCell>
                <TableCell>Bill-to Name</TableCell>
                <TableCell>Ship-to Name</TableCell>
                <TableCell>Grand Total (Base)</TableCell>
                <TableCell>Grand Total (Purchased)</TableCell>
                <TableCell>Refund Total (Base)</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Customer Email</TableCell>
                <TableCell>Customer Name</TableCell>
                <TableCell>Payment Method</TableCell>
                <TableCell>Invoice</TableCell>
                <TableCell>Sku</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody sx={{
                "& > tr": {
                  border: 0 ,
                },
                "& > tr:nth-of-type(even)": {
                  backgroundColor: "#F5F5F5",
                },
              }}>
              {data?.pages?.map((page: any) => (
                <React.Fragment key={page.currentPage}>
                  {page.items.map((item: any) => (
                    <OrderRow
                    key={brand + item.order_id}
                    brand={brand} orderId={item.order_id} />
                  ))}
                </React.Fragment>
              ))}
              {hasNextPage && (
                <TableRow
                  ref={ref}
                  sx={{ "& > td ": { borderRight: "1px dashed #d6d6d6" } }}

                  hidden={!hasNextPage}
                >
                  <TableCell colSpan={99} align="center">
                    {isFetchingNextPage && (
                      <Box display={"flex"} justifyContent={"center"}>
                        <Loadings />
                      </Box>
                    )}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Box>
  );
};

export default SkusOrderDetail;
