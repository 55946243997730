import axios from "axios";
import { useInfiniteQuery, useQuery } from "react-query";
import { IMProductItem } from "../models/ProductItem";

export const useProducts = (filters: {
  brands: string[];
  product_id: string;
}) => {
  return useInfiniteQuery({
    queryKey: ["products", JSON.stringify(filters)],
    queryFn: async ({ pageParam }) => {
      const searchCriteria = [];

      // console.log(filters, "filters");

      if (filters.product_id) {
        searchCriteria.push({
          field: "product_id",
          value: filters.product_id,
          condition_type: "eq",
        });
      }
      const currentPage = pageParam?.currentPage ?? 1;
      const response = await axios.post(
        `/api/v1/products`,
        {
          brands: filters.brands,
          searchCriteria,
          currentPage: currentPage,
        }
      );

      return response.data.data;
    },
    getNextPageParam: (lastPage: any) => {
      return lastPage.items && lastPage.items.length >= 10
        ? { currentPage: lastPage.currentPage + 1 }
        : undefined;
    },
    enabled: true,
  });
};

export const useProduct = (brand: string, product_id: string) => {
  return useQuery({
    queryKey: ["product", brand, product_id],
    queryFn: async () => {
      const response = await axios.get(
        `/api/v1/products/${brand}/${product_id}`
      );

      return response.data.data as IMProductItem;
    },
    enabled: !!brand && !!product_id, 
  });
};
