import { FC } from "react";
import { Typography } from "@mui/material";
import { CURRENCY } from "../../../utils/constant";
import { formatePrice } from "../../../utils/common";

export interface IShippingHandlingInfo {
  shipping_description: string;
  store_currency_code: string;
  shipping_discount_amount: number;
}

interface IShippingHandlingInfoProps {
  shippingHandlingInfo: IShippingHandlingInfo;
}

const ShippingHandlingInfo: FC<IShippingHandlingInfoProps> = ({
  shippingHandlingInfo,
}) => {
  return (
    <>
      <Typography variant="h6">Shipping & Handling Information</Typography>
      <Typography variant="overline">
        {shippingHandlingInfo.shipping_description}{" "}
        {CURRENCY[shippingHandlingInfo.store_currency_code]}{" "}
        {formatePrice( shippingHandlingInfo.shipping_discount_amount)}
      </Typography>
    </>
  );
};

export default ShippingHandlingInfo;
