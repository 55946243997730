import React, { FC } from "react";
import { Box, Button, TextField } from "@mui/material";
import { Link } from "react-router-dom";

interface RegisterProps {}

const Register: FC<RegisterProps> = () => {
  return (
    <Box
      sx={{
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          maxWidth: "720px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <TextField id="email" label="Email" variant="outlined" sx={{ mb: 1 }} />
        <TextField
          id="password"
          label="PassWord"
          variant="outlined"
          type="password"
          sx={{ mb: 1 }}
        />
        <Button variant="contained" sx={{ mb: 1 }}>
          SignUp
        </Button>
        {/* <Link to={"/auth/login"}>Login</Link> */}
      </Box>
    </Box>
  );
};

export default Register;
