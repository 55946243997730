import React, { FC, useState, useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { BRANDS, CURRENCY, NEWBRANDS, NEWBRANDS1 } from "../../utils/constant";
import dayjs, { Dayjs } from "dayjs";

import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import * as XLSX from "xlsx";
import { useFilterOrders } from "../../hooks/useOrders";
import { formatePrice } from "../../utils/common";
import Loadings from "../../compoment/common/Loadings";
interface FiltersProps {
  exportData: any;
  searchRef?: any;
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const Filters: FC<FiltersProps> = ({ exportData, searchRef }) => {
  console.log(exportData, "exportData");
  const [searchParams, setSearchParams] = useSearchParams();
  const [statusChecked, setStatusChecked] = useState(false);
  const [refundChecked, setRefundChecked] = useState(false);
  const [effisys_exportedChecked, setEffisys_exportedChecked] = useState(false);
  const [canceleddChecked, setCanceleddChecked] = useState(false);
  const [closedChecked, setClosedChecked] = useState(false);
  const [completeChecked, setCompleteChecked] = useState(false);
  const [pendingChecked, setPendingChecked] = useState(false);

  const [values, setValues] = useState<{
    orderId: string;
    firstname: string;
    lastname: string;
    email: string;
    invoice: string;
    address: string;
    phonenumber: string;
    brands: string[];
    sku: string;
    status: string;
    refund: string;
    start_date: Dayjs;
    end_date: Dayjs;
    effisys_exported: string;
    canceled: string;
    closed: string;
    complete: string;
    pending: string;
    sortFlag: string;
  }>({
    orderId: searchParams.get("orderId") || "",
    firstname: searchParams.get("firstname") || "",
    lastname: searchParams.get("lastname") || "",
    email: searchParams.get("email") || "",
    invoice: searchParams.get("invoice") || "",
    address: searchParams.get("address") || "",
    phonenumber: searchParams.get("phonenumber") || "",
    brands: searchParams.get("brands")?.split(",") || BRANDS,
    sku: searchParams.get("sku") || "",
    status: searchParams.get("status") || "",
    refund: searchParams.get("refund") || "",
    effisys_exported: searchParams.get("effisys_exported") || "",
    canceled: searchParams.get("canceled") || "",
    closed: searchParams.get("closed") || "",
    complete: searchParams.get("complete") || "",
    pending: searchParams.get("pending") || "",
    sortFlag: searchParams.get("sortFlag") || "0",
    start_date: dayjs(
      searchParams.get("start_date") || "14-04-2023",
      "DD-MM-YYYY"
    ),
    end_date: dayjs(searchParams.get("end_date") || dayjs(), "DD-MM-YYYY"),
  });

  // params.set("start_date", dayjs('2022-04-17').format('DD-MM-YYYY'));
  // params.set("end_date", dayjs(new Date()).format('DD-MM-YYYY'));

  // console.log(values.start_date,"start_date");
  const handleChange = (e: { target: { name: any; value: any } }) => {
    setValues({
      ...values,
      [e.target.name]: e.target.value,
    });
  };

  const handleCheckChange =
    (id: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
      if (id === "status") {
        setStatusChecked(event.target.checked);
        setValues({
          ...values,
          status: event.target.checked ? "Holded" : "",
        });
        // console.log(values.status, "status");
      } else if (id === "refund") {
        setRefundChecked(event.target.checked);
        setValues({
          ...values,
          refund: event.target.checked ? "1" : "0",
        });
        // console.log(values.refund, "refund");
      } else if (id === "effisys_exported") {
        setEffisys_exportedChecked(event.target.checked);
        setValues({
          ...values,
          effisys_exported: event.target.checked ? "1" : "0",
        });
        // console.log(values.effisys_exported, "effisys_exported");
      } else if (id === "canceled") {
        setCanceleddChecked(event.target.checked);
        setValues({
          ...values,
          canceled: event.target.checked ? "1" : "0",
        });
        // console.log(values.canceled, "canceled");
      } else if (id === "closed") {
        setClosedChecked(event.target.checked);
        setValues({
          ...values,
          closed: event.target.checked ? "1" : "0",
        });
        // console.log(values.closed, "closed");
      } else if (id === "complete") {
        setCompleteChecked(event.target.checked);
        setValues({
          ...values,
          complete: event.target.checked ? "1" : "0",
        });
        // console.log(values.complete, "complete");
      } else if (id === "pending") {
        setPendingChecked(event.target.checked);
        setValues({
          ...values,
          pending: event.target.checked ? "1" : "0",
        });
        // console.log(values.pending, "pending");
      }
    };

  const handleApply = () => {
    const params = new URLSearchParams();

    if (values.orderId) {
      params.set("orderId", values.orderId);
    }

    if (values.firstname) {
      params.set("firstname", values.firstname);
    }

    if (values.lastname) {
      params.set("lastname", values.lastname);
    }

    if (values.email) {
      params.set("email", values.email);
    }
    if (values.invoice) {
      params.set("invoice", values.invoice);
    }

    if (values.sku) {
      params.set("sku", values.invoice);
    }
    if (values.status) {
      params.set("status", values.status);
    }
    if (values.effisys_exported) {
      params.set("effisys_exported", values.effisys_exported);
    }
    if (values.canceled) {
      params.set("canceled", values.canceled);
    }
    if (values.closed) {
      params.set("closed", values.closed);
    }
    if (values.complete) {
      params.set("closed", values.closed);
    }
    if (values.complete) {
      params.set("complete", values.complete);
    }
    if (values.pending) {
      params.set("pending", values.pending);
    }
    if (values.address) {
      params.set("address", values.address);
    }
    if (values.phonenumber) {
      params.set("phonenumber", values.phonenumber);
    }

    if (values.brands) {
      params.set("brands", values.brands.join(","));
    }

    if (values.refund) {
      params.set("refund", values.refund);
    }

    if (values.start_date) {
      //  console.log(values.start_date.format('YYYY-MM-DD'),"values.start_date");
      params.set("start_date", values.start_date.format("YYYY-MM-DD"));
    }
    if (values.end_date) {
      params.set("end_date", values.end_date.format("YYYY-MM-DD"));
    }
    //  console.log(values.refund, "values.refund");
    setSearchParams(params);
  };

  const filters = useMemo<{
    orderId: string;
    firstname: string;
    lastname: string;
    email: string;
    invoice: string;
    address: string;
    phonenumber: string;
    brands: string[];
    refund: string;
    status: string;
    start_date: Dayjs;
    end_date: Dayjs;
    effisys_exported: string;
    canceled: string;
    closed: string;
    complete: string;
    pending: string;
    sortFlag: string;
  }>(() => {
    const filters = {
      orderId: searchParams.get("orderId") || "",
      firstname: searchParams.get("firstname") || "",
      lastname: searchParams.get("lastname") || "",
      email: searchParams.get("email") || "",
      invoice: searchParams.get("invoice") || "",
      address: searchParams.get("address") || "",
      phonenumber: searchParams.get("phonenumber") || "",
      brands: searchParams.get("brands")?.split(",") || BRANDS,
      refund: searchParams.get("refund") || "",
      status: searchParams.get("status") || "",
      effisys_exported: searchParams.get("effisys_exported") || "",
      canceled: searchParams.get("canceled") || "",
      closed: searchParams.get("closed") || "",
      complete: searchParams.get("complete") || "",
      pending: searchParams.get("pending") || "",
      sortFlag: searchParams.get("sortFlag") || "0",

      start_date: dayjs(searchParams.get("start_date") || "", "YYYY-MM-DD"),
      end_date: dayjs(searchParams.get("end_date") || "", "YYYY-MM-DD"),
    };

    return filters;
  }, [searchParams]);
  const tableData: any = [];
  const { data, isLoading } = useFilterOrders(filters);
  let holdedCnt = 0;
  let refundCnt = 0;
  let effisysCnt = 0;
  let canceledCnt = 0;
  let closedCnt = 0;
  let completeCnt = 0;
  let pendingCnt = 0;
  let totalRefund = 0;
  let totalCompleted = 0;
  data?.items
    .filter((item: any) => {
      if(!exportData || exportData.length === 0){
        return true;
      }
      return exportData.includes(item.increment_id)
    })
    .map((item: any) => {
      // console.log(item);
      tableData.push({
        Id: item.increment_id,
        PurchasePoint: item.store_name,
        PurchaseDate: item.created_at,
        Bill_to_Name:
          item.billing_address.firstname + " " + item.billing_address.lastname,
        Ship_to_Name:
          item.extension_attributes.shipping_assignments?.[0].shipping.address
            .firstname +
          " " +
          item.extension_attributes.shipping_assignments?.[0].shipping.address
            .lastname,
        Grand_Total_Base:
          CURRENCY[item?.order_currency_code] +
          " " +
          formatePrice(item?.base_grand_total),
        Grand_Total_Purchased:
          CURRENCY[item?.order_currency_code] +
          " " +
          formatePrice(item?.grand_total),
        Refund_Total_Base:
          CURRENCY[item?.order_currency_code] +
          " " +
          formatePrice(item?.base_total_refunded),
        Status: item.status,
        CustomerEmail: item.customer_email,
        CustomerName: item.customer_firstname + " " + item.customer_lastname,
        PaymentMethod:
          item.extension_attributes.payment_additional_info?.filter(
            (info: any) => info.key === "method_title"
          )[0].value,
        Invoice: item.total_invoiced,
        Sku: item.items?.[0].sku,
      });
      if (item.status === "holded") {
        holdedCnt++;
      } else if (item.base_total_refunded > 0) {
        totalRefund += item.base_total_refunded;
        refundCnt++;
      } else if (item.status === "effisys_exported") {
        effisysCnt++;
      } else if (item.status === "canceled") {
        canceledCnt++;
      } else if (item.status === "closed") {
        closedCnt++;
      } else if (item.status === "complete") {
        totalCompleted += item.base_grand_total;
        completeCnt++;
      } else if (item.status === "pending") {
        pendingCnt++;
      }
    });

  // console.log(totalRefund, "total refuned");
  // console.log(totalCompleted, "totalCompleted");
  // console.log(tableData, "filterOrderList");
  // const tableData = [{
  //   Id: '1234',
  //   PurchasePoint: "Vitalisium ",
  //   PurchaseDate: "2023-08-23 12:45:48"
  // }];

  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(tableData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const data = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const url = URL.createObjectURL(data);
    const link = document.createElement("a");
    link.href = url;
    link.download = `Orders filter by ${values.orderId} ${values.firstname} ${
      values.lastname
    } ${values.email} ${values.start_date} from ${
      values.end_date
    } ${values.brands
      .map((index) => {
        return NEWBRANDS1[index] + ",";
      })
      .join("")}.xlsx`;
    link.click();
  };
  return (
    <Box
      sx={{
        padding: "12px",
      }}
    >
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Grid container alignItems={"center"} spacing={"8px"}>
          <Grid item md={1} sm={4} xs={12}>
            <Typography variant="h6">Orders</Typography>
          </Grid>
          <Grid item md={1} sm={4} xs={12}>
            <TextField
              name="orderId"
              label="OrderID"
              type="search"
              value={values.orderId}
              onChange={handleChange}
            />
          </Grid>
          <Grid item md={2} sm={4} xs={12}>
            <TextField
              name="firstname"
              label="FirstName"
              type="search"
              value={values.firstname}
              onChange={handleChange}
            />
          </Grid>
          <Grid item md={2} sm={4} xs={12}>
            <TextField
              name="lastname"
              label="LastName"
              type="search"
              value={values.lastname}
              onChange={handleChange}
            />
          </Grid>
          <Grid item md={2} sm={4} xs={12}>
            <TextField
              name="email"
              label="Email"
              type="search"
              value={values.email}
              onChange={handleChange}
            />
          </Grid>
          <Grid item md={3} sm={4} xs={12}>
            <FormControl sx={{ width: "100%" }}>
              <InputLabel id="brands-label">Brand</InputLabel>
              <Select
                labelId="brands-label"
                multiple
                name={"brands"}
                value={values.brands}
                onChange={handleChange}
                input={<OutlinedInput label="Brand" />}
                type="search"
                renderValue={(selected: any) =>
                  selected.map((item: any) => NEWBRANDS1[item]).join(", ")
                }
                MenuProps={MenuProps}
              >
                {NEWBRANDS.map((brand) => (
                  <MenuItem key={brand.value} value={brand.value}>
                    <Checkbox checked={values.brands.includes(brand.value)} />
                    <ListItemText primary={brand.label} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item md={1} sm={4} xs={12}>
            <Box>
              <IconButton ref={searchRef} sx={{ float: "right" }} onClick={handleApply}>
                <SearchIcon />
              </IconButton>
            </Box>
          </Grid>
          <Grid item md={1} sm={4} xs={12} sx={{ textAlign: "center" }}>
            {isLoading ? (
              <Typography>Loading ...</Typography>
            ) : (
              <Typography>
                Refund rate :<br></br>
                {formatePrice((totalRefund / totalCompleted) * 100)} %
              </Typography>
            )}
          </Grid>

          <Grid item md={1} sm={4} xs={12} sx={{ textAlign: "center" }}>
            <FormControlLabel
              label={`Holded ${holdedCnt === 0 ? "" : holdedCnt}`}
              control={
                <Checkbox
                  name={"status"}
                  checked={statusChecked}
                  onChange={handleCheckChange("status")}
                  value={values.status}
                  inputProps={{ "aria-label": "controlled" }}
                />
              }
            />
          </Grid>
          <Grid item md={1} sm={4} xs={12} sx={{ textAlign: "center" }}>
            <FormControlLabel
              label={`Refund ${refundCnt === 0 ? "" : refundCnt}`}
              control={
                <Checkbox
                  name={"refund"}
                  checked={refundChecked}
                  onChange={handleCheckChange("refund")}
                  value={values.refund}
                  inputProps={{ "aria-label": "controlled" }}
                />
              }
            />
          </Grid>
          <Grid item md={2} sm={4} xs={12} sx={{ textAlign: "center" }}>
            <FormControlLabel
              label={`Effisys_exported ${effisysCnt === 0 ? "" : effisysCnt}`}
              control={
                <Checkbox
                  name={"effisys_exported"}
                  checked={effisys_exportedChecked}
                  onChange={handleCheckChange("effisys_exported")}
                  value={values.effisys_exported}
                  inputProps={{ "aria-label": "controlled" }}
                />
              }
            />
          </Grid>
          <Grid item md={1} sm={4} xs={12} sx={{ textAlign: "center" }}>
            <FormControlLabel
              label={`Canceled ${canceledCnt === 0 ? "" : canceledCnt}`}
              control={
                <Checkbox
                  name={"canceled"}
                  checked={canceleddChecked}
                  onChange={handleCheckChange("canceled")}
                  value={values.canceled}
                  inputProps={{ "aria-label": "controlled" }}
                />
              }
            />
          </Grid>
          <Grid item md={1} sm={4} xs={12} sx={{ textAlign: "center" }}>
            <FormControlLabel
              label={`Closed ${closedCnt === 0 ? "" : closedCnt}`}
              control={
                <Checkbox
                  name={"closed"}
                  checked={closedChecked}
                  onChange={handleCheckChange("closed")}
                  value={values.closed}
                  inputProps={{ "aria-label": "controlled" }}
                />
              }
            />
          </Grid>
          <Grid item md={1} sm={4} xs={12} sx={{ textAlign: "center" }}>
            <FormControlLabel
              label={`Complete ${completeCnt === 0 ? "" : completeCnt}`}
              control={
                <Checkbox
                  name={"complete"}
                  checked={completeChecked}
                  onChange={handleCheckChange("complete")}
                  value={values.complete}
                  inputProps={{ "aria-label": "controlled" }}
                />
              }
            />
          </Grid>
          <Grid item md={1} sm={4} xs={12} sx={{ textAlign: "center" }}>
            <FormControlLabel
              label={`Pending ${pendingCnt === 0 ? "" : pendingCnt}`}
              control={
                <Checkbox
                  name={"pending"}
                  checked={pendingChecked}
                  onChange={handleCheckChange("pending")}
                  value={values.pending}
                  inputProps={{ "aria-label": "controlled" }}
                />
              }
            />
          </Grid>
          <Grid item md={1} sm={4} xs={12}>
            <DatePicker
              label="Start Date"
              // defaultValue={dayjs('2022-04-17')}
              value={values.start_date}
              onChange={(newValue) =>
                handleChange({
                  target: { name: "start_date", value: newValue },
                })
              }
            />
          </Grid>
          <Grid item md={1} sm={4} xs={12}>
            <DatePicker
              label="End Date"
              // defaultValue={dayjs(new Date())}
              value={values.end_date}
              onChange={(newValue) =>
                handleChange({
                  target: { name: "end_date", value: newValue },
                })
              }
            />
          </Grid>
          <Grid item md={1} sm={3} xs={12}>
            {isLoading ? (
              <Button variant="outlined">
                Loading<br></br> ...
              </Button>
            ) : (
              <Button variant="outlined" onClick={exportToExcel}>
                Export to Excel{"(" + exportData.length + ")"}
              </Button>
            )}
          </Grid>
        </Grid>
      </LocalizationProvider>
    </Box>
  );
};

export default Filters;
