import { FC, useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { BRANDS, BRANDS2, NEWBRANDS, NEWBRANDS1, NEWBRANDS2, NEWBRANDS22 } from "../../utils/constant";
import dayjs, { Dayjs } from "dayjs";

import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { green, pink } from "@mui/material/colors";
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";
import axios from "axios";
import { format } from "date-fns";

interface FiltersProps {}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const Filters: FC<FiltersProps> = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [checked, setChecked] = useState(false);
  const [flag, setFlag] = useState("red");
  const [values, setValues] = useState<{
    brands: string[];
    invoiceId: string;
    name: string;
    email: string;
    start_date: Dayjs;
    end_date: Dayjs;
  }>({
    brands: searchParams.get("brands")?.split(",") || BRANDS2,
    invoiceId: searchParams.get("invoiceId") || "",
    name: searchParams.get("name") || "",
    email: searchParams.get("email") || "",
    start_date: dayjs(
      searchParams.get("start_date") || "14-04-2023",
      "DD-MM-YYYY"
    ),
    end_date: dayjs(searchParams.get("end_date") || dayjs().add(1, 'day'), "DD-MM-YYYY"),
  });

  // params.set("start_date", dayjs('2022-04-17').format('DD-MM-YYYY'));
  // params.set("end_date", dayjs(new Date()).format('DD-MM-YYYY'));

  // console.log(values.start_date,"start_date");
  const handleChange = (e: { target: { name: any; value: any } }) => {
    setValues({
      ...values,
      [e.target.name]: e.target.value,
    });
  };
  useEffect(() => {
    (async () => {
      const response = await axios.get("/api/v1/logistics/logisticsstatus");
      // const createdAt = new Date(response.data[0].createdAt).format("YYYY-MM-DD").getTime();
      // console.log(response,"response")
      const createdAt = new Date(response.data[0].modifiedAt);
      const createdAtApo = new Date(response.data[1].modifiedAt);
 
      // console.log(createdAt, "created at");
      const year = createdAt.getFullYear();
      const month = String(createdAt.getMonth() + 1).padStart(2, "0");
      const day = String(createdAt.getDate()).padStart(2, "0");

      const formattedDate = `${year}-${month}-${day}`;
      const createdAtTimestamp = createdAt.getTime();
      const now = new Date().getTime();

      // console.log(new Date(), now, "now");
      // console.log(response.data[0].createdAt, createdAtTimestamp, "createdAt");
      // console.log((now - createdAtTimestamp) / 3600000, "status");
      if ((now - createdAtTimestamp) / 3600000 <= 4) {
        setFlag("green");
      } else if ((now - createdAtTimestamp) / 3600000 <= 24) {
        setFlag("yellow");
      } else {
        setFlag("red");
      }
      
    })();
  }, []);
  const handleCheckChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
    if (event.target.checked === true) {
      setValues({
        ...values,
        [event.target.name]: 1,
      });
    } else {
      setValues({
        ...values,
        [event.target.name]: 0,
      });
    }

    // console.log(event.target.checked, "event.target.checked");
  };
  const handleApply = () => {
    const params = new URLSearchParams();

    if (values.invoiceId) {
      params.set("invoiceId", values.invoiceId);
    }
    if (values.brands) {
      params.set("brands", values.brands.join(","));
    }
    console.log(values.brands,"brands")
    if (values.name) {
      params.set("name", values.name);
    }
   
    if (values.email) {
      params.set("email", values.email);
    }

    if (values.start_date) {
      //  console.log(values.start_date.format('YYYY-MM-DD'),"values.start_date");
      params.set("start_date", values.start_date.format("YYYY-MM-DD"));
    }
    if (values.end_date) {
      params.set("end_date", values.end_date.format("YYYY-MM-DD"));
    }
    //  console.log(values.refund, "values.refund");
    setSearchParams(params);
  };

  return (
    <Box
      sx={{
        padding: "12px",
      }}
    >
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Grid container alignItems={"center"} spacing={"8px"}>
          <Grid
            item
            md={1}
            sm={4}
            xs={12}
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "right",
            }}
          >
            <img width={"48px"} src={`assets/${flag}.png`} />
          </Grid>
          <Grid item md={1} sm={4} xs={12}>
            <TextField
              name="invoiceId"
              label="Invoice Id"
              type="search"
              value={values.invoiceId}
              onChange={handleChange}
            />
          </Grid>
          <Grid item md={1} sm={4} xs={12}>
            <TextField
              name="name"
              label="Name"
              type="search"
              value={values.name}
              onChange={handleChange}
            />
          </Grid>

          <Grid item md={1} sm={4} xs={12}>
            <TextField
              name="email"
              label="Email"
              type="search"
              value={values.email}
              onChange={handleChange}
            />
          </Grid>
          <Grid item md={2} sm={4} xs={12}>
            <DatePicker
              label="Start Date"
              // defaultValue={dayjs('2023-04-14')}
              value={values.start_date}
              onChange={(newValue) =>
                handleChange({
                  target: { name: "start_date", value: newValue },
                })
              }
            />
          </Grid>
          <Grid item md={2} sm={4} xs={12}>
            <DatePicker
              label="End Date"
              // defaultValue={dayjs(new Date())}
              value={values.end_date}
              onChange={(newValue) =>
                handleChange({
                  target: { name: "end_date", value: newValue },
                })
              }
            />
          </Grid>
          <Grid item md={3} sm={4} xs={12}>
            <FormControl sx={{ width: "100%" }}>
              <InputLabel id="brands-label">Brand</InputLabel>
              <Select
                labelId="brands-label"
                multiple
                name={"brands"}
                value={values.brands}
                onChange={handleChange}
                input={<OutlinedInput label="Brand" />}
                type="search"
                renderValue={(selected: any) =>
                  selected.map((item: any) => NEWBRANDS2[item]).join(", ")
                }
                MenuProps={MenuProps}
              >
                {NEWBRANDS22.map((brand) => (
                  <MenuItem key={brand.value} value={brand.value}>
                    <Checkbox checked={values.brands.includes(brand.value)} />
                    <ListItemText primary={brand.label} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item md={1} sm={4} xs={12}>
            <Box>
              <IconButton sx={{ float: "right" }} onClick={handleApply}>
                <SearchIcon />
              </IconButton>
            </Box>
          </Grid>
        </Grid>
      </LocalizationProvider>
    </Box>
  );
};

export default Filters;
