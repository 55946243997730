import axios from "axios";
import { FC, useEffect } from "react";
import { setUser, setAuthCheck } from "../redux/slices/authSlice";
import { useAppDispatch } from "../redux/store";
import * as authService from "../services/authService";

interface AuthProps {
  children: React.ReactNode;
}

const Auth: FC<AuthProps> = ({ children }) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    const token = localStorage.getItem("token");

    const isTokenExpired = () => {
      const expirationDate = localStorage.getItem("expirationDate");

      // if (!expirationDate) {
      //   return true; // Token doesn't exist or has been cleared
      // }

      const currentDate = new Date();
      if (expirationDate !== null && currentDate >= new Date(expirationDate)) {
        // console.log(currentDate,"current date")
        return true;
      }else{
        return false;
      }
      // return currentDate > new Date(expirationDate);
    };

    if (isTokenExpired()) {
      // logout
      localStorage.removeItem("token");
      localStorage.removeItem("expirationDate");
      dispatch(setUser(null));
      return;
    }
    axios.defaults.headers.common.Authorization = "Bearer " + token;
    if (token) {
      dispatch(setAuthCheck(true));
      authService
        .loginWithAccessToken()
        .then((res: any) => {
          dispatch(setAuthCheck(false));
          dispatch(setUser(res.user));
        })
        .catch((err) => {
          dispatch(setAuthCheck(false));
        });
    } else {
      dispatch(setAuthCheck(false));
    }
  }, [dispatch]);

  return <>{children}</>;
};

export default Auth;
