import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../store";

// Define a type for the slice state
interface AuthState {
  user: any;
  isLogged: boolean;
  waitAuthCheck: boolean;
}

// Define the initial state using that type
const initialState: AuthState = {
  user: null,
  isLogged: false,
  waitAuthCheck: true,
};

export const authSlice = createSlice({
  name: "auth",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setUser: (state: AuthState, action: PayloadAction<any>) => {
      state.user = action.payload;
      state.isLogged = !!action.payload;
    },
    setAuthCheck: (state: AuthState, action: PayloadAction<boolean>) => {
      state.waitAuthCheck = action.payload;
    }
  },
});

export const { setUser, setAuthCheck } = authSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectUser = (state: RootState) => state.auth.user;
export const selectIsLogged = (state: RootState) => state.auth.isLogged;
export const selectWaitAuthCheck = (state: RootState) => state.auth.waitAuthCheck;

export default authSlice.reducer;
