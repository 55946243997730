import { FC } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { CssBaseline } from "@mui/material";
import "./App.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import routes from "./routes";
import Auth from "./compoment/Auth";

const router = createBrowserRouter(routes);

const queryClient = new QueryClient();

const App: FC = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <Auth>
        <div className="App">
          <CssBaseline />
          <RouterProvider router={router} />
        </div>
      </Auth>
    </QueryClientProvider>
  );
};

export default App;
