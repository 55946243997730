import axios from "axios";
import { useInfiniteQuery, useQuery } from "react-query";
import { IMSkuItem } from "../models/Sku";
import dayjs, { Dayjs } from "dayjs";

export const useSkus = (filters: {
  //  id: string;
  sku: string;
  name: string;
  brands: string[];
  start_date: Dayjs;
  end_date: Dayjs;
}) => {
  return useInfiniteQuery({
    queryKey: ["skus", JSON.stringify(filters)],
    queryFn: async ({ pageParam }) => {
      const searchCriteria = [];

      // console.log(filters, "filters");

      if (filters.sku) {
        searchCriteria.push({
          field: "sku",
          value: filters.sku,
          condition_type: "like",
        });
      }
      if (filters.name) {
        searchCriteria.push({
          field: "name",
          value: filters.name,
          condition_type: "like",
        });
      }
      
      if ((filters.start_date >= dayjs('2022-04-17')) && (filters.end_date <= dayjs(new Date())))
      {

        searchCriteria.push({
          field: "created_at",
          value: filters.start_date.format("YYYY-MM-DD") + " 00:00:00",
          condition_type: "gteq",
        });
        searchCriteria.push({
          field: "created_at",
          value: filters.end_date.format("YYYY-MM-DD") + " 00:00:00",
          condition_type: "lteq",
        });
      }

      // console.log(filters.start_date, " filter _ sku");
      const currentPage = pageParam?.currentPage ?? 1;
      const response = await axios.post(
        `/api/v1/skus`,
        {
          brands: filters.brands,
          searchCriteria,
          currentPage: currentPage,
        }
      );

      return response.data.data;
    },
    getNextPageParam: (lastPage: any) => {
      return lastPage.items && lastPage.items.length >= 10
        ? { currentPage: lastPage.currentPage + 1 }
        : undefined;
    },
    enabled: true,
  });
};

export const useSku = (brand: string, sku: string) => {
  return useQuery({
    queryKey: ["sku", brand, sku],
    queryFn: async () => {
      const response = await axios.get(
        `/api/v1/skus/${brand}/${sku}`
      );

      return response.data.data as IMSkuItem;
    },
    enabled: !!brand && !!sku,
  });
};
