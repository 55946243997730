import axios from "axios";
import { useInfiniteQuery, useQuery } from "react-query";
import { ILogisticsData } from "../models/Logistics";
import dayjs, { Dayjs } from "dayjs";
import { BRANDS } from "../utils/constant";

export const useLogisticsRecept = (filters: {
  brands: string[];
  invoiceId: string;
  sku?: string;
  productName?: string;
}) => {
   return useInfiniteQuery({
    queryKey: ["recept", JSON.stringify(filters)],
    queryFn: async ({ pageParam }) => {
      
      const currentPage = pageParam?.currentPage ?? 1;

      const response = await axios.get(`/api/v1/logistics/recept`, {
        params:{filters,currentPage: currentPage}
      });
      // console.log(response.data, "response")
      return response.data;
    },
    getNextPageParam: (lastPage: any) => {
      return lastPage && lastPage.items && lastPage.items.length >= 20
        ? { currentPage: lastPage?.currentPage + 1 }
        : undefined;
    },
    enabled: true,
  });
};
