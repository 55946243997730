import { FC } from "react";
import { Box, Typography } from "@mui/material";

export interface LoadingsPros {
  text?: string;
}
const Loadings: FC<LoadingsPros> = ({ text = "Loading..." }) => {
  return (
    <>
      <Box className="spinner-container" alignContent={"center"}>
        <Box className="loading-spinner"></Box>
        <Typography variant="h6" align="center" sx={{ marginTop: "12px" }}>
          {text}
        </Typography>
      </Box>
    </>
  );
};

export default Loadings;
