import React, { FC, useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useInView } from "react-intersection-observer";
import {
  Avatar,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import moment from "moment";
import { IMSkuItem } from "../../models/Sku";

import { useSkus } from "../../hooks/useSkus";
import { useInfiniteQuery, useQuery } from "react-query";
import axios from "axios";
import Loadings from "../../compoment/common/Loadings";
import {
  BRANDS,
  CURRENCY,
  BRANDS_SKU,
  IMAGE_FROM_WEBSITES,
  SKU_VISIBILITY,
  SKU_STATUS,
} from "../../utils/constant";
import { formatePrice } from "../../utils/common";
import dayjs, { Dayjs } from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

const StatisticsByPeriod: FC = () => {
  const [values, setValues] = useState<{
    specificDate: Dayjs;
  }>({
    specificDate: dayjs("01-06-2023", "DD-MM-YYYY"),
  });

  const handleChange = (e: { target: { name: any; value: any } }) => {
    setValues({
      ...values,
      [e.target.name]: e.target.value,
    });
  };
  const currentDate = new Date();

  // Calculate the date of a week ago
  const lastWeekDate = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    currentDate.getDate() - 7
  );
  const yesterdayDate = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    currentDate.getDate() - 1
  );

  // Format the date as desired
  const formattedLastWeekDate = `${
    lastWeekDate.getMonth() + 1
  }/${lastWeekDate.getDate()}/${lastWeekDate.getFullYear()}`;
  const formattedYesterdayDate = `${
    yesterdayDate.getMonth() + 1
  }/${yesterdayDate.getDate()}/${yesterdayDate.getFullYear()}`;

  const { data, isLoading } = useQuery(
    ["revenueByPeriod", "revenueByPeriod"],
    async () => {
      const response = await axios.post(`/api/v1/orders/getAllOrderList`, {
        revenueByPeriod: "revenueByPeriod",
      });
      return response.data.data;
    }
  );

  return (
    <Box sx={{ margin: " 10px 10px 10px 10px", textAlign: "center" }}>
      {isLoading ? (
        <Box
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          height={"calc( 100vh - 160px)"}
        >
          <Loadings />
        </Box>
      ) : (
        <>
          <Typography variant="h6">Revenue By Period</Typography>
          <TableContainer sx={{ maxHeight: "calc( 100vh - 160px)" }}>
            <Table stickyHeader aria-label="simple table">
              <TableHead>
                <TableRow
                  sx={{
                    "& > th ": {
                      textAlign: "center",
                      fontWeight: 700,
                      backgroundColor: "#514943",
                      color: "white",
                      borderRight: "1px solid #8A837F",
                    },
                    "& > td": {
                      textAlign: "center",
                    },
                  }}
                >
                  <TableCell sx={{ width: "20%" }}>By Period</TableCell>
                  <TableCell>Revenue</TableCell>
                </TableRow>
              </TableHead>
              <TableBody
                sx={{
                  "& > tr": {
                    border: 0,
                  },
                  "& > tr:nth-of-type(even)": {
                    backgroundColor: "#F5F5F5",
                  },
                  "& > tr > td": {
                    textAlign: "center",
                  },
                }}
              >
                <TableRow
                  sx={{ "& > td ": { borderRight: "1px dashed #d6d6d6" } }}
                >
                  <TableCell>Last Week</TableCell>
                  <TableCell>{formatePrice(data?.totalLastWeekRevenue) ?? "0"}</TableCell>
                </TableRow>
                <TableRow
                  sx={{ "& > td ": { borderRight: "1px dashed #d6d6d6" } }}
                >
                  <TableCell>2 Weeks Ago</TableCell>
                  <TableCell>{formatePrice(data?.totalLast2WeeksRevenue) ?? "0"}</TableCell>
                </TableRow>
                <TableRow
                  sx={{ "& > td ": { borderRight: "1px dashed #d6d6d6" } }}
                >
                  <TableCell>Trimester</TableCell>
                  <TableCell>{formatePrice(data?.totalTrimesterRevenue) ?? "0"}</TableCell>
                </TableRow>
                <TableRow
                  sx={{ "& > td ": { borderRight: "1px dashed #d6d6d6" } }}
                >
                  <TableCell>Year</TableCell>
                  <TableCell>{formatePrice(data?.totalYearRevenue) ?? "0"}</TableCell>
                </TableRow>
                <TableRow
                  sx={{ "& > td ": { borderRight: "1px dashed #d6d6d6" } }}
                >
                  <TableCell>Last 30 days (Average)</TableCell>
                  <TableCell>{formatePrice(data?.totalLast30daysRevenue / 30) ?? "0"}</TableCell>
                </TableRow>
                <TableRow
                  sx={{ "& > td ": { borderRight: "1px dashed #d6d6d6" } }}
                >
                  <TableCell>Last 90 days (Average)</TableCell>
                  <TableCell>{formatePrice(data?.totalLast90daysRevenue / 90) ?? "0"}</TableCell>
                </TableRow>
                <TableRow
                  sx={{ "& > td ": { borderRight: "1px dashed #d6d6d6" } }}
                >
                  <TableCell>Last 30 days vs Last 90 days (Average)</TableCell>
                  <TableCell>{data?.totalLast30daysRevenue ===
                    data?.totalLast90daysRevenue
                      ? "0%"
                      : data?.totalLast90daysRevenue === 0
                      ? "0%"
                      : data?.totalLast30daysRevenue /30 >=
                        data?.totalLast90daysRevenue /90
                      ? "+" + formatePrice(((data?.totalLast30daysRevenue / 30) /
                          (data?.totalLast90daysRevenue / 90)) *
                          100 - 100)  +  "%"
                      : formatePrice(((data?.totalLast30daysRevenue / 30) /
                          (data?.totalLast90daysRevenue / 90)) *
                          100 - 100) +  "%"}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </Box>
  );
};

export default StatisticsByPeriod;
