import React, { FC, useState } from "react";
import {
  Box,
  Button,
  TextField,
  FormControl,
  Snackbar,
  FormHelperText,
  FormControlLabel,
  Checkbox,
  Typography,
  createTheme,
  Avatar,
  Paper,
  Grid,
  CssBaseline,
  ThemeProvider,
} from "@mui/material";
import { Link, createSearchParams } from "react-router-dom";
import * as authService from "../../../services/authService";
import { setUser } from "../../../redux/slices/authSlice";
import { Formik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { useAppDispatch } from "../../../redux/store";
import { useNavigate } from "react-router-dom";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";

const defaultTheme = createTheme();

interface LoginProps {}

const TemporaryLogin: FC = () => {
  const [message, setMessage] = React.useState("");
  const navigate = useNavigate();

  const onSubmit = async (values: any) => {
    // console.log(values, "vvv");
    try {
      const response = await axios.get(
        `/api/v1/auth/temporaryLogin/${values.email}`
      );
      if (response.data.status === "Success") {
        setMessage("You are registered");
        // console.log("arrived");
        navigate({pathname: "/auth/verify", search: createSearchParams({email: values.email}).toString()});
        // navigate("/auth/verify", {state: {email: values.email}});
      } else {
        // console.log("You are not registered");
        setMessage("You are not registered");
      }
    } catch (err: any) {
      console.log(
        err.response.data.info.message,
        "err.response.data.info.message"
      );
      console.log(err, "err");
      setMessage(err.response.data.info.message);
    }
  };

  return (
    <ThemeProvider theme={defaultTheme}>
    <Grid
      container
      component="main"
      sx={{
        height: "100vh",
        backgroundImage: "url(/assets/bg2.jpg)",
        backgroundRepeat: "no-repeat",
        backgroundColor: (t) =>
          t.palette.mode === "light"
            ? t.palette.grey[50]
            : t.palette.grey[900],
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <CssBaseline />
      <Grid item xs={false} sm={6} md={8} />
      <Snackbar
        open={!!message}
        autoHideDuration={6000}
        onClose={() => {
          setMessage("");
        }}
        message={message}
      />
      <Grid
        item
        xs={12}
        sm={6}
        md={4}
        component={Paper}
        elevation={6}
        square
        sx={{ opacity: "80%", backgroundColor: "#103d7c" }}
      >
        <Box
          sx={{
            my: 8,
            mx: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
          <LockOutlinedIcon />

          </Avatar>
          <Typography component="h1" variant="h5" sx={{ color: "white" }}>
          Forgot your password?

          </Typography>
          <Formik
            initialValues={{
              email: "",
              submit: null,
            }}
            validationSchema={Yup.object().shape({
              email: Yup.string().email("Invalid format").required(),
            })}
            onSubmit={onSubmit}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
            }) => (
              <form noValidate onSubmit={handleSubmit} style={{ width: "90%" }}>
                <FormControl
                  fullWidth
                  error={Boolean(touched.email && errors.email)}
                >
                  <TextField
                  className="sign-in-side"
                    margin="normal"
                    required
                    fullWidth
                    autoComplete="email"
                    autoFocus
                    name="email"
                    value={values.email}
                    onChange={handleChange}
                    id="email"
                    onBlur={handleBlur}
                    label="Email"
                    variant="outlined"
                    InputLabelProps={{
                      style: { color: "white" },
                    }}
                    // sx={{ mb: 1 }}
                  />
                  {touched.email && errors.email && (
                    <FormHelperText
                      error
                      id="standard-weight-helper-text--register"
                      style={{ color: "white" }}
                    >
                      {errors.email}
                    </FormHelperText>
                  )}
                </FormControl>

              <Box sx={{ display: "flex", justifyContent: "center" , marginTop:"18px"}}>
                <Button
                  fullWidth
                  variant="contained"
                  sx={{ mb: 1 }}
                  type="submit"
                >
                  {isSubmitting ? "Send" : "Send"}
                </Button>
              </Box>
            </form>
          )}
        </Formik>
      </Box>
    </Grid>
    </Grid>
    </ThemeProvider>
  );
};

export default TemporaryLogin;
