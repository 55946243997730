import axios from "axios";
import { useInfiniteQuery, useQuery } from "react-query";
import { IMOrderItem } from "../models/Order";
import dayjs, { Dayjs } from "dayjs";
import { Experimental_CssVarsProvider } from "@mui/material";

export const useOrders = (filters: {
  orderId: string;
  firstname: string;
  lastname: string;
  email: string;
  invoice: string;
  address: string;
  phonenumber: string;
  brands: string[];
  refund: string;
  status: string;
  start_date: Dayjs;
  end_date: Dayjs;
  effisys_exported: string;
  canceled: string;
  closed: string;
  complete: string;
  pending: string;
  sortFlag: string;
}) => {
  return useInfiniteQuery({
    queryKey: ["orders", JSON.stringify(filters)],
    queryFn: async ({ pageParam }) => {
      const searchCriteria = [];

      if (filters.orderId) {
        searchCriteria.push({
          field: "increment_id",
          value: filters.orderId,
          condition_type: "like",
        });
      }
      if (filters.firstname) {
        searchCriteria.push({
          field: "customer_firstname",
          value: filters.firstname,
          condition_type: "like",
        });
      }

      if (filters.lastname) {
        searchCriteria.push({
          field: "customer_lastname",
          value: filters.lastname,
          condition_type: "like",
        });
      }
      if (filters.email) {
        searchCriteria.push({
          field: "customer_email",
          value: filters.email,
          condition_type: "like",
        });
      }
      if (filters.invoice) {
        searchCriteria.push({
          field: "invoice",
          value: filters.invoice,
          condition_type: "like",
        });
      }
      if (filters.address) {
        searchCriteria.push({
          field: "address",
          value: filters.address,
          condition_type: "like",
        });
      }
      if (filters.phonenumber) {
        searchCriteria.push({
          field: "phonenumber",
          value: filters.phonenumber,
          condition_type: "like",
        });
      }
      // console.log(filters.refund, filters.refund === "1", 'redun')
      if (filters.refund === "1") {
        searchCriteria.push({
          field: "base_total_refunded",
          value: 0,
          condition_type: "gt",
        });
      }
      if (filters.status === "Holded") {
        searchCriteria.push({
          field: "status",
          value: "holded",
          condition_type: "like",
        });
      }
      if (filters.effisys_exported === "1") {
        searchCriteria.push({
          field: "status",
          value: "effisys_exported",
          condition_type: "like",
        });
      }
      if (filters.canceled === "1") {
        searchCriteria.push({
          field: "status",
          value: "canceled",
          condition_type: "like",
        });
      }
      if (filters.closed === "1") {
        searchCriteria.push({
          field: "status",
          value: "closed",
          condition_type: "like",
        });
      }
      if (filters.complete === "1") {
        searchCriteria.push({
          field: "status",
          value: "complete",
          condition_type: "like",
        });
      }
      if (filters.pending === "1") {
        searchCriteria.push({
          field: "status",
          value: "pending",
          condition_type: "like",
        });
      }
      if (filters.sortFlag === "0") {
        searchCriteria.push({
          field: "sortOrders",
          value: "desc",
          condition_type: "like",
        });
      } else{
        searchCriteria.push({
          field: "sortOrders",
          value: "asc",
          condition_type: "like",
        });
      }
    //  console.log(filters.start_date.format("YYYY-MM-DD"), "start");

      if ((filters.start_date >= dayjs('2022-04-17')) && (filters.end_date <= dayjs(new Date())))
      {
        searchCriteria.push({
          field: "created_at",
          value: filters.end_date.format("YYYY-MM-DD") + " 23:59:59",
          condition_type: "lteq",
        });
        searchCriteria.push({
          field: "created_at",
          value: filters.start_date.format("YYYY-MM-DD") + " 00:00:00",
          condition_type: "gteq",
        });
        
      }

      // console.log(filters, "filters");
      const currentPage = pageParam?.currentPage ?? 1;
      const response = await axios.post(`/api/v1/orders`, {
        brands: filters.brands,
        searchCriteria,
        currentPage: currentPage,
      });

      return response.data.data;
    },
    getNextPageParam: (lastPage: any) => {
      return lastPage.items && lastPage.items.length >= 10
        ? { currentPage: lastPage.currentPage + 1 }
        : undefined;
    },
    enabled: true,
  });
};


export const useFilterOrders = (filters: {
  orderId: string;
  firstname: string;
  lastname: string;
  email: string;
  invoice: string;
  address: string;
  phonenumber: string;
  brands: string[];
  refund: string;
  status: string;
  start_date: Dayjs;
  end_date: Dayjs;
  effisys_exported: string;
  canceled: string;
  closed: string;
  complete: string;
  pending: string;
  sortFlag: string;
}) => {
  return useQuery({
    queryKey: ["orders1", JSON.stringify(filters)],
    queryFn: async () =>  {
      const searchCriteria = [];

      if (filters.orderId) {
        searchCriteria.push({
          field: "increment_id",
          value: filters.orderId,
          condition_type: "like",
        });
      }
      if (filters.firstname) {
        searchCriteria.push({
          field: "customer_firstname",
          value: filters.firstname,
          condition_type: "like",
        });
      }

      if (filters.lastname) {
        searchCriteria.push({
          field: "customer_lastname",
          value: filters.lastname,
          condition_type: "like",
        });
      }
      if (filters.email) {
        searchCriteria.push({
          field: "customer_email",
          value: filters.email,
          condition_type: "like",
        });
      }
      if (filters.invoice) {
        searchCriteria.push({
          field: "invoice",
          value: filters.invoice,
          condition_type: "like",
        });
      }
      if (filters.address) {
        searchCriteria.push({
          field: "address",
          value: filters.address,
          condition_type: "like",
        });
      }
      if (filters.phonenumber) {
        searchCriteria.push({
          field: "phonenumber",
          value: filters.phonenumber,
          condition_type: "like",
        });
      }
      // console.log(filters.refund, filters.refund === "1", 'redun')
      if (filters.refund === "1") {
        searchCriteria.push({
          field: "base_total_refunded",
          value: 0,
          condition_type: "gt",
        });
      }
      if (filters.status === "Holded") {
        searchCriteria.push({
          field: "status",
          value: "holded",
          condition_type: "like",
        });
      }
      if (filters.effisys_exported === "1") {
        searchCriteria.push({
          field: "status",
          value: "effisys_exported",
          condition_type: "like",
        });
      }
      if (filters.canceled === "1") {
        searchCriteria.push({
          field: "status",
          value: "canceled",
          condition_type: "like",
        });
      }
      if (filters.closed === "1") {
        searchCriteria.push({
          field: "status",
          value: "closed",
          condition_type: "like",
        });
      }
      if (filters.complete === "1") {
        searchCriteria.push({
          field: "status",
          value: "complete",
          condition_type: "like",
        });
      }
      console.log(filters.sortFlag,"sortFlag");
      if (filters.pending === "1") {
        searchCriteria.push({
          field: "status",
          value: "pending",
          condition_type: "like",
        });
      }
      if (filters.sortFlag === "0") {
        searchCriteria.push({
          field: "sortOrders",
          value: "desc",
          condition_type: "like",
        });
      } else{
        searchCriteria.push({
          field: "sortOrders",
          value: "asc",
          condition_type: "like",
        });
      }
    //  console.log(filters.start_date.format("YYYY-MM-DD"), "start");

      if ((filters.start_date >= dayjs('2022-04-17')) && (filters.end_date <= dayjs(new Date())))
      {
        searchCriteria.push({
          field: "created_at",
          value: filters.end_date.format("YYYY-MM-DD") + " 23:59:59",
          condition_type: "lteq",
        });
        searchCriteria.push({
          field: "created_at",
          value: filters.start_date.format("YYYY-MM-DD") + " 00:00:00",
          condition_type: "gteq",
        });
        
      }

      // console.log(filters, "filters");
      // const currentPage = pageParam?.currentPage ?? 1;
      const response = await axios.post(`/api/v1/orders/getFiltersAllOrderList`, {
        brands: filters.brands,
        searchCriteria,
        currentPage: 0,
      });

      return response.data.data;
    },
    enabled: true,
  });
};


export const useOrder = (brand: string, orderId: string) => {
  return useQuery({
    queryKey: ["order", brand, orderId],
    queryFn: async () => {
      const response = await axios.get(`/api/v1/orders/${brand}/${orderId}`);

      return response.data.data as IMOrderItem;
    },
    enabled: !!brand && !!orderId,
  });
};
