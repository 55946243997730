import React, { FC, useEffect, useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import { useInView } from "react-intersection-observer";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

import { IMOrderItem } from "../../models/Order";

import { useOrders } from "../../hooks/useOrders";
import dayjs, { Dayjs } from "dayjs";

import Filters from "./Filters";
import Loadings from "../../compoment/common/Loadings";
import { BRANDS, CURRENCY, NEWBRANDS1, NEWBRANDS2 } from "../../utils/constant";
import { formatePrice } from "../../utils/common";
import { useLogistics } from "../../hooks/useLogistics";
import { ILogisticData, ILogisticReceptData } from "../../models/Logistics";
import { useLogisticsRecept } from "../../hooks/useLogisticsRecept";

const EffisysRecept: FC = () => {
  const [searchParams] = useSearchParams();

  const filters = useMemo<{
    brands: string[];
    invoiceId: string;
    sku: string;
    productName: string;
    start_date: Dayjs;
    end_date: Dayjs;
  }>(() => {
    const filters = {
    brands: searchParams.get("brands")?.split(",") || BRANDS,
      invoiceId: searchParams.get("invoiceId") || "",
      sku: searchParams.get("sku") || "",
      productName: searchParams.get("productName") || "",
      start_date: dayjs(searchParams.get("start_date") || "2023-04-14", "YYYY-MM-DD"),
      end_date: dayjs(searchParams.get("end_date") || dayjs(), "YYYY-MM-DD"),
    };

    return filters;
  }, [searchParams]);

  const { data, isLoading, isFetchingNextPage, fetchNextPage, hasNextPage } =
    useLogisticsRecept(filters);

  const { ref, inView } = useInView({ rootMargin: "500px" });

  useEffect(() => {
    if (inView) {
      fetchNextPage();
    }
  }, [inView, fetchNextPage]);

  return (
    <Box>
      <Filters />
      {isLoading ? (
        <Box
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          height={"calc( 100vh - 160px)"}
        >
          <Loadings />
        </Box>
      ) : (
        <TableContainer sx={{ maxHeight: "calc( 100vh - 160px)" }}>
          <Table stickyHeader aria-label="simple table">
            <TableHead>
              <TableRow
                sx={{
                  "& > th ": {
                    fontWeight: 700,
                    backgroundColor: "#514943",
                    color: "white",
                    borderRight: "1px solid #8A837F",
                  },
                }}
              >
                <TableCell>Brand</TableCell>
                <TableCell>Modify Time</TableCell>
                <TableCell>Invoice Id</TableCell>
                <TableCell>Sku</TableCell>
                <TableCell>ProductName</TableCell>
              </TableRow>
            </TableHead>
            <TableBody
              sx={{
                "& > tr": {
                  border: 0 ,
                },
                "& > tr:nth-of-type(even)": {
                  backgroundColor: "#F5F5F5",
                },
              }}
            >
               {data?.pages?.map((page: any) => (
                <React.Fragment key={page.currentPage}>
                  {page?.items?.map((order: ILogisticReceptData) => (
                    <TableRow
                     key={order.invoiceId}
                      sx={{ "& > td ": { borderRight: "1px dashed #d6d6d6" } }}
                    >
                      <TableCell>{order.brand && NEWBRANDS2[order.brand]}</TableCell>
                      <TableCell>{dayjs(order.modifyTime).format("YYYY-MM-DD HH:mm:ss")}</TableCell>
                      <TableCell>{order.invoiceId}</TableCell>
                      <TableCell>{order.sku}</TableCell>
                      <TableCell>{order.productName}</TableCell>  
                    </TableRow>
                  ))}
                </React.Fragment>
              ))}
              {hasNextPage && (
                <TableRow
                  ref={ref}
                  sx={{ height: "248px" }}
                  hidden={!hasNextPage}
                >
                  <TableCell colSpan={99} align="center">
                    {isFetchingNextPage && (
                      <Box display={"flex"} justifyContent={"center"}>
                        <Loadings />
                      </Box>
                    )}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Box>
  );
};

export default EffisysRecept;
