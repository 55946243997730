import React, { FC } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import { IMStatusHistory } from "../../../models/Order";

const rows = [
  {
    comment:
      'Registered notification about captured amount of 99,00 €. Transaktions-ID: "tr_UUHLvFjNZX"',
    created_at: "2023-05-05 08:03:28",
    entity_id: 209226,
    entity_name: "invoice",
    is_customer_notified: null,
    is_visible_on_front: 0,
    parent_id: 60248,
    status: "processing",
  },
  {
    comment: "Kunde über Rechnung #3000032870 informiert.",
    created_at: "2023-05-05 08:02:28",
    entity_id: 209219,
    entity_name: "order",
    is_customer_notified: 1,
    is_visible_on_front: 0,
    parent_id: 60248,
    status: "processing",
  },
  {
    comment: "E-Mail über neue Bestellung gesendet",
    created_at: "2023-05-05 08:02:28",
    entity_id: 209218,
    entity_name: "order",
    is_customer_notified: 1,
    is_visible_on_front: 0,
    parent_id: 60248,
    status: "processing",
  },
  {
    comment:
      'An amount of 99,00 € will be captured after being approved at the payment gateway. Transaktions-ID: "tr_UUHLvFjNZX"',
    created_at: "2023-05-05 08:02:28",
    entity_id: 209217,
    entity_name: "invoice",
    is_customer_notified: null,
    is_visible_on_front: 0,
    parent_id: 60248,
    status: "payment_review",
  },
  {
    comment: "Kunde zu Mollie weitergeleitet",
    created_at: "2023-05-05 08:02:27",
    entity_id: 209216,
    entity_name: "order",
    is_customer_notified: 0,
    is_visible_on_front: 0,
    parent_id: 60248,
    status: "pending_payment",
  },
  {
    comment:
      "We will authorize 99,00 € after the payment is approved at the payment gateway.",
    created_at: "2023-05-05 08:02:27",
    entity_id: 209215,
    entity_name: "order",
    is_customer_notified: null,
    is_visible_on_front: 0,
    parent_id: 60248,
    status: "payment_review",
  },
];

interface ICommentsHistoryInfoProps {
  commentsHistoryInfo: IMStatusHistory[];
}
const CommentsHistory: FC<ICommentsHistoryInfoProps> = ({commentsHistoryInfo}) => {
  return (
    <>
      <Typography variant="h6">Comments History</Typography>
      <TableContainer>
        <Table aria-label="simple table">
          <TableBody>
            {commentsHistoryInfo.map((row) => (
              <React.Fragment key={row.entity_id}>
                <TableRow sx={{ "& > td ": { border: 0 } }}>
                  <TableCell>{row.created_at}</TableCell>
                  <TableCell sx={{ textTransform: "capitalize" }}>{row.status}</TableCell>
                  <TableCell>
                    {row.is_customer_notified
                      ? "Customer Notified"
                      : "Customer Not Notified"}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan={3}>{row.comment}</TableCell>
                </TableRow>
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default CommentsHistory;
