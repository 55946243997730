import React, { FC, useEffect, useMemo, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useInView } from "react-intersection-observer";
import {
  Badge,
  BadgeProps,
  Box,
  Checkbox,
  FormControlLabel,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  styled,
} from "@mui/material";

import { IMOrderItem } from "../../models/Order";

import { useOrders } from "../../hooks/useOrders";
import dayjs, { Dayjs } from "dayjs";

import Filters from "./Filters";
import Loadings from "../../compoment/common/Loadings";
import { BRANDS, CURRENCY } from "../../utils/constant";
import { formatePrice } from "../../utils/common";
import PublishedWithChangesIcon from "@mui/icons-material/PublishedWithChanges";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";

const Orders: FC = () => {
  const searchRef = useRef<HTMLButtonElement>(null);
  const [searchParams] = useSearchParams();
  const [selectedOrder, setSelectedOrder] = useState<String[]>([]);
  const [sortFlag, setSortFlag] = useState<Number>(0);

  const filters = useMemo<{
    orderId: string;
    firstname: string;
    lastname: string;
    email: string;
    invoice: string;
    address: string;
    phonenumber: string;
    brands: string[];
    refund: string;
    status: string;
    start_date: Dayjs;
    end_date: Dayjs;
    effisys_exported: string;
    canceled: string;
    closed: string;
    complete: string;
    pending: string;
    sortFlag: string;
  }>(() => {
    const filters = {
      orderId: searchParams.get("orderId") || "",
      firstname: searchParams.get("firstname") || "",
      lastname: searchParams.get("lastname") || "",
      email: searchParams.get("email") || "",
      invoice: searchParams.get("invoice") || "",
      address: searchParams.get("address") || "",
      phonenumber: searchParams.get("phonenumber") || "",
      brands: searchParams.get("brands")?.split(",") || BRANDS,
      refund: searchParams.get("refund") || "",
      status: searchParams.get("status") || "",
      effisys_exported: searchParams.get("effisys_exported") || "",
      canceled: searchParams.get("canceled") || "",
      closed: searchParams.get("closed") || "",
      complete: searchParams.get("complete") || "",
      pending: searchParams.get("pending") || "",
      sortFlag: sortFlag + "" || "0",
      start_date: dayjs(searchParams.get("start_date") || "", "YYYY-MM-DD"),
      end_date: dayjs(searchParams.get("end_date") || "", "YYYY-MM-DD"),
    };

    return filters;
  }, [searchParams, sortFlag]);
  console.log(filters, "filters");
  const { data, isLoading, isFetchingNextPage, fetchNextPage, hasNextPage } =
    useOrders(filters);

  const { ref, inView } = useInView({ rootMargin: "500px" });
  useEffect(() => {
    if (inView) {
      fetchNextPage();
    }
  }, [inView, fetchNextPage]);

  const handleCheckedChange =
    (id: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
      // console.log(event.target.checked);
      let selectedItems: any = selectedOrder;
      if (event.target.checked === true) {
        setSelectedOrder([...selectedItems, id]);
      } else {
        setSelectedOrder(selectedItems.filter((_id: string) => _id !== id));
      }
    };

  function DeselectAll() {
    setSelectedOrder([]);
  }

  function SortClick() {
    setSortFlag((p) => {
      return (Number(p) + 1) % 2;
    });

    searchRef.current?.click();
  }

  return (
    <Box>
      <Filters exportData={selectedOrder} searchRef={searchRef} />
      {isLoading ? (
        <Box
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          height={"calc( 100vh - 160px)"}
        >
          <Loadings />
        </Box>
      ) : (
        <TableContainer sx={{ maxHeight: "calc( 100vh - 160px)" }}>
          <Table stickyHeader aria-label="simple table">
            <TableHead>
              <TableRow
                sx={{
                  "& > th ": {
                    fontWeight: 700,
                    backgroundColor: "#514943",
                    color: "white",
                    borderRight: "1px solid #8A837F",
                  },
                }}
              >
                <TableCell sx={{ alignItems: "center" }}>
                  <IconButton
                    color="primary"
                    aria-label="add to shopping cart"
                    onClick={DeselectAll}
                  >
                    <PublishedWithChangesIcon />
                  </IconButton>
                  Ids
                </TableCell>
                <TableCell>Purchase Point</TableCell>
                <TableCell>
                  <Box sx={{display:"flex"}}>
                  <IconButton
                    color="primary"
                    aria-label="add to shopping cart"
                    onClick={SortClick}
                  >
                    {sortFlag === 0 ? (
                      <ArrowDownwardIcon />
                    ) : (
                      <ArrowUpwardIcon />
                    )}
                  </IconButton>
                  Purchase Date
                  </Box>
                  
                </TableCell>
                <TableCell>Bill-to Name</TableCell>
                <TableCell>Ship-to Name</TableCell>
                <TableCell>Grand Total (Base)</TableCell>
                <TableCell>Grand Total (Purchased)</TableCell>
                <TableCell>Refund Total (Base)</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Customer Email</TableCell>
                <TableCell>Customer Name</TableCell>
                <TableCell>Payment Method</TableCell>
                <TableCell>Invoice</TableCell>
                <TableCell>Sku</TableCell>
                {/* <TableCell>PhoneNumber</TableCell> */}
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody
              sx={{
                "& > tr": {
                  border: 0,
                },
                "& > tr:nth-of-type(even)": {
                  backgroundColor: "#F5F5F5",
                },
              }}
            >
              {data?.pages?.map((page: any, index: any) => (
                <React.Fragment key={page.currentPage}>
                  {page?.items?.map((order: IMOrderItem) => (
                    <TableRow
                      key={order.brand + order.increment_id}
                      sx={{ "& > td ": { borderRight: "1px dashed #d6d6d6" } }}
                    >
                      <TableCell scope="row">
                        <Checkbox
                          checked={selectedOrder.includes(order.increment_id)}
                          onChange={handleCheckedChange(order.increment_id)}
                        />
                        {order.increment_id}
                        {order.extension_attributes.is_recurring_order ===
                        true ? (
                          <span>
                            <br></br>
                            <span
                              style={{
                                color: "white",
                                backgroundColor: "#007bff",
                                borderRadius: "10rem",
                                paddingRight: "0.6em",
                                paddingLeft: "0.6em",
                              }}
                            >
                              Abo
                            </span>
                          </span>
                        ) : (
                          ""
                        )}
                        {order.extension_attributes.is_api === true ? (
                          <span>
                            <br></br>
                            <span
                              style={{
                                color: "white",
                                backgroundColor: "#17a2b8",
                                borderRadius: "10rem",
                                paddingRight: "0.6em",
                                paddingLeft: "0.6em",
                              }}
                            >
                              Bdc
                            </span>
                          </span>
                        ) : (
                          ""
                        )}
                      </TableCell>

                      <TableCell>{order.store_name}</TableCell>
                      <TableCell>{order.created_at}</TableCell>
                      <TableCell>
                        {order.billing_address.firstname +
                          " " +
                          order.billing_address.lastname}
                      </TableCell>
                      <TableCell>
                        {order.extension_attributes.shipping_assignments?.[0]
                          .shipping.address.firstname +
                          " " +
                          order.extension_attributes.shipping_assignments?.[0]
                            .shipping.address.lastname}
                      </TableCell>
                      <TableCell sx={{ padding: "10px" }}>
                        {CURRENCY[order?.order_currency_code]}{" "}
                        {formatePrice(order?.base_grand_total)}
                      </TableCell>
                      <TableCell>
                        {CURRENCY[order?.order_currency_code]}{" "}
                        {formatePrice(order?.grand_total)}
                      </TableCell>
                      <TableCell>
                        {CURRENCY[order?.order_currency_code]}{" "}
                        {formatePrice(order?.base_total_refunded)}
                      </TableCell>
                      <TableCell sx={{ textTransform: "capitalize" }}>
                        {order.status}
                      </TableCell>
                      <TableCell>{order.customer_email}</TableCell>
                      <TableCell>
                        {order.customer_firstname +
                          " " +
                          order.customer_lastname}
                      </TableCell>

                      <TableCell>
                        {
                          order.extension_attributes.payment_additional_info?.filter(
                            (info) => info.key === "method_title"
                          )[0].value
                        }
                      </TableCell>

                      {/* invoice -------------*/}
                      <TableCell>{order.total_invoiced}</TableCell>
                      <TableCell>{order.items?.[0].sku}</TableCell>
                      {/* <TableCell>
                        {order.billing_address.telephone}
                      </TableCell> */}
                      {/* phonenumber-------------- */}

                      <TableCell>
                        <a
                          target={"_blank"}
                          href={`/orderdetail/${order.brand}/${order.entity_id}`}
                          rel="noreferrer"
                        >
                          View
                        </a>
                      </TableCell>
                    </TableRow>
                  ))}
                </React.Fragment>
              ))}
              {hasNextPage && (
                <TableRow
                  ref={ref}
                  sx={{ height: "248px" }}
                  hidden={!hasNextPage}
                >
                  <TableCell colSpan={99} align="center">
                    {isFetchingNextPage && (
                      <Box display={"flex"} justifyContent={"center"}>
                        <Loadings />
                      </Box>
                    )}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Box>
  );
};

export default Orders;
