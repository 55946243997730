import React, { FC, useRef, useState } from "react";
import {
  Box,
  Button,
  TextField,
  FormControl,
  Snackbar,
  FormHelperText,
  FormControlLabel,
  Checkbox,
  ThemeProvider,
  createTheme,
  Grid,
  Avatar,
  Typography,
  CssBaseline,
  Paper,
} from "@mui/material";
import { Link, NavLink } from "react-router-dom";
import * as authService from "../../../services/authService";
import { useAppDispatch } from "../../../redux/store";
import { setUser } from "../../../redux/slices/authSlice";
import { Formik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
const defaultTheme = createTheme();

interface LoginProps {}

const Login: FC<LoginProps> = () => {
  const dispatch = useAppDispatch();
  const [message, setMessage] = React.useState("");

  const [checked, setChecked] = React.useState(true);
  const navigate = useNavigate();

  const handleChangeChecked = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };
  const handleTemporaryLogin = async (email: string) => {
    // const response = await axios.post(`/api/v1/auth/user/${email}`);
    // localStorage.setItem("token", response.data.token);
    // console.log( email,  "current email");

    navigate("/auth/temporaryLogin");
  };

  const onSubmit = (values: any) => {
    authService
      .login(values.email, values.password, checked)
      .then((res: any) => {
        dispatch(setUser(res.user));
      })
      .catch((err) => {
        setMessage(err.response.data.info.message);
        dispatch(setUser(null));
      });
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Grid
        container
        component="main"
        sx={{
          height: "100vh",
          backgroundImage: "url(/assets/bg4.jpg)",
          backgroundRepeat: "no-repeat",
          backgroundColor: (t) =>
            t.palette.mode === "light"
              ? t.palette.grey[50]
              : t.palette.grey[900],
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <CssBaseline />
        <Grid item xs={false} sm={6} md={8} />
        <Snackbar
          open={!!message}
          autoHideDuration={6000}
          onClose={() => {
            setMessage("");
          }}
          message={message}
        />
        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          component={Paper}
          elevation={6}
          square
          sx={{ opacity: "80%", backgroundColor: "#103d7c" }}
        >
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5" sx={{ color: "white" }}>
              Sign in
            </Typography>
            <Formik
            
              initialValues={{
                email: "",
                password: "",
                submit: null,
              }}
              validationSchema={Yup.object().shape({
                email: Yup.string().email("Invalid format").required(),
                password: Yup.string().min(1).max(255).required(),
              })}
              onSubmit={onSubmit}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values,
              }) => (
                <form noValidate onSubmit={handleSubmit} style={{ width: "90%" }}>
                  <FormControl
                    fullWidth
                    error={Boolean(touched.email && errors.email)}
                    className="sign-in-side"
                  >
                    <TextField
                      margin="normal"
                      required
                      fullWidth
                      autoComplete="email"
                      autoFocus
                      name="email"
                      value={values.email}
                      onChange={handleChange}
                      id="email"
                      onBlur={handleBlur}
                      label="Email"
                      variant="outlined"
                      InputLabelProps={{
                        style: { color: "white" },
                      }}

                      sx={{ mb: 1 }}
                    />
                    {touched.email && errors.email && (
                      <FormHelperText
                        error
                        id="standard-weight-helper-text--register"
                        style={{ color: "white" }}
                      >
                        {errors.email}
                      </FormHelperText>
                    )}
                  </FormControl>
                  <FormControl
                    fullWidth
                    error={Boolean(touched.password && errors.password)}
                  >
                    <TextField
                    className="sign-in-side"
                      name="password"
                      value={values.password}
                      onChange={handleChange}
                      id="password"
                      onBlur={handleBlur}
                      label="PassWord"
                      variant="outlined"
                      type="password"
                      // sx={{ mb: 1 }}
                      margin="normal"
                      required
                      autoComplete="current-password"
                      fullWidth
                      InputLabelProps={{
                        style: { color: "white" },
                      }}
                    />
                    {touched.password && errors.password && (
                      <FormHelperText
                        error
                        id="standard-weight-helper-text--register"
                        style={{ color: "white" }}
                      >
                        {errors.password}
                      </FormHelperText>
                    )}
                  </FormControl>
                  <Grid container sx={{ alignItems: "center", marginTop:"18px"}}>
                    <Grid item xs>
                      <FormControlLabel
                        // label="Remember this account"
                        label={
                          <span style={{ color: "white" }}>
                            Remember this account
                          </span>
                        }
                        control={
                          <Checkbox
                            checked={checked}
                            onChange={handleChangeChecked}
                            inputProps={{ "aria-label": "controlled" }}
                          />
                        }
                      />
                    </Grid>
                    <Grid item>
                      <Box sx={{ color: "white" }}>
                        <Link
                          // sx={{ marginLeft: "10px" }}
                          // variant="outlined"
                          onClick={() => handleTemporaryLogin(values.email)}
                          to={""}
                          style={{ color: "white" }}
                        >
                          Forgot password?
                        </Link>
                      </Box>
                    </Grid>
                  </Grid>
                  <Box sx={{ display: "flex", justifyContent: "center", marginTop:"18px" }}>
                    <Button
                      fullWidth
                      variant="contained"
                      sx={{ mb: 1 }}
                      type="submit"
                    >
                      {isSubmitting ? "SIGN IN " : "SIGN IN"}
                    </Button>
                  </Box>
                </form>
              )}
            </Formik>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
};

export default Login;
