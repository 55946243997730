import React, { FC } from "react";
import { Link, Outlet, Navigate } from "react-router-dom";
import { Box } from "@mui/material";
import { useSelector } from "react-redux";
import { selectIsLogged } from "../../redux/slices/authSlice";

interface AuthProps {}

const Auth: FC<AuthProps> = () => {
  const isLogged = useSelector(selectIsLogged);

  if (isLogged) {
    return <Navigate to={"/orders"} />;
  }

  return (
    <Box>
      <Outlet />
    </Box>
  );
};

export default Auth;
