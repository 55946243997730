import { FC } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";

export interface IOrderInfo {
  increment_id: string;
  orderDate: string;
  orderStatus: string;
  purchaseFrom: string;
  placedFromIP: string;
}

interface OrderInfoProps {
  orderInfo: IOrderInfo;
}

const OrderInfo: FC<OrderInfoProps> = ({ orderInfo }) => {
  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <Typography variant="h6">Order # {orderInfo.increment_id}</Typography>
        <TableContainer>
          <Table aria-label="simple table">
            <TableBody>
              <TableRow>
                <TableCell sx={{ fontWeight: '700' }}>Order Date</TableCell>
                <TableCell align="right"> {orderInfo.orderDate}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ fontWeight: '700' }}>Order Status</TableCell>
                <TableCell align="right" sx={{ textTransform: "capitalize" }}>
                  {" "}
                  {orderInfo.orderStatus}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ fontWeight: '700' }}>Purchased From</TableCell>
                <TableCell align="right">{orderInfo.purchaseFrom}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ fontWeight: '700' }}>Placed from IP</TableCell>
                <TableCell align="right"> {orderInfo.placedFromIP}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </>
  );
};

export default OrderInfo;
