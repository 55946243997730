import { FC } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { IMItem } from "../../../models/Order";
import { CURRENCY } from "../../../utils/constant";
import { formatePrice } from "../../../utils/common";

function createData(
  product: string,
  itemStatus: string,
  originalPrice: string,
  price: string,
  qty: string,
  subtotal: number,
  taxAmount: string,
  taxPercent: string,
  discountAmount: string,
  rowTotal: string
) {
  return {
    product,
    itemStatus,
    originalPrice,
    price,
    qty,
    subtotal,
    taxAmount,
    taxPercent,
    discountAmount,
    rowTotal,
  };
}


export interface IItemInfo {
  data: IMItem[]
  currencycode: string;
}



export interface IItemInfoProps {
  itemInfo:IItemInfo,
}


const ItemOrdered: FC<IItemInfoProps> = ({ itemInfo }) => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <TableContainer>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow sx={{ "& > th ": { fontWeight: 700 } }}>
              <TableCell>Product</TableCell>
              <TableCell>Item Status</TableCell>
              <TableCell>Original Price</TableCell>
              <TableCell>Price</TableCell>
              <TableCell>Qty</TableCell>
              <TableCell>Subtotal</TableCell>
              <TableCell>Tax Amount</TableCell>
              <TableCell>Tax Percent</TableCell>
              <TableCell>Discount Amount</TableCell>
              <TableCell>Row Total</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {itemInfo?.data?.map((row) => (
              <TableRow
                key={row.item_id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell scope="row">
                  {row.name}
                  <br />
                  SKU:{row.sku}
                </TableCell>
                <TableCell >
                  {row.qty_canceled ? `Canceled \n`:row.qty_refunded ? `Refunded \n` :row.qty_invoiced ? `Invoiced \n` : `Ordered\n`}
                </TableCell>
                <TableCell >{CURRENCY[itemInfo.currencycode]}{" "}{formatePrice(row.original_price)}</TableCell>
                <TableCell >{CURRENCY[itemInfo.currencycode]}{" "}{formatePrice(row.base_price_incl_tax)}</TableCell>
                <TableCell >
                  {row.qty_ordered ? `Ordered ${row.qty_ordered}\n` : ""}
                  {row.qty_invoiced ? `Invoiced ${row.qty_invoiced}\n` : ""}
                  {row.qty_refunded ? `Refunded ${row.qty_refunded}\n` : ""}
                  {row.qty_canceled ? `Canceled ${row.qty_canceled}\n` : ""}
                </TableCell>
                <TableCell >{CURRENCY[itemInfo.currencycode]}{" "}{formatePrice(row.base_row_total_incl_tax)}</TableCell>
                <TableCell >{CURRENCY[itemInfo.currencycode]}{" "}{formatePrice(row.tax_amount)}</TableCell>
                <TableCell >{row.tax_percent}%</TableCell>
                <TableCell >{CURRENCY[itemInfo.currencycode]}{" "}{formatePrice(row.discount_amount)}</TableCell>
                <TableCell >{CURRENCY[itemInfo.currencycode]}{" "}{formatePrice(row.row_total_incl_tax)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default ItemOrdered;
