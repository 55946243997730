import { FC } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import { IMPayment, IMPaymetAdditionalInfo } from "../../../models/Order";

export interface IPaymentInfo {
  paymentInfo_detail: IMPaymetAdditionalInfo[];
  payment: IMPayment;
}

interface IPaymentInfoProps {
  paymentInfo: IPaymentInfo
}

const PaymentInfo: FC<IPaymentInfoProps> = ({ paymentInfo }) => {
  return (
    <>
      <Typography variant="h6">Payment Information</Typography>
      <TableContainer>
        <Table aria-label="simple table">
          <TableBody>
            <TableRow>
              <TableCell sx={{ fontWeight: "700" }}>Payment Method</TableCell>
              <>
              {console.log(paymentInfo.paymentInfo_detail,"paymentInfo")}
              </>
              <TableCell align="right">
                {
                  paymentInfo.paymentInfo_detail.filter((item) => item.key === "method_title")?.[0] //// Credit Card, Stripe
                    ?.value
                }
              </TableCell>
            </TableRow>
            {paymentInfo.paymentInfo_detail.filter((item) => item.key === "method_title")?.[0]
              ?.value === "Credit Cards" ? (
              <>
                <TableRow>
                  <TableCell sx={{ fontWeight: "700" }}>
                    Checkout Type
                  </TableCell>
                  <TableCell align="right" sx={{ textTransform: "capitalize" }}>
                    {
                      paymentInfo.paymentInfo_detail.filter(
                        (item) => item.key === "checkout_type"
                      )?.[0]?.value
                    }
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{ fontWeight: "700" }}>
                    Payment Status
                  </TableCell>
                  <TableCell align="right" sx={{ textTransform: "capitalize" }}>
                    {
                      paymentInfo.paymentInfo_detail.filter(
                        (item) => item.key === "payment_status"
                      )?.[0]?.value
                    }
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{ fontWeight: "700" }}>Mollie ID</TableCell>
                  <TableCell align="right">
                    <a
                      href={
                        paymentInfo.paymentInfo_detail.filter(
                          (item) => item.key === "dashboard_url"
                        )?.[0]?.value
                      }
                      target="_blank" rel="noreferrer"
                    >
                      {
                        paymentInfo.paymentInfo_detail.filter(
                          (item) => item.key === "mollie_id"
                        )?.[0]?.value
                      }
                    </a>
                  </TableCell>
                </TableRow>
              </>
            ) : (
              <>
              <TableRow>
              <TableCell sx={{ fontWeight: '700' }}>Payment</TableCell>
              <TableCell align="right">
                {/* <a
                  href={"https://dashboard.stripe.com/test/payments/" +
                    paymentInfo.payment.last_trans_id
                  }
                  target="_blank" rel="noreferrer"
                  
                > */}
                  {paymentInfo.payment.last_trans_id}
                 
                {/* </a> */}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={{ fontWeight: '700' }}>Customer</TableCell>
              <TableCell align="right">
                {/* <a
                  href={"https://dashboard.stripe.com/test/customers/" +
                    paymentInfo.paymentInfo_detail.filter((item) => item.key === "customer_stripe_id")?.[0]
                      ?.value
                  }
                  target="_blank" rel="noreferrer"
                  
                > */}
                  {paymentInfo.paymentInfo_detail.filter((item) => item.key === "paypal_payer_id")?.[0]
                      ?.value}
                {/* </a> */}
              <>
              {console.log(paymentInfo)}
              </>   

              </TableCell>
            </TableRow>
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default PaymentInfo;
