import { FC, useState } from "react";
import { useSearchParams } from "react-router-dom";
import {
  Box,
  Checkbox,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { BRANDS, NEWBRANDS, NEWBRANDS1 } from "../../utils/constant";
import dayjs, { Dayjs } from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
interface FiltersProps {
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const Filters: FC<FiltersProps> = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [values, setValues] = useState<{
    invoice: string;
    brands: string[];
    start_date: Dayjs;
    end_date: Dayjs;
  }>({
    invoice: searchParams.get("invoice") || "",
    brands: searchParams.get("brands")?.split(",") || BRANDS,
    start_date: dayjs(searchParams.get("start_date")||"01-06-2023", "DD-MM-YYYY"),
    end_date: dayjs(searchParams.get("end_date")||dayjs(), "DD-MM-YYYY"),
  });

  const handleChange = (e: { target: { name: any; value: any } }) => {
    setValues({
      ...values,
      [e.target.name]: e.target.value,
    });
  };

  const handleApply = () => {
    const params = new URLSearchParams();
    if (values.invoice) {
      params.set("invoice", values.invoice);
    }

    if (values.brands) {
      params.set("brands", values.brands.join(","));
    }
    if (values.start_date) {
      params.set("start_date", values.start_date.format("YYYY-MM-DD"));
    }
    if (values.end_date) {
      params.set("end_date", values.end_date.format("YYYY-MM-DD"));
    }
    setSearchParams(params);

    
  };
  return (
    <Box
      sx={{
        padding: "12px",
      }}
    >
          <LocalizationProvider dateAdapter={AdapterDayjs}>

      <Grid container alignItems={"center"} spacing={"8px"}>
        <Grid item md={1} sm={4} xs={12}>
          <Typography variant="h6">Invoice</Typography>
        </Grid>
        <Grid item md={2} sm={4} xs={12}>
          <TextField
            name="invoice"
            label="Invoice"
            type="search"
            value={values.invoice}
            onChange={handleChange}
          />
        </Grid>
        <Grid item md={2} sm={4} xs={12}>
            
              <DatePicker
                label="Start Date"
                defaultValue={dayjs('2022-04-17')}
                value={values.start_date}
                onChange={(newValue) => handleChange({ target: { name: 'start_date', value: newValue }})}
              />
        </Grid>
        <Grid item md={2} sm={4} xs={12}>
              <DatePicker
                label="End Date"
                defaultValue={dayjs(new Date())}
                value={values.end_date}
                onChange={(newValue) => handleChange({ target: { name: 'end_date', value: newValue }})}
              />
        </Grid>
        <Grid item md={4} sm={4} xs={12}>
          <FormControl sx={{ width: "100%" }}>
            <InputLabel id="brands-label">Brand</InputLabel>
            <Select
              labelId="brands-label"
              multiple
              name={"brands"}
              value={values.brands}
              onChange={handleChange}
              input={<OutlinedInput label="Brand" />}
              type="search"
              renderValue={(selected: any) =>
                selected.map((item: any) => NEWBRANDS1[item]).join(", ")
              }
              MenuProps={MenuProps}
            >
              {NEWBRANDS.map((brand) => (
                <MenuItem key={brand.value} value={brand.value}>
                  <Checkbox checked={values.brands.includes(brand.value)} />
                  <ListItemText primary={brand.label} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item md={1} sm={4} xs={12}>
          <Box>
            <IconButton sx={{ float: "right" }} onClick={handleApply}>
              <SearchIcon />
            </IconButton>
          </Box>
        </Grid>
        
        <Grid item md={2} sm={4} xs={12}></Grid>
        {/*  <Grid item md={4} sm={4} xs={12}>
          <TextField
            name="address"
            label="Address"
            type="search"
            value={values.address}
            onChange={handleChange}
            sx={{ width: "100%" }}
          />
        </Grid> */}
      </Grid>
      </LocalizationProvider>

    </Box>
  );
};

export default Filters;
