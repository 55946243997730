import { TableCell, TableRow } from "@mui/material";
import axios from "axios";
import React, { FC, useEffect, useState } from "react";
import { IMInvoiceItem } from "../../models/Invoice";
import { formatePrice } from "../../utils/common";
import { CURRENCY } from "../../utils/constant";
import Loadings from "../common/Loadings";

interface InvoiceRowProps {
  brand?: string;
  orderId: string;
}

const InvoiceRow: FC<InvoiceRowProps> = ({ brand = "", orderId = "" }) => {
  const [invoice, setInvoice] = useState<IMInvoiceItem>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  useEffect(() => {
    (async () => {
      setIsLoading(true);
      const response = await axios.get(
        `/api/v1/invoices/from_order_id/${brand}/${orderId}`
      );
      if (response.data.data) {

        // console.log(response.data.data, "invoice")
        setInvoice(response.data.data.items[0]);
      }
      setIsLoading(false);
    })();
  }, [brand, orderId]);

  if (!invoice) {
    return null;
  }
  return (
      <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
        {isLoading || !invoice ? (
          <TableCell colSpan={999}>
            <Loadings />
          </TableCell>
        ) : (
          <>
            <TableCell scope="row">{invoice.increment_id}</TableCell>
            <TableCell>{invoice.created_at}</TableCell>
            <TableCell>
              {CURRENCY[invoice?.base_currency_code]}{" "}
              {formatePrice(invoice?.base_grand_total)}
            </TableCell>
            <TableCell>
              {CURRENCY[invoice?.base_currency_code]}{" "}
              {formatePrice(invoice?.grand_total)}
            </TableCell>
            <TableCell>
              <a
                target={"_blank"}
                href={`/invoicedetail/${invoice.brand}/${invoice.order_id}/${invoice.entity_id}`}
                rel="noreferrer"
              >
                View
              </a>
            </TableCell>
          </>
        )}
      </TableRow>
  );
};

export default InvoiceRow;
