import { FC, useMemo } from "react";
import { useParams } from "react-router-dom";
import { Box, Grid, Typography } from "@mui/material";
import { useOrder } from "../hooks/useOrders";
import OrderInfo, {
  IOrderInfo,
} from "../compoment/orders/orderDetails/OrderInfo";
import AccountInfo, {
  IAccountInfo,
} from "../compoment/orders/orderDetails/AccountInfo";
import BillingAdress from "../compoment/orders/orderDetails/BillingAddress";
import ShippingAdress from "../compoment/orders/orderDetails/ShippingAddress";
import PaymentInfo from "../compoment/invoices/invoiceDetails/PaymentInfo";
import ShippingHandlingInfo from "../compoment/orders/orderDetails/ShippingHandlingInfo";
import ItemInvoiced from "../compoment/invoices/invoiceDetails/ItemInvoiced";
import CommentsHistory from "../compoment/orders/orderDetails/CommentsHistory";
import InvoiceTotals from "../compoment/invoices/invoiceDetails/InvoiceTotals";
import { IMAddress } from "../models/Order";
import Loadings from "../compoment/common/Loadings";
import { useInvoice } from "../hooks/useInvoice";

const Invoices: FC = () => {
  const { brand ,orderId, invoiceId} = useParams();

  // console.log(orderId, "orderid");
  //  console.log(invoiceId, "invoiceId");
  const { data, isLoading } = useOrder(brand || "", orderId || "");
  const { data : invoice_data, isLoading : invoice_loading } = useInvoice(brand || "", invoiceId || "");


  // console.log(invoice_data, "invoice_data");

  const accountInfo = useMemo<IAccountInfo>(
    () => ({
      customerName:
        data?.customer_firstname + " " + data?.customer_lastname ?? "",
      customerEmail: data?.customer_email ?? "",
    }),
    [data]
  );

  const orderInfo = useMemo<IOrderInfo>(
    () => ({
      increment_id: data?.increment_id ?? "",
      orderDate: data?.created_at ?? "",
      orderStatus: data?.status ?? "",
      purchaseFrom: data?.store_name ?? "",
      placedFromIP: data?.remote_ip ?? "",
    }),
    [data]
  );

  const billingAddressInfo = useMemo<IMAddress>(
    () => ({
      address_type: data?.billing_address.address_type ?? "",
      city: data?.billing_address.city ?? "",
      country_id: data?.billing_address.country_id ?? "",
      email: data?.billing_address.email ?? "",
      entity_id: data?.billing_address.entity_id ?? 0,
      firstname: data?.billing_address.firstname ?? "",
      lastname: data?.billing_address.lastname ?? "",
      parent_id: data?.billing_address.parent_id ?? 0,
      postcode: data?.billing_address.postcode ?? "",
      street: data?.billing_address.street ?? [],
      telephone: data?.billing_address.telephone ?? "",
    }),
    [data]
  );

  const shippingAddressInfo = useMemo<IMAddress>(
    () => ({
      address_type:
        data?.extension_attributes?.shipping_assignments?.[0]?.shipping?.address
          .address_type ?? "",
      city:
        data?.extension_attributes?.shipping_assignments?.[0]?.shipping?.address
          .city ?? "",
      country_id:
        data?.extension_attributes?.shipping_assignments?.[0]?.shipping?.address
          .country_id ?? "",
      email:
        data?.extension_attributes?.shipping_assignments?.[0]?.shipping?.address
          .email ?? "",
      entity_id:
        data?.extension_attributes?.shipping_assignments?.[0]?.shipping?.address
          .entity_id ?? 0,
      firstname:
        data?.extension_attributes?.shipping_assignments?.[0]?.shipping?.address
          .firstname ?? "",
      lastname:
        data?.extension_attributes?.shipping_assignments?.[0]?.shipping?.address
          .lastname ?? "",
      parent_id:
        data?.extension_attributes?.shipping_assignments?.[0]?.shipping?.address
          .parent_id ?? 0,
      postcode:
        data?.extension_attributes?.shipping_assignments?.[0]?.shipping?.address
          .postcode ?? "",
      street:
        data?.extension_attributes?.shipping_assignments?.[0]?.shipping?.address
          .street ?? [],
      telephone:
        data?.extension_attributes?.shipping_assignments?.[0]?.shipping?.address
          .telephone ?? "",
    }),
    [data]
  );

  const paymentInfo = useMemo(
    () => ({
      paymentInfo_detail:data?.extension_attributes.payment_additional_info ?? [],
      payment: data?.payment ?? {
        account_status: "",
        additional_information: [""],
        amount_authorized: 0,
        amount_ordered: 0,
        base_amount_authorized: 0,
        base_amount_ordered: 0,
        base_shipping_amount: 0,
        cc_exp_year: "",
        cc_last4: "",
        cc_ss_start_month: "",
        cc_ss_start_year: "",
        entity_id: 0,
        last_trans_id: "",
        method: "",
        parent_id: 0,
        shipping_amount: 0,
      },
    }),
    [data]
  );

  const shippingHandlingInfo = useMemo(
    () => ({
      shipping_description: data?.shipping_description ?? "",
      store_currency_code: data?.store_currency_code ?? "",
      shipping_discount_amount: data?.shipping_discount_amount ?? 0,
    }),
    [data]
  );

  const itemInfo = useMemo(
    () => ({
      data: invoice_data?.items ?? [],
      currencycode: invoice_data?.order_currency_code ?? "USD",
    }),
    [invoice_data]
  );

  const commentsHistoryInfo = useMemo(
    () => data?.status_histories ?? [],
    [data]
  );

  const orderTotalsInfo = useMemo(
    () => ({
      base_shipping_amount: data?.base_shipping_amount ?? 0,
      base_subtotal: data?.base_subtotal ?? 0,
      base_subtotal_incl_tax: data?.base_subtotal_incl_tax ?? 0,
      base_tax_amount: data?.base_tax_amount ?? 0,
      base_tax_invoiced: data?.base_tax_invoiced ?? 0,
      base_total_due: data?.base_total_due ?? 0,
      base_total_refunded: data?.base_total_refunded ?? 0,
      base_grand_total: data?.base_grand_total ?? 0,
      base_total_paid: data?.base_total_paid ?? 0,
      applied_taxes: data?.extension_attributes.applied_taxes ?? [],
      currencycode: data?.order_currency_code??"EUR"
    }),
    [data]
  );

  return (
    <Box sx={{ padding: "16px" }}>
      {isLoading ? (
        <Box
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          height={"100vh"}
        >
          <Loadings />
        </Box>
      ) : (
        <Grid container spacing={5}>
          <Grid item xs={12}>
            <Typography variant="h5">Order & Account Information</Typography>
            <hr />
          </Grid>
          <Grid item xs={6}>
            <OrderInfo orderInfo={orderInfo} />
          </Grid>
          <Grid item xs={6}>
            <AccountInfo accountInfo={accountInfo} />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h5">Address Information</Typography>
            <hr />
          </Grid>
          <Grid item xs={6}>
            <BillingAdress billingAddressInfo={billingAddressInfo} />
          </Grid>
          <Grid item xs={6}>
            <ShippingAdress shippingAddressInfo={shippingAddressInfo} />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h5">Payment & Shipping Method</Typography>
            <hr />
          </Grid>
          <Grid item xs={6}>
            <PaymentInfo paymentInfo={paymentInfo} />
          </Grid>
          <Grid item xs={6}>
            <ShippingHandlingInfo shippingHandlingInfo={shippingHandlingInfo} />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h5">Items Invoiced</Typography>
            <hr />
          </Grid>
          <Grid item xs={12}>
            <ItemInvoiced itemInfo={itemInfo} />
          </Grid>
          <Grid item xs={12}>
            <hr />
          </Grid>
          <Grid item xs={6}>
            <CommentsHistory commentsHistoryInfo={commentsHistoryInfo} />
          </Grid>
          <Grid item xs={6}>
            <InvoiceTotals orderTotalsInfo={orderTotalsInfo} />
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default Invoices;
