import { FC } from "react";
import { Typography } from "@mui/material";
import { IMAddress } from "../../../models/Order";

interface IShippingAddressInfoProps {
  shippingAddressInfo: IMAddress;
}


const ShippingAdress: FC<IShippingAddressInfoProps> = (shippingAddressInfo) => {
  return (
    <>
      <Typography variant="h6"  >
        Shipping Address
      </Typography>
      <address>
      {shippingAddressInfo.shippingAddressInfo.firstname + shippingAddressInfo.shippingAddressInfo.lastname} <br /> {shippingAddressInfo.shippingAddressInfo.street?.join(', ')} 
         <br />
        {shippingAddressInfo.shippingAddressInfo.city + ', ' + shippingAddressInfo.shippingAddressInfo.postcode}
        <br />
        T: {shippingAddressInfo.shippingAddressInfo.telephone}
      </address>
    </>
  );
};

export default ShippingAdress;
