import React, { FC, useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useInView } from "react-intersection-observer";
import { useInfiniteQuery, useQuery } from "react-query";

import {
  Avatar,
  Box,
  Button,
  Checkbox,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

import { IMSkuItem } from "../../models/Sku";

import { useSkus } from "../../hooks/useSkus";
import Loadings from "../../compoment/common/Loadings";
import {
  CURRENCY,
  BRANDS_SKU,
  IMAGE_FROM_WEBSITES,
  SKU_VISIBILITY,
  SKU_STATUS,
} from "../../utils/constant";
import { formatePrice } from "../../utils/common";
import dayjs, { Dayjs } from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import axios from "axios";
import { BRANDS, NEWBRANDS, NEWBRANDS1 } from "../../utils/constant";
import SearchIcon from "@mui/icons-material/Search";
import { writeFile } from "xlsx";
import * as XLSX from "xlsx";
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 50,
    },
  },
};

const StatisticsByHourChart: FC = () => {
  const [values, setValues] = useState<{
    specificDate: Dayjs;
    brands: string[];
  }>({
    specificDate: dayjs("01-06-2023", "DD-MM-YYYY"),
    brands: BRANDS,
  });

  const handleChange = (e: { target: { name: any; value: any } }) => {
    setValues({
      ...values,
      [e.target.name]: e.target.value,
    });
  };

  const { data, isLoading } = useQuery(
    ["revenueByHour", values.specificDate, values.brands],
    async () => {
      const response = await axios.post(`/api/v1/orders/getAllOrderList`, {
        revenueByHour: values.specificDate.format("YYYY-MM-DD"),
        brands: values.brands,
      });
      return response.data.data;
    }
  );

  // console.log(data, "data.total_grand");

  const currentDate = new Date();

  // Calculate the date of a week ago
  const lastWeekDate = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    currentDate.getDate() - 7
  );
  const yesterdayDate = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    currentDate.getDate() - 1
  );

  // Format the date as desired
  const formattedLastWeekDate = `${
    lastWeekDate.getMonth() + 1
  }/${lastWeekDate.getDate()}/${lastWeekDate.getFullYear()}`;
  const formattedYesterdayDate = `${
    yesterdayDate.getMonth() + 1
  }/${yesterdayDate.getDate()}/${yesterdayDate.getFullYear()}`;

  // Last 30 days
  const last30DaysStart = dayjs().subtract(30, "day").format("MM/DD/YYYY");
  const last30DaysEnd = dayjs().format("MM/DD/YYYY");
  const tableData = [
    {
      ByHour: `Specific Date ${values.specificDate.format("MM-DD-YYYY")}`,
      "0~1": data?.revenueBySpecificDate["0"] ?? "0",
      "1~2": data?.revenueBySpecificDate["1"] ?? "0",
      "2~3": data?.revenueBySpecificDate["2"] ?? "0",
      "3~4": data?.revenueBySpecificDate["3"] ?? "0",
      "4~5": data?.revenueBySpecificDate["4"] ?? "0",
      "5~6": data?.revenueBySpecificDate["5"] ?? "0",
      "6~7": data?.revenueBySpecificDate["6"] ?? "0",
      "7~8": data?.revenueBySpecificDate["7"] ?? "0",
      "8~9": data?.revenueBySpecificDate["8"] ?? "0",
      "9~10": data?.revenueBySpecificDate["9"] ?? "0",
      "10~11": data?.revenueBySpecificDate["10"] ?? "0",
      "11~12": data?.revenueBySpecificDate["11"] ?? "0",
      "12~13": data?.revenueBySpecificDate["12"] ?? "0",
      "13~14": data?.revenueBySpecificDate["13"] ?? "0",
      "14~15": data?.revenueBySpecificDate["14"] ?? "0",
      "15~16": data?.revenueBySpecificDate["15"] ?? "0",
      "16~17": data?.revenueBySpecificDate["16"] ?? "0",
      "17~18": data?.revenueBySpecificDate["17"] ?? "0",
      "18~19": data?.revenueBySpecificDate["18"] ?? "0",
      "19~20": data?.revenueBySpecificDate["19"] ?? "0",
      "20~21": data?.revenueBySpecificDate["20"] ?? "0",
      "21~22": data?.revenueBySpecificDate["21"] ?? "0",
      "22~23": data?.revenueBySpecificDate["22"] ?? "0",
      "23~24": data?.revenueBySpecificDate["23"] ?? "0",
    },
    {
      ByHour: `${formattedLastWeekDate} ~ ${formattedYesterdayDate} (Total)`,
      "0~1": data?.revenueByHourWeekly["0"] ?? "0",
      "1~2": data?.revenueByHourWeekly["1"] ?? "0",
      "2~3": data?.revenueByHourWeekly["2"] ?? "0",
      "3~4": data?.revenueByHourWeekly["3"] ?? "0",
      "4~5": data?.revenueByHourWeekly["4"] ?? "0",
      "5~6": data?.revenueByHourWeekly["5"] ?? "0",
      "6~7": data?.revenueByHourWeekly["6"] ?? "0",
      "7~8": data?.revenueByHourWeekly["7"] ?? "0",
      "8~9": data?.revenueByHourWeekly["8"] ?? "0",
      "9~10": data?.revenueByHourWeekly["9"] ?? "0",
      "10~11": data?.revenueByHourWeekly["10"] ?? "0",
      "11~12": data?.revenueByHourWeekly["11"] ?? "0",
      "12~13": data?.revenueByHourWeekly["12"] ?? "0",
      "13~14": data?.revenueByHourWeekly["13"] ?? "0",
      "14~15": data?.revenueByHourWeekly["14"] ?? "0",
      "15~16": data?.revenueByHourWeekly["15"] ?? "0",
      "16~17": data?.revenueByHourWeekly["16"] ?? "0",
      "17~18": data?.revenueByHourWeekly["17"] ?? "0",
      "18~19": data?.revenueByHourWeekly["18"] ?? "0",
      "19~20": data?.revenueByHourWeekly["19"] ?? "0",
      "20~21": data?.revenueByHourWeekly["20"] ?? "0",
      "21~22": data?.revenueByHourWeekly["21"] ?? "0",
      "22~23": data?.revenueByHourWeekly["22"] ?? "0",
      "23~24": data?.revenueByHourWeekly["23"] ?? "0",
    },
    {
      ByHour: `${last30DaysStart} ~ ${last30DaysEnd} (Average)`,
      "0~1": formatePrice(data?.revenueByHourLast30days["0"] / 30) ?? "0",
      "1~2": formatePrice(data?.revenueByHourLast30days["1"] / 30) ?? "0",
      "2~3": formatePrice(data?.revenueByHourLast30days["2"] / 30) ?? "0",
      "3~4": formatePrice(data?.revenueByHourLast30days["3"] / 30) ?? "0",
      "4~5": formatePrice(data?.revenueByHourLast30days["4"] / 30) ?? "0",
      "5~6": formatePrice(data?.revenueByHourLast30days["5"] / 30) ?? "0",
      "6~7": formatePrice(data?.revenueByHourLast30days["6"] / 30) ?? "0",
      "7~8": formatePrice(data?.revenueByHourLast30days["7"] / 30) ?? "0",
      "8~9": formatePrice(data?.revenueByHourLast30days["8"] / 30) ?? "0",
      "9~10": formatePrice(data?.revenueByHourLast30days["9"] / 30) ?? "0",
      "10~11": formatePrice(data?.revenueByHourLast30days["10"] / 30) ?? "0",
      "11~12": formatePrice(data?.revenueByHourLast30days["11"] / 30) ?? "0",
      "12~13": formatePrice(data?.revenueByHourLast30days["12"] / 30) ?? "0",
      "13~14": formatePrice(data?.revenueByHourLast30days["13"] / 30) ?? "0",
      "14~15": formatePrice(data?.revenueByHourLast30days["14"] / 30) ?? "0",
      "15~16": formatePrice(data?.revenueByHourLast30days["15"] / 30) ?? "0",
      "16~17": formatePrice(data?.revenueByHourLast30days["16"] / 30) ?? "0",
      "17~18": formatePrice(data?.revenueByHourLast30days["17"] / 30) ?? "0",
      "18~19": formatePrice(data?.revenueByHourLast30days["18"] / 30) ?? "0",
      "19~20": formatePrice(data?.revenueByHourLast30days["19"] / 30) ?? "0",
      "20~21": formatePrice(data?.revenueByHourLast30days["20"] / 30) ?? "0",
      "21~22": formatePrice(data?.revenueByHourLast30days["21"] / 30) ?? "0",
      "22~23": formatePrice(data?.revenueByHourLast30days["22"] / 30) ?? "0",
      "23~24": formatePrice(data?.revenueByHourLast30days["23"] / 30) ?? "0",
    },
    {
      ByHour: `${formattedLastWeekDate} ~ ${formattedYesterdayDate} (Average)`,
      "0~1": formatePrice(data?.revenueByHourWeekly["0"] / 30) ?? "0",
      "1~2": formatePrice(data?.revenueByHourWeekly["1"] / 30) ?? "0",
      "2~3": formatePrice(data?.revenueByHourWeekly["2"] / 30) ?? "0",
      "3~4": formatePrice(data?.revenueByHourWeekly["3"] / 30) ?? "0",
      "4~5": formatePrice(data?.revenueByHourWeekly["4"] / 30) ?? "0",
      "5~6": formatePrice(data?.revenueByHourWeekly["5"] / 30) ?? "0",
      "6~7": formatePrice(data?.revenueByHourWeekly["6"] / 30) ?? "0",
      "7~8": formatePrice(data?.revenueByHourWeekly["7"] / 30) ?? "0",
      "8~9": formatePrice(data?.revenueByHourWeekly["8"] / 30) ?? "0",
      "9~10": formatePrice(data?.revenueByHourWeekly["9"] / 30) ?? "0",
      "10~11": formatePrice(data?.revenueByHourWeekly["10"] / 30) ?? "0",
      "11~12": formatePrice(data?.revenueByHourWeekly["11"] / 30) ?? "0",
      "12~13": formatePrice(data?.revenueByHourWeekly["12"] / 30) ?? "0",
      "13~14": formatePrice(data?.revenueByHourWeekly["13"] / 30) ?? "0",
      "14~15": formatePrice(data?.revenueByHourWeekly["14"] / 30) ?? "0",
      "15~16": formatePrice(data?.revenueByHourWeekly["15"] / 30) ?? "0",
      "16~17": formatePrice(data?.revenueByHourWeekly["16"] / 30) ?? "0",
      "17~18": formatePrice(data?.revenueByHourWeekly["17"] / 30) ?? "0",
      "18~19": formatePrice(data?.revenueByHourWeekly["18"] / 30) ?? "0",
      "19~20": formatePrice(data?.revenueByHourWeekly["19"] / 30) ?? "0",
      "20~21": formatePrice(data?.revenueByHourWeekly["20"] / 30) ?? "0",
      "21~22": formatePrice(data?.revenueByHourWeekly["21"] / 30) ?? "0",
      "22~23": formatePrice(data?.revenueByHourWeekly["22"] / 30) ?? "0",
      "23~24": formatePrice(data?.revenueByHourWeekly["23"] / 30) ?? "0",
    },
    {
      ByHour: "Lastweek vs Last 30days(average)",
      "0~1":
        data?.revenueByHourWeekly["0"] === data?.revenueByHourLast30days["0"]
          ? "0%"
          : data?.revenueByHourLast30days["0"] === 0
          ? "0%"
          : data?.revenueByHourWeekly["0"] / 7 >=
            data?.revenueByHourLast30days["0"] / 30
          ? "+" +
            formatePrice(
              (data?.revenueByHourWeekly["0"] /
                7 /
                (data?.revenueByHourLast30days["0"] / 30)) *
                100 -
                100
            ) +
            "%"
          : formatePrice(
              (data?.revenueByHourWeekly["0"] /
                7 /
                (data?.revenueByHourLast30days["0"] / 30)) *
                100 -
                100
            ) + "%",
      "1~2":
        data?.revenueByHourWeekly["1"] === data?.revenueByHourLast30days["1"]
          ? "0%"
          : data?.revenueByHourLast30days["1"] === 0
          ? "0%"
          : data?.revenueByHourWeekly["1"] / 7 >=
            data?.revenueByHourLast30days["1"] / 30
          ? "+" +
            formatePrice(
              (data?.revenueByHourWeekly["1"] /
                7 /
                (data?.revenueByHourLast30days["1"] / 30)) *
                100 -
                100
            ) +
            "%"
          : formatePrice(
              (data?.revenueByHourWeekly["1"] /
                7 /
                (data?.revenueByHourLast30days["1"] / 30)) *
                100 -
                100
            ) + "%",
      "2~3": data?.revenueByHourWeekly["2"] === data?.revenueByHourLast30days["2"]
      ? "0%"
      : data?.revenueByHourLast30days["2"] === 0
      ? "0%"
      : data?.revenueByHourWeekly["2"] / 7 >=
        data?.revenueByHourLast30days["2"] / 30
      ? "+" +
        formatePrice(
          (data?.revenueByHourWeekly["2"] /
            7 /
            (data?.revenueByHourLast30days["2"] / 30)) *
            100 -
            100
        ) +
        "%"
      : formatePrice(
          (data?.revenueByHourWeekly["2"] /
            7 /
            (data?.revenueByHourLast30days["2"] / 30)) *
            100 -
            100
        ) + "%",
      "3~4": data?.revenueByHourWeekly["3"] === data?.revenueByHourLast30days["3"]
      ? "0%"
      : data?.revenueByHourLast30days["3"] === 0
      ? "0%"
      : data?.revenueByHourWeekly["3"] / 7 >=
        data?.revenueByHourLast30days["3"] / 30
      ? "+" +
        formatePrice(
          (data?.revenueByHourWeekly["3"] /
            7 /
            (data?.revenueByHourLast30days["3"] / 30)) *
            100 -
            100
        ) +
        "%"
      : formatePrice(
          (data?.revenueByHourWeekly["3"] /
            7 /
            (data?.revenueByHourLast30days["3"] / 30)) *
            100 -
            100
        ) + "%",
      "4~5": data?.revenueByHourWeekly["4"] === data?.revenueByHourLast30days["4"]
      ? "0%"
      : data?.revenueByHourLast30days["4"] === 0
      ? "0%"
      : data?.revenueByHourWeekly["4"] / 7 >=
        data?.revenueByHourLast30days["4"] / 30
      ? "+" +
        formatePrice(
          (data?.revenueByHourWeekly["4"] /
            7 /
            (data?.revenueByHourLast30days["4"] / 30)) *
            100 -
            100
        ) +
        "%"
      : formatePrice(
          (data?.revenueByHourWeekly["4"] /
            7 /
            (data?.revenueByHourLast30days["4"] / 30)) *
            100 -
            100
        ) + "%",
      "5~6": data?.revenueByHourWeekly["5"] === data?.revenueByHourLast30days["5"]
      ? "0%"
      : data?.revenueByHourLast30days["5"] === 0
      ? "0%"
      : data?.revenueByHourWeekly["5"] / 7 >=
        data?.revenueByHourLast30days["5"] / 30
      ? "+" +
        formatePrice(
          (data?.revenueByHourWeekly["5"] /
            7 /
            (data?.revenueByHourLast30days["5"] / 30)) *
            100 -
            100
        ) +
        "%"
      : formatePrice(
          (data?.revenueByHourWeekly["5"] /
            7 /
            (data?.revenueByHourLast30days["5"] / 30)) *
            100 -
            100
        ) + "%",
      "6~7": data?.revenueByHourWeekly["6"] === data?.revenueByHourLast30days["6"]
      ? "0%"
      : data?.revenueByHourLast30days["6"] === 0
      ? "0%"
      : data?.revenueByHourWeekly["6"] / 7 >=
        data?.revenueByHourLast30days["6"] / 30
      ? "+" +
        formatePrice(
          (data?.revenueByHourWeekly["6"] /
            7 /
            (data?.revenueByHourLast30days["6"] / 30)) *
            100 -
            100
        ) +
        "%"
      : formatePrice(
          (data?.revenueByHourWeekly["6"] /
            7 /
            (data?.revenueByHourLast30days["6"] / 30)) *
            100 -
            100
        ) + "%",
      "7~8": data?.revenueByHourWeekly["7"] === data?.revenueByHourLast30days["7"]
      ? "0%"
      : data?.revenueByHourLast30days["7"] === 0
      ? "0%"
      : data?.revenueByHourWeekly["7"] / 7 >=
        data?.revenueByHourLast30days["7"] / 30
      ? "+" +
        formatePrice(
          (data?.revenueByHourWeekly["7"] /
            7 /
            (data?.revenueByHourLast30days["7"] / 30)) *
            100 -
            100
        ) +
        "%"
      : formatePrice(
          (data?.revenueByHourWeekly["7"] /
            7 /
            (data?.revenueByHourLast30days["7"] / 30)) *
            100 -
            100
        ) + "%",
      "8~9": data?.revenueByHourWeekly["8"] === data?.revenueByHourLast30days["8"]
      ? "0%"
      : data?.revenueByHourLast30days["8"] === 0
      ? "0%"
      : data?.revenueByHourWeekly["8"] / 7 >=
        data?.revenueByHourLast30days["8"] / 30
      ? "+" +
        formatePrice(
          (data?.revenueByHourWeekly["8"] /
            7 /
            (data?.revenueByHourLast30days["8"] / 30)) *
            100 -
            100
        ) +
        "%"
      : formatePrice(
          (data?.revenueByHourWeekly["8"] /
            7 /
            (data?.revenueByHourLast30days["8"] / 30)) *
            100 -
            100
        ) + "%",
      "9~10": data?.revenueByHourWeekly["9"] === data?.revenueByHourLast30days["9"]
      ? "0%"
      : data?.revenueByHourLast30days["9"] === 0
      ? "0%"
      : data?.revenueByHourWeekly["9"] / 7 >=
        data?.revenueByHourLast30days["9"] / 30
      ? "+" +
        formatePrice(
          (data?.revenueByHourWeekly["9"] /
            7 /
            (data?.revenueByHourLast30days["9"] / 30)) *
            100 -
            100
        ) +
        "%"
      : formatePrice(
          (data?.revenueByHourWeekly["9"] /
            7 /
            (data?.revenueByHourLast30days["9"] / 30)) *
            100 -
            100
        ) + "%",
      "10~11": data?.revenueByHourWeekly["10"] === data?.revenueByHourLast30days["10"]
      ? "0%"
      : data?.revenueByHourLast30days["10"] === 0
      ? "0%"
      : data?.revenueByHourWeekly["10"] / 7 >=
        data?.revenueByHourLast30days["10"] / 30
      ? "+" +
        formatePrice(
          (data?.revenueByHourWeekly["10"] /
            7 /
            (data?.revenueByHourLast30days["10"] / 30)) *
            100 -
            100
        ) +
        "%"
      : formatePrice(
          (data?.revenueByHourWeekly["10"] /
            7 /
            (data?.revenueByHourLast30days["10"] / 30)) *
            100 -
            100
        ) + "%",
      "11~12": data?.revenueByHourWeekly["11"] === data?.revenueByHourLast30days["11"]
      ? "0%"
      : data?.revenueByHourLast30days["11"] === 0
      ? "0%"
      : data?.revenueByHourWeekly["11"] / 7 >=
        data?.revenueByHourLast30days["11"] / 30
      ? "+" +
        formatePrice(
          (data?.revenueByHourWeekly["11"] /
            7 /
            (data?.revenueByHourLast30days["11"] / 30)) *
            100 -
            100
        ) +
        "%"
      : formatePrice(
          (data?.revenueByHourWeekly["11"] /
            7 /
            (data?.revenueByHourLast30days["11"] / 30)) *
            100 -
            100
        ) + "%",
      "12~13": data?.revenueByHourWeekly["12"] === data?.revenueByHourLast30days["12"]
      ? "0%"
      : data?.revenueByHourLast30days["12"] === 0
      ? "0%"
      : data?.revenueByHourWeekly["12"] / 7 >=
        data?.revenueByHourLast30days["12"] / 30
      ? "+" +
        formatePrice(
          (data?.revenueByHourWeekly["12"] /
            7 /
            (data?.revenueByHourLast30days["12"] / 30)) *
            100 -
            100
        ) +
        "%"
      : formatePrice(
          (data?.revenueByHourWeekly["12"] /
            7 /
            (data?.revenueByHourLast30days["12"] / 30)) *
            100 -
            100
        ) + "%",
      "13~14": data?.revenueByHourWeekly["13"] === data?.revenueByHourLast30days["13"]
      ? "0%"
      : data?.revenueByHourLast30days["13"] === 0
      ? "0%"
      : data?.revenueByHourWeekly["13"] / 7 >=
        data?.revenueByHourLast30days["13"] / 30
      ? "+" +
        formatePrice(
          (data?.revenueByHourWeekly["13"] /
            7 /
            (data?.revenueByHourLast30days["13"] / 30)) *
            100 -
            100
        ) +
        "%"
      : formatePrice(
          (data?.revenueByHourWeekly["13"] /
            7 /
            (data?.revenueByHourLast30days["13"] / 30)) *
            100 -
            100
        ) + "%",
      "14~15": data?.revenueByHourWeekly["14"] === data?.revenueByHourLast30days["14"]
      ? "0%"
      : data?.revenueByHourLast30days["14"] === 0
      ? "0%"
      : data?.revenueByHourWeekly["14"] / 7 >=
        data?.revenueByHourLast30days["14"] / 30
      ? "+" +
        formatePrice(
          (data?.revenueByHourWeekly["14"] /
            7 /
            (data?.revenueByHourLast30days["14"] / 30)) *
            100 -
            100
        ) +
        "%"
      : formatePrice(
          (data?.revenueByHourWeekly["14"] /
            7 /
            (data?.revenueByHourLast30days["14"] / 30)) *
            100 -
            100
        ) + "%",
      "15~16": data?.revenueByHourWeekly["15"] === data?.revenueByHourLast30days["15"]
      ? "0%"
      : data?.revenueByHourLast30days["15"] === 0
      ? "0%"
      : data?.revenueByHourWeekly["15"] / 7 >=
        data?.revenueByHourLast30days["15"] / 30
      ? "+" +
        formatePrice(
          (data?.revenueByHourWeekly["15"] /
            7 /
            (data?.revenueByHourLast30days["15"] / 30)) *
            100 -
            100
        ) +
        "%"
      : formatePrice(
          (data?.revenueByHourWeekly["15"] /
            7 /
            (data?.revenueByHourLast30days["15"] / 30)) *
            100 -
            100
        ) + "%",
      "16~17": data?.revenueByHourWeekly["16"] === data?.revenueByHourLast30days["16"]
      ? "0%"
      : data?.revenueByHourLast30days["16"] === 0
      ? "0%"
      : data?.revenueByHourWeekly["16"] / 7 >=
        data?.revenueByHourLast30days["16"] / 30
      ? "+" +
        formatePrice(
          (data?.revenueByHourWeekly["16"] /
            7 /
            (data?.revenueByHourLast30days["16"] / 30)) *
            100 -
            100
        ) +
        "%"
      : formatePrice(
          (data?.revenueByHourWeekly["16"] /
            7 /
            (data?.revenueByHourLast30days["16"] / 30)) *
            100 -
            100
        ) + "%",
      "17~18": data?.revenueByHourWeekly["17"] === data?.revenueByHourLast30days["17"]
      ? "0%"
      : data?.revenueByHourLast30days["17"] === 0
      ? "0%"
      : data?.revenueByHourWeekly["17"] / 7 >=
        data?.revenueByHourLast30days["17"] / 30
      ? "+" +
        formatePrice(
          (data?.revenueByHourWeekly["17"] /
            7 /
            (data?.revenueByHourLast30days["17"] / 30)) *
            100 -
            100
        ) +
        "%"
      : formatePrice(
          (data?.revenueByHourWeekly["17"] /
            7 /
            (data?.revenueByHourLast30days["17"] / 30)) *
            100 -
            100
        ) + "%",
      "18~19": data?.revenueByHourWeekly["18"] === data?.revenueByHourLast30days["18"]
      ? "0%"
      : data?.revenueByHourLast30days["18"] === 0
      ? "0%"
      : data?.revenueByHourWeekly["18"] / 7 >=
        data?.revenueByHourLast30days["18"] / 30
      ? "+" +
        formatePrice(
          (data?.revenueByHourWeekly["18"] /
            7 /
            (data?.revenueByHourLast30days["18"] / 30)) *
            100 -
            100
        ) +
        "%"
      : formatePrice(
          (data?.revenueByHourWeekly["18"] /
            7 /
            (data?.revenueByHourLast30days["18"] / 30)) *
            100 -
            100
        ) + "%",
      "19~20": data?.revenueByHourWeekly["19"] === data?.revenueByHourLast30days["19"]
      ? "0%"
      : data?.revenueByHourLast30days["19"] === 0
      ? "0%"
      : data?.revenueByHourWeekly["19"] / 7 >=
        data?.revenueByHourLast30days["19"] / 30
      ? "+" +
        formatePrice(
          (data?.revenueByHourWeekly["19"] /
            7 /
            (data?.revenueByHourLast30days["19"] / 30)) *
            100 -
            100
        ) +
        "%"
      : formatePrice(
          (data?.revenueByHourWeekly["19"] /
            7 /
            (data?.revenueByHourLast30days["19"] / 30)) *
            100 -
            100
        ) + "%",
      "20~21": data?.revenueByHourWeekly["20"] === data?.revenueByHourLast30days["20"]
      ? "0%"
      : data?.revenueByHourLast30days["20"] === 0
      ? "0%"
      : data?.revenueByHourWeekly["20"] / 7 >=
        data?.revenueByHourLast30days["20"] / 30
      ? "+" +
        formatePrice(
          (data?.revenueByHourWeekly["20"] /
            7 /
            (data?.revenueByHourLast30days["20"] / 30)) *
            100 -
            100
        ) +
        "%"
      : formatePrice(
          (data?.revenueByHourWeekly["20"] /
            7 /
            (data?.revenueByHourLast30days["20"] / 30)) *
            100 -
            100
        ) + "%",
      "21~22": data?.revenueByHourWeekly["21"] === data?.revenueByHourLast30days["21"]
      ? "0%"
      : data?.revenueByHourLast30days["21"] === 0
      ? "0%"
      : data?.revenueByHourWeekly["21"] / 7 >=
        data?.revenueByHourLast30days["21"] / 30
      ? "+" +
        formatePrice(
          (data?.revenueByHourWeekly["21"] /
            7 /
            (data?.revenueByHourLast30days["21"] / 30)) *
            100 -
            100
        ) +
        "%"
      : formatePrice(
          (data?.revenueByHourWeekly["21"] /
            7 /
            (data?.revenueByHourLast30days["21"] / 30)) *
            100 -
            100
        ) + "%",
      "22~23": data?.revenueByHourWeekly["22"] === data?.revenueByHourLast30days["22"]
      ? "0%"
      : data?.revenueByHourLast30days["22"] === 0
      ? "0%"
      : data?.revenueByHourWeekly["22"] / 7 >=
        data?.revenueByHourLast30days["22"] / 30
      ? "+" +
        formatePrice(
          (data?.revenueByHourWeekly["22"] /
            7 /
            (data?.revenueByHourLast30days["22"] / 30)) *
            100 -
            100
        ) +
        "%"
      : formatePrice(
          (data?.revenueByHourWeekly["22"] /
            7 /
            (data?.revenueByHourLast30days["22"] / 30)) *
            100 -
            100
        ) + "%",
      "23~24": data?.revenueByHourWeekly["23"] === data?.revenueByHourLast30days["23"]
      ? "0%"
      : data?.revenueByHourLast30days["23"] === 0
      ? "0%"
      : data?.revenueByHourWeekly["23"] / 7 >=
        data?.revenueByHourLast30days["23"] / 30
      ? "+" +
        formatePrice(
          (data?.revenueByHourWeekly["23"] /
            7 /
            (data?.revenueByHourLast30days["23"] / 30)) *
            100 -
            100
        ) +
        "%"
      : formatePrice(
          (data?.revenueByHourWeekly["23"] /
            7 /
            (data?.revenueByHourLast30days["23"] / 30)) *
            100 -
            100
        ) + "%",
    },

    // Add more rows as needed
  ];
  // export to excel
  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(tableData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const data = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const url = URL.createObjectURL(data);
    const link = document.createElement("a");
    link.href = url;
    link.download = `Revenue by Hour filtered by ${values.brands.map(index => {
      return NEWBRANDS1[index] + ",";
    }).join("")}.xlsx`;
    link.click();
  };
  return (
    <Box sx={{ margin: " 10px 10px 10px 10px", textAlign: "center" }}>
      {isLoading ? (
        <Box
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          height={"calc( 100vh - 160px)"}
        >
          <Loadings />
        </Box>
      ) : (
        <>
          <Grid container alignItems={"center"} spacing={"8px"}>
            <Grid item md={6} sm={9} xs={12}>
              <Typography variant="h6">Revenue By Hour</Typography>
            </Grid>
            <Grid item md={3} sm={3} xs={12}>
              <FormControl sx={{ width: "100%" }}>
                <InputLabel id="brands-label">Brand</InputLabel>
                <Select
                  labelId="brands-label"
                  multiple
                  name={"brands"}
                  value={values.brands}
                  onChange={handleChange}
                  input={<OutlinedInput label="Brand" />}
                  type="search"
                  renderValue={(selected: any) =>
                    selected.map((item: any) => NEWBRANDS1[item]).join(", ")
                  }
                  MenuProps={MenuProps}
                >
                  {NEWBRANDS.map((brand) => (
                    <MenuItem key={brand.value} value={brand.value}>
                      <Checkbox checked={values.brands.includes(brand.value)} />
                      <ListItemText primary={brand.label} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={3} sm={3} xs={12}><Button variant="outlined" onClick={exportToExcel}>Export to Excel</Button></Grid>
          </Grid>

          <TableContainer sx={{ maxHeight: "calc( 100vh - 160px)" }}>
            <Table id="table" stickyHeader aria-label="simple table">
              <TableHead>
                <TableRow
                  sx={{
                    "& > th ": {
                      fontWeight: 700,
                      backgroundColor: "#514943",
                      color: "white",
                      borderRight: "1px solid #8A837F",
                    },
                  }}
                >
                  <TableCell sx={{ minWidth: "180px" }}>By Hour</TableCell>
                  <TableCell>0~1</TableCell>
                  <TableCell>1~2</TableCell>
                  <TableCell>2~3</TableCell>
                  <TableCell>3~4</TableCell>
                  <TableCell>4~5</TableCell>
                  <TableCell>5~6</TableCell>
                  <TableCell>6~7</TableCell>
                  <TableCell>7~8</TableCell>
                  <TableCell>8~9</TableCell>
                  <TableCell>9~10</TableCell>
                  <TableCell>10~11</TableCell>
                  <TableCell>11~12</TableCell>
                  <TableCell>12~13</TableCell>
                  <TableCell>13~14</TableCell>
                  <TableCell>14~15</TableCell>
                  <TableCell>15~16</TableCell>
                  <TableCell>16~17</TableCell>
                  <TableCell>17~18</TableCell>
                  <TableCell>18~19</TableCell>
                  <TableCell>19~20</TableCell>
                  <TableCell>20~21</TableCell>
                  <TableCell>21~22</TableCell>
                  <TableCell>22~23</TableCell>
                  <TableCell>23~24</TableCell>
                </TableRow>
              </TableHead>
              <TableBody
                sx={{
                  "& > tr": {
                    border: 0,
                  },
                  "& > tr:nth-of-type(even)": {
                    backgroundColor: "#F5F5F5",
                  },
                }}
              >
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <TableRow
                    sx={{ "& > td ": { borderRight: "1px dashed #d6d6d6" } }}
                  >
                    <TableCell>
                      <DatePicker
                        label="Specific Date"
                        // defaultValue={dayjs(new Date())}
                        value={values.specificDate}
                        onChange={(newValue) =>
                          handleChange({
                            target: { name: "specificDate", value: newValue },
                          })
                        }
                      />
                    </TableCell>
                    <TableCell>
                      {data?.revenueBySpecificDate["0"] ?? "0"}
                    </TableCell>
                    <TableCell>
                      {data?.revenueBySpecificDate["1"] ?? "0"}
                    </TableCell>
                    <TableCell>
                      {data?.revenueBySpecificDate["2"] ?? "0"}
                    </TableCell>
                    <TableCell>
                      {data?.revenueBySpecificDate["3"] ?? "0"}
                    </TableCell>
                    <TableCell>
                      {data?.revenueBySpecificDate["4"] ?? "0"}
                    </TableCell>
                    <TableCell>
                      {data?.revenueBySpecificDate["5"] ?? "0"}
                    </TableCell>
                    <TableCell>
                      {data?.revenueBySpecificDate["6"] ?? "0"}
                    </TableCell>
                    <TableCell>
                      {data?.revenueBySpecificDate["7"] ?? "0"}
                    </TableCell>
                    <TableCell>
                      {data?.revenueBySpecificDate["8"] ?? "0"}
                    </TableCell>
                    <TableCell>
                      {data?.revenueBySpecificDate["9"] ?? "0"}
                    </TableCell>
                    <TableCell>
                      {data?.revenueBySpecificDate["10"] ?? "0"}
                    </TableCell>
                    <TableCell>
                      {data?.revenueBySpecificDate["11"] ?? "0"}
                    </TableCell>
                    <TableCell>
                      {data?.revenueBySpecificDate["12"] ?? "0"}
                    </TableCell>
                    <TableCell>
                      {data?.revenueBySpecificDate["13"] ?? "0"}
                    </TableCell>
                    <TableCell>
                      {data?.revenueBySpecificDate["14"] ?? "0"}
                    </TableCell>
                    <TableCell>
                      {data?.revenueBySpecificDate["15"] ?? "0"}
                    </TableCell>
                    <TableCell>
                      {data?.revenueBySpecificDate["16"] ?? "0"}
                    </TableCell>
                    <TableCell>
                      {data?.revenueBySpecificDate["17"] ?? "0"}
                    </TableCell>
                    <TableCell>
                      {data?.revenueBySpecificDate["18"] ?? "0"}
                    </TableCell>
                    <TableCell>
                      {data?.revenueBySpecificDate["19"] ?? "0"}
                    </TableCell>
                    <TableCell>
                      {data?.revenueBySpecificDate["20"] ?? "0"}
                    </TableCell>
                    <TableCell>
                      {data?.revenueBySpecificDate["21"] ?? "0"}
                    </TableCell>
                    <TableCell>
                      {data?.revenueBySpecificDate["22"] ?? "0"}
                    </TableCell>
                    <TableCell>
                      {data?.revenueBySpecificDate["23"] ?? "0"}
                    </TableCell>
                  </TableRow>
                </LocalizationProvider>
                <TableRow
                  sx={{ "& > td ": { borderRight: "1px dashed #d6d6d6" } }}
                >
                  <TableCell>
                    {formattedLastWeekDate} ~ {formattedYesterdayDate} (Total)
                  </TableCell>
                  <TableCell>{data?.revenueByHourWeekly["0"] ?? "0"}</TableCell>
                  <TableCell>{data?.revenueByHourWeekly["1"] ?? "0"}</TableCell>
                  <TableCell>{data?.revenueByHourWeekly["2"] ?? "0"}</TableCell>
                  <TableCell>{data?.revenueByHourWeekly["3"] ?? "0"}</TableCell>
                  <TableCell>{data?.revenueByHourWeekly["4"] ?? "0"}</TableCell>
                  <TableCell>{data?.revenueByHourWeekly["5"] ?? "0"}</TableCell>
                  <TableCell>{data?.revenueByHourWeekly["6"] ?? "0"}</TableCell>
                  <TableCell>{data?.revenueByHourWeekly["7"] ?? "0"}</TableCell>
                  <TableCell>{data?.revenueByHourWeekly["8"] ?? "0"}</TableCell>
                  <TableCell>{data?.revenueByHourWeekly["9"] ?? "0"}</TableCell>
                  <TableCell>
                    {data?.revenueByHourWeekly["10"] ?? "0"}
                  </TableCell>
                  <TableCell>
                    {data?.revenueByHourWeekly["11"] ?? "0"}
                  </TableCell>
                  <TableCell>
                    {data?.revenueByHourWeekly["12"] ?? "0"}
                  </TableCell>
                  <TableCell>
                    {data?.revenueByHourWeekly["13"] ?? "0"}
                  </TableCell>
                  <TableCell>
                    {data?.revenueByHourWeekly["14"] ?? "0"}
                  </TableCell>
                  <TableCell>
                    {data?.revenueByHourWeekly["15"] ?? "0"}
                  </TableCell>
                  <TableCell>
                    {data?.revenueByHourWeekly["16"] ?? "0"}
                  </TableCell>
                  <TableCell>
                    {data?.revenueByHourWeekly["17"] ?? "0"}
                  </TableCell>
                  <TableCell>
                    {data?.revenueByHourWeekly["18"] ?? "0"}
                  </TableCell>
                  <TableCell>
                    {data?.revenueByHourWeekly["19"] ?? "0"}
                  </TableCell>
                  <TableCell>
                    {data?.revenueByHourWeekly["20"] ?? "0"}
                  </TableCell>
                  <TableCell>
                    {data?.revenueByHourWeekly["21"] ?? "0"}
                  </TableCell>
                  <TableCell>
                    {data?.revenueByHourWeekly["22"] ?? "0"}
                  </TableCell>
                  <TableCell>
                    {data?.revenueByHourWeekly["23"] ?? "0"}
                  </TableCell>
                </TableRow>
                <TableRow
                  sx={{ "& > td ": { borderRight: "1px dashed #d6d6d6" } }}
                >
                  <TableCell>
                    {last30DaysStart} ~ {last30DaysEnd} (Average)
                  </TableCell>
                  <TableCell>
                    {formatePrice(data?.revenueByHourLast30days["0"] / 30) ??
                      "0"}
                  </TableCell>
                  <TableCell>
                    {formatePrice(data?.revenueByHourLast30days["1"] / 30) ??
                      "0"}
                  </TableCell>
                  <TableCell>
                    {formatePrice(data?.revenueByHourLast30days["2"] / 30) ??
                      "0"}
                  </TableCell>
                  <TableCell>
                    {formatePrice(data?.revenueByHourLast30days["3"] / 30) ??
                      "0"}
                  </TableCell>
                  <TableCell>
                    {formatePrice(data?.revenueByHourLast30days["4"] / 30) ??
                      "0"}
                  </TableCell>
                  <TableCell>
                    {formatePrice(data?.revenueByHourLast30days["5"] / 30) ??
                      "0"}
                  </TableCell>
                  <TableCell>
                    {formatePrice(data?.revenueByHourLast30days["6"] / 30) ??
                      "0"}
                  </TableCell>
                  <TableCell>
                    {formatePrice(data?.revenueByHourLast30days["7"] / 30) ??
                      "0"}
                  </TableCell>
                  <TableCell>
                    {formatePrice(data?.revenueByHourLast30days["8"] / 30) ??
                      "0"}
                  </TableCell>
                  <TableCell>
                    {formatePrice(data?.revenueByHourLast30days["9"] / 30) ??
                      "0"}
                  </TableCell>
                  <TableCell>
                    {formatePrice(data?.revenueByHourLast30days["10"] / 30) ??
                      "0"}
                  </TableCell>
                  <TableCell>
                    {formatePrice(data?.revenueByHourLast30days["11"] / 30) ??
                      "0"}
                  </TableCell>
                  <TableCell>
                    {formatePrice(data?.revenueByHourLast30days["12"] / 30) ??
                      "0"}
                  </TableCell>
                  <TableCell>
                    {formatePrice(data?.revenueByHourLast30days["13"] / 30) ??
                      "0"}
                  </TableCell>
                  <TableCell>
                    {formatePrice(data?.revenueByHourLast30days["14"] / 30) ??
                      "0"}
                  </TableCell>
                  <TableCell>
                    {formatePrice(data?.revenueByHourLast30days["15"] / 30) ??
                      "0"}
                  </TableCell>
                  <TableCell>
                    {formatePrice(data?.revenueByHourLast30days["16"] / 30) ??
                      "0"}
                  </TableCell>
                  <TableCell>
                    {formatePrice(data?.revenueByHourLast30days["17"] / 30) ??
                      "0"}
                  </TableCell>
                  <TableCell>
                    {formatePrice(data?.revenueByHourLast30days["18"] / 30) ??
                      "0"}
                  </TableCell>
                  <TableCell>
                    {formatePrice(data?.revenueByHourLast30days["19"] / 30) ??
                      "0"}
                  </TableCell>
                  <TableCell>
                    {formatePrice(data?.revenueByHourLast30days["20"] / 30) ??
                      "0"}
                  </TableCell>
                  <TableCell>
                    {formatePrice(data?.revenueByHourLast30days["21"] / 30) ??
                      "0"}
                  </TableCell>
                  <TableCell>
                    {formatePrice(data?.revenueByHourLast30days["22"] / 30) ??
                      "0"}
                  </TableCell>
                  <TableCell>
                    {formatePrice(data?.revenueByHourLast30days["23"] / 30) ??
                      "0"}
                  </TableCell>
                </TableRow>
                <TableRow
                  sx={{ "& > td ": { borderRight: "1px dashed #d6d6d6" } }}
                >
                  <TableCell>
                    {formattedLastWeekDate} ~ {formattedYesterdayDate} (Average)
                  </TableCell>
                  <TableCell>
                    {formatePrice(data?.revenueByHourWeekly["0"] / 7) ?? "0"}
                  </TableCell>
                  <TableCell>
                    {formatePrice(data?.revenueByHourWeekly["1"] / 7) ?? "0"}
                  </TableCell>
                  <TableCell>
                    {formatePrice(data?.revenueByHourWeekly["2"] / 7) ?? "0"}
                  </TableCell>
                  <TableCell>
                    {formatePrice(data?.revenueByHourWeekly["3"] / 7) ?? "0"}
                  </TableCell>
                  <TableCell>
                    {formatePrice(data?.revenueByHourWeekly["4"] / 7) ?? "0"}
                  </TableCell>
                  <TableCell>
                    {formatePrice(data?.revenueByHourWeekly["5"] / 7) ?? "0"}
                  </TableCell>
                  <TableCell>
                    {formatePrice(data?.revenueByHourWeekly["6"] / 7) ?? "0"}
                  </TableCell>
                  <TableCell>
                    {formatePrice(data?.revenueByHourWeekly["7"] / 7) ?? "0"}
                  </TableCell>
                  <TableCell>
                    {formatePrice(data?.revenueByHourWeekly["8"] / 7) ?? "0"}
                  </TableCell>
                  <TableCell>
                    {formatePrice(data?.revenueByHourWeekly["9"] / 7) ?? "0"}
                  </TableCell>
                  <TableCell>
                    {formatePrice(data?.revenueByHourWeekly["10"] / 7) ?? "0"}
                  </TableCell>
                  <TableCell>
                    {formatePrice(data?.revenueByHourWeekly["11"] / 7) ?? "0"}
                  </TableCell>
                  <TableCell>
                    {formatePrice(data?.revenueByHourWeekly["12"] / 7) ?? "0"}
                  </TableCell>
                  <TableCell>
                    {formatePrice(data?.revenueByHourWeekly["13"] / 7) ?? "0"}
                  </TableCell>
                  <TableCell>
                    {formatePrice(data?.revenueByHourWeekly["14"] / 7) ?? "0"}
                  </TableCell>
                  <TableCell>
                    {formatePrice(data?.revenueByHourWeekly["15"] / 7) ?? "0"}
                  </TableCell>
                  <TableCell>
                    {formatePrice(data?.revenueByHourWeekly["16"] / 7) ?? "0"}
                  </TableCell>
                  <TableCell>
                    {formatePrice(data?.revenueByHourWeekly["17"] / 7) ?? "0"}
                  </TableCell>
                  <TableCell>
                    {formatePrice(data?.revenueByHourWeekly["18"] / 7) ?? "0"}
                  </TableCell>
                  <TableCell>
                    {formatePrice(data?.revenueByHourWeekly["19"] / 7) ?? "0"}
                  </TableCell>
                  <TableCell>
                    {formatePrice(data?.revenueByHourWeekly["20"] / 7) ?? "0"}
                  </TableCell>
                  <TableCell>
                    {formatePrice(data?.revenueByHourWeekly["21"] / 7) ?? "0"}
                  </TableCell>
                  <TableCell>
                    {formatePrice(data?.revenueByHourWeekly["22"] / 7) ?? "0"}
                  </TableCell>
                  <TableCell>
                    {formatePrice(data?.revenueByHourWeekly["23"] / 7) ?? "0"}
                  </TableCell>
                </TableRow>
                <TableRow
                  sx={{ "& > td ": { borderRight: "1px dashed #d6d6d6" } }}
                >
                  <TableCell>Last 30days vs Last Week (Average)</TableCell>
                  <TableCell>
                    {data?.revenueByHourWeekly["0"] ===
                    data?.revenueByHourLast30days["0"]
                      ? "0%"
                      : data?.revenueByHourLast30days["0"] === 0
                      ? "0%"
                      : data?.revenueByHourWeekly["0"] / 7 >=
                        data?.revenueByHourLast30days["0"] / 30
                      ? "+" +
                        formatePrice(
                          (data?.revenueByHourWeekly["0"] /
                            7 /
                            (data?.revenueByHourLast30days["0"] / 30)) *
                            100 -
                            100
                        ) +
                        "%"
                      : formatePrice(
                          (data?.revenueByHourWeekly["0"] /
                            7 /
                            (data?.revenueByHourLast30days["0"] / 30)) *
                            100 -
                            100
                        ) + "%"}
                  </TableCell>
                  <TableCell>
                    {data?.revenueByHourWeekly["1"] ===
                    data?.revenueByHourLast30days["1"]
                      ? "0%"
                      : data?.revenueByHourLast30days["1"] === 0
                      ? "0%"
                      : data?.revenueByHourWeekly["1"] / 7 >=
                        data?.revenueByHourLast30days["1"] / 30
                      ? "+" +
                        formatePrice(
                          (data?.revenueByHourWeekly["1"] /
                            7 /
                            (data?.revenueByHourLast30days["1"] / 30)) *
                            100 -
                            100
                        ) +
                        "%"
                      : formatePrice(
                          (data?.revenueByHourWeekly["1"] /
                            7 /
                            (data?.revenueByHourLast30days["1"] / 30)) *
                            100 -
                            100
                        ) + "%"}
                  </TableCell>
                  <TableCell>
                    {data?.revenueByHourWeekly["2"] ===
                    data?.revenueByHourLast30days["2"]
                      ? "0%"
                      : data?.revenueByHourLast30days["2"] === 0
                      ? "0%"
                      : data?.revenueByHourWeekly["2"] / 7 >=
                        data?.revenueByHourLast30days["2"] / 30
                      ? "+" +
                        formatePrice(
                          (data?.revenueByHourWeekly["2"] /
                            7 /
                            (data?.revenueByHourLast30days["2"] / 30)) *
                            100 -
                            100
                        ) +
                        "%"
                      : formatePrice(
                          (data?.revenueByHourWeekly["2"] /
                            7 /
                            (data?.revenueByHourLast30days["2"] / 30)) *
                            100 -
                            100
                        ) + "%"}
                  </TableCell>
                  <TableCell>
                    {data?.revenueByHourWeekly["3"] ===
                    data?.revenueByHourLast30days["3"]
                      ? "0%"
                      : data?.revenueByHourLast30days["3"] === 0
                      ? "0%"
                      : data?.revenueByHourWeekly["3"] / 7 >=
                        data?.revenueByHourLast30days["3"] / 30
                      ? "+" +
                        formatePrice(
                          (data?.revenueByHourWeekly["3"] /
                            7 /
                            (data?.revenueByHourLast30days["3"] / 30)) *
                            100 -
                            100
                        ) +
                        "%"
                      : formatePrice(
                          (data?.revenueByHourWeekly["3"] /
                            7 /
                            (data?.revenueByHourLast30days["3"] / 30)) *
                            100 -
                            100
                        ) + "%"}
                  </TableCell>
                  <TableCell>
                    {data?.revenueByHourWeekly["4"] ===
                    data?.revenueByHourLast30days["4"]
                      ? "0%"
                      : data?.revenueByHourLast30days["4"] === 0
                      ? "0%"
                      : data?.revenueByHourWeekly["4"] / 7 >=
                        data?.revenueByHourLast30days["4"] / 30
                      ? "+" +
                        formatePrice(
                          (data?.revenueByHourWeekly["4"] /
                            7 /
                            (data?.revenueByHourLast30days["4"] / 30)) *
                            100 -
                            100
                        ) +
                        "%"
                      : formatePrice(
                          (data?.revenueByHourWeekly["4"] /
                            7 /
                            (data?.revenueByHourLast30days["4"] / 30)) *
                            100 -
                            100
                        ) + "%"}
                  </TableCell>
                  <TableCell>
                    {data?.revenueByHourWeekly["5"] ===
                    data?.revenueByHourLast30days["5"]
                      ? "0%"
                      : data?.revenueByHourLast30days["5"] === 0
                      ? "0%"
                      : data?.revenueByHourWeekly["5"] / 7 >=
                        data?.revenueByHourLast30days["5"] / 30
                      ? "+" +
                        formatePrice(
                          (data?.revenueByHourWeekly["5"] /
                            7 /
                            (data?.revenueByHourLast30days["5"] / 30)) *
                            100 -
                            100
                        ) +
                        "%"
                      : formatePrice(
                          (data?.revenueByHourWeekly["5"] /
                            7 /
                            (data?.revenueByHourLast30days["5"] / 30)) *
                            100 -
                            100
                        ) + "%"}
                  </TableCell>
                  <TableCell>
                    {data?.revenueByHourWeekly["6"] ===
                    data?.revenueByHourLast30days["6"]
                      ? "0%"
                      : data?.revenueByHourLast30days["6"] === 0
                      ? "0%"
                      : data?.revenueByHourWeekly["6"] / 7 >=
                        data?.revenueByHourLast30days["6"] / 30
                      ? "+" +
                        formatePrice(
                          (data?.revenueByHourWeekly["6"] /
                            7 /
                            (data?.revenueByHourLast30days["6"] / 30)) *
                            100 -
                            100
                        ) +
                        "%"
                      : formatePrice(
                          (data?.revenueByHourWeekly["6"] /
                            7 /
                            (data?.revenueByHourLast30days["6"] / 30)) *
                            100 -
                            100
                        ) + "%"}
                  </TableCell>
                  <TableCell>
                    {data?.revenueByHourWeekly["7"] ===
                    data?.revenueByHourLast30days["7"]
                      ? "0%"
                      : data?.revenueByHourLast30days["7"] === 0
                      ? "0%"
                      : data?.revenueByHourWeekly["7"] / 7 >=
                        data?.revenueByHourLast30days["7"] / 30
                      ? "+" +
                        formatePrice(
                          (data?.revenueByHourWeekly["7"] /
                            7 /
                            (data?.revenueByHourLast30days["7"] / 30)) *
                            100 -
                            100
                        ) +
                        "%"
                      : formatePrice(
                          (data?.revenueByHourWeekly["7"] /
                            7 /
                            (data?.revenueByHourLast30days["7"] / 30)) *
                            100 -
                            100
                        ) + "%"}
                  </TableCell>
                  <TableCell>
                    {data?.revenueByHourWeekly["8"] ===
                    data?.revenueByHourLast30days["8"]
                      ? "0%"
                      : data?.revenueByHourLast30days["8"] === 0
                      ? "0%"
                      : data?.revenueByHourWeekly["8"] / 7 >=
                        data?.revenueByHourLast30days["8"] / 30
                      ? "+" +
                        formatePrice(
                          (data?.revenueByHourWeekly["8"] /
                            7 /
                            (data?.revenueByHourLast30days["8"] / 30)) *
                            100 -
                            100
                        ) +
                        "%"
                      : formatePrice(
                          (data?.revenueByHourWeekly["8"] /
                            7 /
                            (data?.revenueByHourLast30days["8"] / 30)) *
                            100 -
                            100
                        ) + "%"}
                  </TableCell>
                  <TableCell>
                    {data?.revenueByHourWeekly["9"] ===
                    data?.revenueByHourLast30days["9"]
                      ? "0%"
                      : data?.revenueByHourLast30days["9"] === 0
                      ? "0%"
                      : data?.revenueByHourWeekly["9"] / 7 >=
                        data?.revenueByHourLast30days["9"] / 30
                      ? "+" +
                        formatePrice(
                          (data?.revenueByHourWeekly["9"] /
                            7 /
                            (data?.revenueByHourLast30days["9"] / 30)) *
                            100 -
                            100
                        ) +
                        "%"
                      : formatePrice(
                          (data?.revenueByHourWeekly["9"] /
                            7 /
                            (data?.revenueByHourLast30days["9"] / 30)) *
                            100 -
                            100
                        ) + "%"}
                  </TableCell>
                  <TableCell>
                    {data?.revenueByHourWeekly["10"] ===
                    data?.revenueByHourLast30days["10"]
                      ? "0%"
                      : data?.revenueByHourLast30days["10"] === 0
                      ? "0%"
                      : data?.revenueByHourWeekly["10"] / 7 >=
                        data?.revenueByHourLast30days["10"] / 30
                      ? "+" +
                        formatePrice(
                          (data?.revenueByHourWeekly["10"] /
                            7 /
                            (data?.revenueByHourLast30days["10"] / 30)) *
                            100 -
                            100
                        ) +
                        "%"
                      : formatePrice(
                          (data?.revenueByHourWeekly["10"] /
                            7 /
                            (data?.revenueByHourLast30days["10"] / 30)) *
                            100 -
                            100
                        ) + "%"}
                  </TableCell>
                  <TableCell>
                    {data?.revenueByHourWeekly["11"] ===
                    data?.revenueByHourLast30days["11"]
                      ? "0%"
                      : data?.revenueByHourLast30days["11"] === 0
                      ? "0%"
                      : data?.revenueByHourWeekly["11"] / 7 >=
                        data?.revenueByHourLast30days["11"] / 30
                      ? "+" +
                        formatePrice(
                          (data?.revenueByHourWeekly["11"] /
                            7 /
                            (data?.revenueByHourLast30days["11"] / 30)) *
                            100 -
                            100
                        ) +
                        "%"
                      : formatePrice(
                          (data?.revenueByHourWeekly["11"] /
                            7 /
                            (data?.revenueByHourLast30days["11"] / 30)) *
                            100 -
                            100
                        ) + "%"}
                  </TableCell>
                  <TableCell>
                    {data?.revenueByHourWeekly["12"] ===
                    data?.revenueByHourLast30days["12"]
                      ? "0%"
                      : data?.revenueByHourLast30days["12"] === 0
                      ? "0%"
                      : data?.revenueByHourWeekly["12"] / 7 >=
                        data?.revenueByHourLast30days["12"] / 30
                      ? "+" +
                        formatePrice(
                          (data?.revenueByHourWeekly["12"] /
                            7 /
                            (data?.revenueByHourLast30days["12"] / 30)) *
                            100 -
                            100
                        ) +
                        "%"
                      : formatePrice(
                          (data?.revenueByHourWeekly["12"] /
                            7 /
                            (data?.revenueByHourLast30days["12"] / 30)) *
                            100 -
                            100
                        ) + "%"}
                  </TableCell>
                  <TableCell>
                    {data?.revenueByHourWeekly["13"] ===
                    data?.revenueByHourLast30days["13"]
                      ? "0%"
                      : data?.revenueByHourLast30days["13"] === 0
                      ? "0%"
                      : data?.revenueByHourWeekly["13"] / 7 >=
                        data?.revenueByHourLast30days["13"] / 30
                      ? "+" +
                        formatePrice(
                          (data?.revenueByHourWeekly["13"] /
                            7 /
                            (data?.revenueByHourLast30days["13"] / 30)) *
                            100 -
                            100
                        ) +
                        "%"
                      : formatePrice(
                          (data?.revenueByHourWeekly["13"] /
                            7 /
                            (data?.revenueByHourLast30days["13"] / 30)) *
                            100 -
                            100
                        ) + "%"}
                  </TableCell>
                  <TableCell>
                    {data?.revenueByHourWeekly["14"] ===
                    data?.revenueByHourLast30days["14"]
                      ? "0%"
                      : data?.revenueByHourLast30days["14"] === 0
                      ? "0%"
                      : data?.revenueByHourWeekly["14"] / 7 >=
                        data?.revenueByHourLast30days["14"] / 30
                      ? "+" +
                        formatePrice(
                          (data?.revenueByHourWeekly["14"] /
                            7 /
                            (data?.revenueByHourLast30days["14"] / 30)) *
                            100 -
                            100
                        ) +
                        "%"
                      : formatePrice(
                          (data?.revenueByHourWeekly["14"] /
                            7 /
                            (data?.revenueByHourLast30days["14"] / 30)) *
                            100 -
                            100
                        ) + "%"}
                  </TableCell>
                  <TableCell>
                    {data?.revenueByHourWeekly["15"] ===
                    data?.revenueByHourLast30days["15"]
                      ? "0%"
                      : data?.revenueByHourLast30days["15"] === 0
                      ? "0%"
                      : data?.revenueByHourWeekly["15"] / 7 >=
                        data?.revenueByHourLast30days["15"] / 30
                      ? "+" +
                        formatePrice(
                          (data?.revenueByHourWeekly["15"] /
                            7 /
                            (data?.revenueByHourLast30days["15"] / 30)) *
                            100 -
                            100
                        ) +
                        "%"
                      : formatePrice(
                          (data?.revenueByHourWeekly["15"] /
                            7 /
                            (data?.revenueByHourLast30days["15"] / 30)) *
                            100 -
                            100
                        ) + "%"}
                  </TableCell>
                  <TableCell>
                    {data?.revenueByHourWeekly["16"] ===
                    data?.revenueByHourLast30days["16"]
                      ? "0%"
                      : data?.revenueByHourLast30days["16"] === 0
                      ? "0%"
                      : data?.revenueByHourWeekly["16"] / 7 >=
                        data?.revenueByHourLast30days["16"] / 30
                      ? "+" +
                        formatePrice(
                          (data?.revenueByHourWeekly["16"] /
                            7 /
                            (data?.revenueByHourLast30days["16"] / 30)) *
                            100 -
                            100
                        ) +
                        "%"
                      : formatePrice(
                          (data?.revenueByHourWeekly["16"] /
                            7 /
                            (data?.revenueByHourLast30days["16"] / 30)) *
                            100 -
                            100
                        ) + "%"}
                  </TableCell>
                  <TableCell>
                    {data?.revenueByHourWeekly["17"] ===
                    data?.revenueByHourLast30days["17"]
                      ? "0%"
                      : data?.revenueByHourLast30days["17"] === 0
                      ? "0%"
                      : data?.revenueByHourWeekly["17"] / 7 >=
                        data?.revenueByHourLast30days["17"] / 30
                      ? "+" +
                        formatePrice(
                          (data?.revenueByHourWeekly["17"] /
                            7 /
                            (data?.revenueByHourLast30days["17"] / 30)) *
                            100 -
                            100
                        ) +
                        "%"
                      : formatePrice(
                          (data?.revenueByHourWeekly["17"] /
                            7 /
                            (data?.revenueByHourLast30days["17"] / 30)) *
                            100 -
                            100
                        ) + "%"}
                  </TableCell>
                  <TableCell>
                    {data?.revenueByHourWeekly["18"] ===
                    data?.revenueByHourLast30days["18"]
                      ? "0%"
                      : data?.revenueByHourLast30days["18"] === 0
                      ? "0%"
                      : data?.revenueByHourWeekly["18"] / 7 >=
                        data?.revenueByHourLast30days["18"] / 30
                      ? "+" +
                        formatePrice(
                          (data?.revenueByHourWeekly["18"] /
                            7 /
                            (data?.revenueByHourLast30days["18"] / 30)) *
                            100 -
                            100
                        ) +
                        "%"
                      : formatePrice(
                          (data?.revenueByHourWeekly["18"] /
                            7 /
                            (data?.revenueByHourLast30days["18"] / 30)) *
                            100 -
                            100
                        ) + "%"}
                  </TableCell>
                  <TableCell>
                    {data?.revenueByHourWeekly["19"] ===
                    data?.revenueByHourLast30days["19"]
                      ? "0%"
                      : data?.revenueByHourLast30days["19"] === 0
                      ? "0%"
                      : data?.revenueByHourWeekly["19"] / 7 >=
                        data?.revenueByHourLast30days["19"] / 30
                      ? "+" +
                        formatePrice(
                          (data?.revenueByHourWeekly["19"] /
                            7 /
                            (data?.revenueByHourLast30days["19"] / 30)) *
                            100 -
                            100
                        ) +
                        "%"
                      : formatePrice(
                          (data?.revenueByHourWeekly["19"] /
                            7 /
                            (data?.revenueByHourLast30days["19"] / 30)) *
                            100 -
                            100
                        ) + "%"}
                  </TableCell>
                  <TableCell>
                    {data?.revenueByHourWeekly["20"] ===
                    data?.revenueByHourLast30days["20"]
                      ? "0%"
                      : data?.revenueByHourLast30days["20"] === 0
                      ? "0%"
                      : data?.revenueByHourWeekly["20"] / 7 >=
                        data?.revenueByHourLast30days["20"] / 30
                      ? "+" +
                        formatePrice(
                          (data?.revenueByHourWeekly["20"] /
                            7 /
                            (data?.revenueByHourLast30days["20"] / 30)) *
                            100 -
                            100
                        ) +
                        "%"
                      : formatePrice(
                          (data?.revenueByHourWeekly["20"] /
                            7 /
                            (data?.revenueByHourLast30days["20"] / 30)) *
                            100 -
                            100
                        ) + "%"}
                  </TableCell>
                  <TableCell>
                    {data?.revenueByHourWeekly["21"] ===
                    data?.revenueByHourLast30days["21"]
                      ? "0%"
                      : data?.revenueByHourLast30days["21"] === 0
                      ? "0%"
                      : data?.revenueByHourWeekly["21"] / 7 >=
                        data?.revenueByHourLast30days["21"] / 30
                      ? "+" +
                        formatePrice(
                          (data?.revenueByHourWeekly["21"] /
                            7 /
                            (data?.revenueByHourLast30days["21"] / 30)) *
                            100 -
                            100
                        ) +
                        "%"
                      : formatePrice(
                          (data?.revenueByHourWeekly["21"] /
                            7 /
                            (data?.revenueByHourLast30days["21"] / 30)) *
                            100 -
                            100
                        ) + "%"}
                  </TableCell>
                  <TableCell>
                    {data?.revenueByHourWeekly["22"] ===
                    data?.revenueByHourLast30days["22"]
                      ? "0%"
                      : data?.revenueByHourLast30days["22"] === 0
                      ? "0%"
                      : data?.revenueByHourWeekly["22"] / 7 >=
                        data?.revenueByHourLast30days["22"] / 30
                      ? "+" +
                        formatePrice(
                          (data?.revenueByHourWeekly["22"] /
                            7 /
                            (data?.revenueByHourLast30days["22"] / 30)) *
                            100 -
                            100
                        ) +
                        "%"
                      : formatePrice(
                          (data?.revenueByHourWeekly["22"] /
                            7 /
                            (data?.revenueByHourLast30days["22"] / 30)) *
                            100 -
                            100
                        ) + "%"}
                  </TableCell>
                  <TableCell>
                    {data?.revenueByHourWeekly["23"] ===
                    data?.revenueByHourLast30days["23"]
                      ? "0%"
                      : data?.revenueByHourLast30days["23"] === 0
                      ? "0%"
                      : data?.revenueByHourWeekly["23"] / 7 >=
                        data?.revenueByHourLast30days["23"] / 30
                      ? "+" +
                        formatePrice(
                          (data?.revenueByHourWeekly["23"] /
                            7 /
                            (data?.revenueByHourLast30days["23"] / 30)) *
                            100 -
                            100
                        ) +
                        "%"
                      : formatePrice(
                          (data?.revenueByHourWeekly["23"] /
                            7 /
                            (data?.revenueByHourLast30days["23"] / 30)) *
                            100 -
                            100
                        ) + "%"}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </Box>
  );
};

export default StatisticsByHourChart;
