import axios from "axios";

export const login = (email: string, password: string, rememberMe: boolean) => {
  return new Promise((resolve, reject) => {
    axios
      .post("/api/v1/auth/sign-in", { email, password })
      .then((res) => {
        // console.log(res, "res");
        const expirationDate = new Date();

        if(rememberMe === true){
          expirationDate.setDate(expirationDate.getDate() + 7); // Set expiration date to 1 week from now
        }else {
          expirationDate.setDate(expirationDate.getDate() + 1); // Set expiration date to 1 day from now
        }
        
        localStorage.setItem("token", res.data.token);
        localStorage.setItem('expirationDate', expirationDate.toISOString());

        axios.defaults.headers.common.Authorization =
          "Bearer " + res.data.token;
        resolve({
          success: true,
          token: res.data.token,
          user: {
            email: res.data.user.email,
            firstName: res.data.user.firstName,
            lastName: res.data.user.lastName,
            user_role: res.data.user.user_role,
          },
        });
      })
      .catch((error) => {
        reject(error);
      });
  });
};


export const loginWithVerifyCode = (email: string, verifyCode: string) => {
  return new Promise((resolve, reject) => {
    axios
      .get("/api/v1/auth/verify", {
        params: {
          email: email,
          verifyCode: verifyCode
        }
      })
      .then((res) => {
       
        localStorage.setItem("token", res.data.token);

        axios.defaults.headers.common.Authorization =
          "Bearer " + res.data.token;
        resolve({
          success: true,
          token: res.data.token,
          user: {
            email: res.data.user.email,
            firstName: res.data.user.firstName,
            lastName: res.data.user.lastName,
            user_role: res.data.user.user_role,
          },
        });
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const loginWithAccessToken = () => {
  return new Promise((resolve, reject) => {
    axios
      .get("/api/v1/auth/access-token")
      .then((res) => {
        // console.log(res, "res");
        localStorage.setItem("token", res.data.token);
        axios.defaults.headers.common.Authorization =
          "Bearer " + res.data.token;
        resolve({
          success: true,
          token: res.data.token,
          user: {
            email: res.data.user.email,
            firstName: res.data.user.firstName,
            lastName: res.data.user.lastName,
            user_role: res.data.user.user_role,
          },
        });
      })
      .catch((error) => {
        reject(error);
      });
  });
};
