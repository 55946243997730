import React, { FC, useEffect, useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import { useInView } from "react-intersection-observer";
import {
  Avatar,
  Box,
  Grid,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Typography,
} from "@mui/material";
import { useSelector } from "react-redux";

import { IMSkuItem } from "../../models/Sku";

import { useSkus } from "../../hooks/useSkus";

// import Filters from "./Filters";
import Loadings from "../../compoment/common/Loadings";
import {
  BRANDS,
  CURRENCY,
  BRANDS_SKU,
  IMAGE_FROM_WEBSITES,
  SKU_VISIBILITY,
  SKU_STATUS,
} from "../../utils/constant";
import { formatePrice } from "../../utils/common";
import dayjs, { Dayjs } from "dayjs";
import { selectUser } from "../../redux/slices/authSlice";
import { useQuery } from "react-query";
import axios from "axios";

const Maintenance: FC = () => {
  const currentUser = useSelector(selectUser);

  const fetchData = async () => {
    const response = await axios.get("/api/v1/scrap");
    return response.data;
  };

  const { data, isLoading } = useQuery("myData", fetchData, {
    refetchInterval: 3600000, // 5 minutes in milliseconds
  });

  console.log(data, "data");

  return (
    <Box
      sx={{
        height: "100vh",
        backgroundImage: "url(/assets/bg8.jpg)",
        backgroundRepeat: "no-repeat",
        backgroundColor: (t) =>
          t.palette.mode === "light" ? t.palette.grey[50] : t.palette.grey[900],
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      {currentUser && currentUser.user_role === "administrator" ? (
        <Box sx={{ alignItems: "center" }}>
          {isLoading ? (
            <Box
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
              height={"calc( 100vh - 160px)"}
            >
              <Loadings />
            </Box>
          ) : (
            <Box
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <Grid container alignItems={"center"} sx={{ paddingTop: "48px" }}>
                <Grid item md={12} sm={12} xs={12}>
                  <Typography
                    variant="h6"
                    sx={{
                      textAlign: "center",
                      paddingTop: "48px",
                      color: "white",
                      fontSize: "36px",
                    }}
                  >
                    {"Paypal Maintenance : " + data?.data?.paypal}
                  </Typography>
                </Grid>
                <Grid item md={12} sm={12} xs={12}>
                  <Typography
                    variant="h6"
                    sx={{
                      textAlign: "center",
                      paddingTop: "48px",
                      color: "white",
                      fontSize: "36px",
                    }}
                  >
                    {"Ingenico Maintenance : " + data?.data?.ogone}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          )}
        </Box>
      ) : (
        <Box sx={{ textAlign: "center", marginTop: "200px" }}>
          <Typography variant="h6">
            You can't access this Page. Because you have no pemission.
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default Maintenance;
