import React, { FC, useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import {
  Box,
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import CreateUser from "./create_user";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import axios from "axios";
import { useSelector } from "react-redux";
import { selectUser, setUser } from "../../../redux/slices/authSlice";
import reportWebVitals from "../../../reportWebVitals";
import { NEWBRANDS, NEWBRANDS1 } from "../../../utils/constant";
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
export const revenueAlertSelect1 = ["Daily", "Weekly", "Monthly"];
export const revenueAlertSelect2 = [
  {
    value: "Daily",
    label: "Daily",
  },
  {
    value: "Weekly",
    label: "Weekly",
  },
  {
    value: "Monthly",
    label: "Monthly",
  },
];

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

interface User {
  _id: string;
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  user_role: string;
  phonenumber: string;
  effisysAlert: boolean;
  revenueAlert: Array<string>;
}

const AdminDashboard: FC = () => {
  const [open, setOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState<number>();
  const [users, setUsers] = useState<User[]>([]);
  const currentUser = useSelector(selectUser);
  const [values, setValues] = useState<{
    revenueAlertSelect: string[];
  }>({ revenueAlertSelect: revenueAlertSelect1 });

  const handleChange =
    (id: string) => async (event: React.ChangeEvent<HTMLInputElement>) => {
      const response = await axios.put(`/api/v1/auth/user/effisysAlert/${id}`, {
        effisysAlert: event.target.checked,
      });

      fetchUserList();
    };

    const handleChange1 = (id: string) =>async (event: SelectChangeEvent<typeof revenueAlertSelect1>) => {
      const {
        target: { value },
      } = event;
      // console.log('1111111', value);
      // setUsers((userlist: any) => 
      //   userlist.map((obj: any) => (obj._id === id ? { ...obj, revenueAlert: ( typeof value === 'string' ? value.split(',') : value ) } : obj))
      // );

      const response = await axios.put(`/api/v1/auth/user/revenueAlert/${id}`, {
        revenueAlert: value,
      });
      fetchUserList();
    
    };
  const handleCreate = () => {
    setSelectedUser(undefined);
    setOpen(true);
  };

  const fetchUserList = async () => {
    const response = await axios.get(`/api/v1/auth/user`);
    // console.log(response, "response");
    setUsers(response.data);
  };

  useEffect(() => {
    fetchUserList();
  }, []);


  useEffect(() => {
    // console.log('userssssssss', users);
  }, [users]);

  const handleEdit = (idx: number) => {
    setSelectedUser(idx);
    setOpen(true);
  };
  const handleDelete = async (idx: string) => {
    // console.log(idx, "delete id");
    const response = await axios.delete(`/api/v1/auth/user/${idx}`);
    // console.log(response, "response");
    fetchUserList();
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box
      sx={{
        height: "100vh",
        margin: "0 50px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            margin: "30px",
            alignItems: "flex-end",
            display: "flex",
            flexDirection: "row-reverse",
            justifyContent: "space-between",
          }}
        >
          <Button variant="outlined" onClick={handleCreate}>
            Create
          </Button>
          <Typography variant="h4" component="h4">
            User Management
          </Typography>
        </Box>
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>
            {selectedUser === undefined ? "Create User" : "Edit User"}
          </DialogTitle>
          <DialogContent>
            <CreateUser
              onChange={() => {
                fetchUserList();
                handleClose();
              }}
              user={
                typeof selectedUser !== "undefined"
                  ? users[selectedUser]
                  : undefined
              }
            ></CreateUser>
          </DialogContent>
        </Dialog>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell align="center">Name</StyledTableCell>
                <StyledTableCell align="center">Email</StyledTableCell>
                <StyledTableCell align="center">PhoneNumber</StyledTableCell>
                <StyledTableCell align="center">User Role</StyledTableCell>
                <StyledTableCell align="center">Effisys Alert</StyledTableCell>
                <StyledTableCell align="center">Revenue Alert</StyledTableCell>
                <StyledTableCell align="center">Action</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users.map((row: User, index: number) => (
                <StyledTableRow key={row.email}>
                  <StyledTableCell align="center">
                    {row.firstName + " " + row.lastName}
                  </StyledTableCell>
                  <StyledTableCell align="center">{row.email}</StyledTableCell>
                  <StyledTableCell align="center">
                    {row.phonenumber}
                  </StyledTableCell>

                  <StyledTableCell align="center">
                    {row.user_role}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <Checkbox
                      id="effisysAlert"
                      checked={row.effisysAlert}
                      onChange={handleChange(row._id)}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  </StyledTableCell>
                  <StyledTableCell>
                    <FormControl sx={{ m: 1, width: 300 }}>
                      <InputLabel id="demo-multiple-checkbox-label">
                        Revenue Alert
                      </InputLabel>
                      <Select
                        labelId="demo-multiple-checkbox-label"
                        id="demo-multiple-checkbox"
                        multiple
                        value={row.revenueAlert}
                        onChange={handleChange1(row._id)}
                        input={<OutlinedInput label="Revenue Alert" />}
                        renderValue={(selected) => selected.join(", ")}
                        MenuProps={MenuProps}
                      >
                        {revenueAlertSelect1.map((name) => (
                          <MenuItem key={name} value={name}>
                            <Checkbox checked={row.revenueAlert.indexOf(name) > -1} />
                            <ListItemText primary={name} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <Button
                      sx={{ marginLeft: "10px" }}
                      variant="outlined"
                      onClick={() => handleEdit(index)}
                    >
                      Edit
                    </Button>{" "}
                    {currentUser && currentUser.email === row.email ? (
                      <>
                        <Button
                          disabled
                          sx={{ marginLeft: "10px" }}
                          variant="outlined"
                          onClick={() => handleDelete(row._id)}
                        >
                          Delete
                        </Button>
                      </>
                    ) : (
                      <Button
                        sx={{ marginLeft: "10px" }}
                        variant="outlined"
                        onClick={() => handleDelete(row._id)}
                      >
                        Delete
                      </Button>
                    )}
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
};

export default AdminDashboard;
