import axios from "axios";
import { useInfiniteQuery, useQuery } from "react-query";
import { IMInvoiceItem } from "../models/Invoice";
import dayjs, { Dayjs } from "dayjs";

export const useInvoices = (filters: {
  invoice: string;
  brands: string[];
  start_date: Dayjs;
  end_date: Dayjs;
}) => {
  return useInfiniteQuery({
    queryKey: ["invoice", JSON.stringify(filters)],
    queryFn: async ({ pageParam }) => {
      const searchCriteria = [];

      // console.log(filters, "filters");
  
      
      if (filters.invoice) {
        searchCriteria.push({
          field: "increment_id",
          value: filters.invoice,
          condition_type: "like",
        });
      }

      if ((filters.start_date >= dayjs('2022-04-17')) && (filters.end_date <= dayjs(new Date()))) {
        searchCriteria.push({
          field: "created_at",
          value: filters.start_date.format("YYYY-MM-DD") + " 00:00:00",
          condition_type: "gteq",
        });
        searchCriteria.push({
          field: "created_at",
          value: filters.end_date.format("YYYY-MM-DD") + " 23:59:59",
          condition_type: "lteq",
        });
      }
      

      const currentPage = pageParam?.currentPage ?? 1;
      const response = await axios.post(
        `/api/v1/invoices`,
        {
          brands: filters.brands,
          searchCriteria,
          currentPage: currentPage,
        }
      );

      return response.data.data;
    },
    getNextPageParam: (lastPage: any) => {
      return lastPage.items && lastPage.items.length >= 10
        ? { currentPage: lastPage.currentPage + 1 }
        : undefined;
    },
    enabled: true,
  });
};

export const useInvoice = (brand: string, invoiceId: string) => {
  return useQuery({
    queryKey: ["invoice", brand, invoiceId],
    queryFn: async () => {
      const response = await axios.get(
        `/api/v1/invoices/${brand}/${invoiceId}`
      );

      return response.data.data as IMInvoiceItem;
    },
    enabled: !!brand && !!invoiceId,
  });
};
