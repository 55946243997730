import MainPage from "./pages/Main";
import OrdersPage from "./pages/Orders";
import InvoicePage from "./pages/Invoice";
import SkuPage from "./pages/Sku";
import OrderDetailPage from "./pages/OrderDetail";
import InvoiceDetailPage from "./pages/InvoiceDetail";
import SkuOrderDetailPage from "./pages/SkuOrderDetail";
import SkuInvoiceDetailPage from "./pages/SkuInvoiceDetail";
import AuthPage from "./pages/Auth";
import LoginPage from "./pages/Auth/Login";
import AdminDashboard from "./pages/Auth/AdminDashboard";
import RegisterPage from "./pages/Auth/Register";
import { Link, Navigate } from "react-router-dom";
import Effisys from "./pages/Effisys";
import Statistics from "./pages/Statistics";
import SmartDashboard from "./pages/SmartDashboard/index";
import TemporaryLogin from "./pages/Auth/Login/TemporaryLogin";
import VerifyTempPassword  from "./pages/Auth/Login/Verify";
import Maintenance from "./pages/Maintenance";
import EffisysRecept from "./pages/EffisysRecept";

const routes = [
  {
    path: "/auth",
    element: <AuthPage />,
    children: [
      {
        path: "login",
        element: <LoginPage />,
      },
      {
        path: "register",
        element: <RegisterPage />,
      },
      {
        path: "temporaryLogin",
        element: <TemporaryLogin />,
      },
      {
        path: "verify",
        element: <VerifyTempPassword />,
      },
    ],
  },
  {
    path: "/",
    element: <MainPage />,
    children: [
      {
        path: "orders",
        element: <OrdersPage />,
      },
      {
        path: "admindashboard",
        element: <AdminDashboard />,
      },
      {
        path: "orderdetail/:brand/:orderId",
        element: <OrderDetailPage />,
      },
      {
        index: true,
        element: <Navigate to={"/orders"} />,
      },
      {
        path: "invoices",
        element: <InvoicePage />,
      },   
      {
        path: "invoicedetail/:brand/:orderId/:invoiceId",
        element: <InvoiceDetailPage />,
      },
      {
        path: "skus",
        element: <SkuPage />,
      },
      {
        path: "skuorderdetail/:brand/:product_id",
        element: <SkuOrderDetailPage />,
      },
      {
        path: "skuinvoicedetail/:brand/:product_id",
        element: <SkuInvoiceDetailPage />,
      },
      {
        path: "effisys",
        element: <Effisys />,
      },{
        path: "effisysrecept",
        element: <EffisysRecept />,
      },
      {
        path: "statistics",
        element: <Statistics />,
      },  
      {
        path: "smart dashboard",
        element: <SmartDashboard />,
      },
      {
        path: "maintenance",
        element: <Maintenance />,
      },
    ],
  },
];

export default routes;
