import React, { FC, useState } from "react";
import {
  Alert,
  Box,
  Button,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";
import * as authService from "../../../services/authService";
import { useAppDispatch } from "../../../redux/store";
import { setUser } from "../../../redux/slices/authSlice";
import Grid from "@mui/material/Unstable_Grid2";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import axios from "axios";

import { Formik } from "formik";
import * as Yup from "yup";
interface User {
  _id: string;
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  user_role: string;
  phonenumber: string;
}
interface CreateUserProps {
  user: User | undefined;
  onChange: any;
}
interface CustomEvent {
  target: {
    key: string;
    value: string;
  };
}
const CreateUser: FC<CreateUserProps> = (props) => {
  const [message, setMessage] = React.useState("");
  // const [user, setUser_] = useState<any>(props.user ?? {});

  const onSubmit = async (values: any) => {
    try {
      if (props.user === undefined) {
        if (values.email === undefined) {
          // console.log("alert");
          setMessage("Email required");
        }
        if (values.password === "") {
          // console.log("alert");
          setMessage("Password required");
        }
        if (values.email > "" && values.password > "") {
          const response = await axios.post("/api/v1/auth/sign-up", values);
          props.onChange();
        }
      } else {
        // console.log(values.password, "pasword");
        if (values.password === "") {
          delete values.password;
        }
        const response = await axios.put(
          `/api/v1/auth/user/${props.user._id}`,
          values
        );
        props.onChange();
      }
    } catch (err: any) {
      // console.log(err);
      setMessage(err.response.data.error.message);
    }
  };
  //   const [role, setRole] = useState("");

  // const handleChange = (event: CustomEvent) => {
  //   setUser_({
  //     ...user,
  //     [event.target.key]: event.target.value,
  //   });
  // };

  return (
    <Box
      sx={{
        maxHeight: "500px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Snackbar
        open={!!message}
        autoHideDuration={6000}
        onClose={() => {
          setMessage("");
        }}
        message={message}
      />
      <Box
        sx={{
          maxWidth: "400px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Formik
          initialValues={{
            firstName: props.user === undefined ? "" : props.user.firstName,
            lastName: props.user === undefined ? "" : props.user.lastName,
            email: props.user === undefined ? "" : props.user.email,
            phonenumber: props.user === undefined ? "" : props.user.phonenumber,
            password: props.user === undefined ? "" : props.user.password,
            user_role:
              props.user === undefined ? "" : props.user.user_role.toString(),
            submit: null,
          }}
          validationSchema={Yup.object().shape({
            email: Yup.string().email("Invalid format").required(),
            password: props.user
              ? Yup.string().min(1).max(255)
              : Yup.string().min(1).max(255).required(),
            phonenumber: Yup.string()
              .matches(
                /^(\+\d{1,3})?\s?(\(\d{1,3}\))?\s?[0-9\- ]+$/,
                "Must be a valid phone"
              )
              .max(255)
              .required("Phone is required"),
          })}
          onSubmit={onSubmit}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
          }) => (
            <form noValidate onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid xs={12}>
                  <TextField
                    fullWidth
                    value={values.firstName}
                    id="outlined-basic"
                    label="First Name"
                    name="firstName"
                    variant="outlined"
                    onChange={handleChange}
                  />
                </Grid>
                <Grid xs={12}>
                  <TextField
                    fullWidth
                    value={values.lastName}
                    id="outlined-basic"
                    name="lastName"
                    label="Last Name"
                    variant="outlined"
                    onChange={handleChange}
                  />
                </Grid>
                <FormControl
                  fullWidth
                  error={Boolean(touched.email && errors.email)}
                >
                  <Grid xs={12}>
                    {props.user !== undefined ? (
                      <>
                        <TextField
                          fullWidth
                          disabled
                          name="email"
                          value={values.email}
                          id="outlined-basic"
                          label="User Email"
                          variant="outlined"
                          onChange={handleChange}
                        />
                      </>
                    ) : (
                      <>
                        <TextField
                          fullWidth
                          name="email"
                          id="outlined-basic"
                          value={values.email}
                          label="User Email"
                          variant="outlined"
                          onChange={handleChange}
                        />
                      </>
                    )}
                  </Grid>
                  {touched.email && errors.email && (
                    <FormHelperText
                      error
                      id="standard-weight-helper-text--register"
                    >
                      {errors.email}
                    </FormHelperText>
                  )}
                </FormControl>
                <FormControl
                  fullWidth
                  error={Boolean(touched.password && errors.password)}
                >
                  <Grid xs={12}>
                    <TextField
                      fullWidth
                      name="password"
                      id="outlined-basic"
                      label="Password"
                      variant="outlined"
                      onChange={handleChange}
                    />
                  </Grid>
                  {touched.password && errors.password && (
                    <FormHelperText
                      error
                      id="standard-weight-helper-text--register"
                    >
                      {errors.password}
                    </FormHelperText>
                  )}
                </FormControl>
                <FormControl
                  fullWidth
                  error={Boolean(touched.phonenumber && errors.phonenumber)}
                >
                  <Grid xs={12}>
                    <TextField
                      fullWidth
                      value={values.phonenumber}
                      id="outlined-basic"
                      name="phonenumber"
                      label="User PhoneNumber"
                      variant="outlined"
                      onChange={handleChange}
                    />
                  </Grid>
                  {touched.phonenumber && errors.phonenumber && (
                    <FormHelperText
                      error
                      id="standard-weight-helper-text--register"
                    >
                      {errors.phonenumber}
                    </FormHelperText>
                  )}
                </FormControl>
                <Grid xs={12}>
                  <Box>
                    <FormControl sx={{ minWidth: 400 }}>
                      <Select
                        value={values.user_role}
                        // defaultValue={
                        //   props.user === undefined
                        //     ? ""
                        //     : props.user.user_role.toString()
                        // }
                        name="user_role"
                        onChange={handleChange}
                        displayEmpty
                        inputProps={{ "aria-label": "Without label" }}
                      >
                        <MenuItem disabled value="">
                          <em>
                            {props.user === undefined
                              ? "User Role"
                              : props.user.user_role.toString()}
                          </em>
                        </MenuItem>
                        <MenuItem value={"administrator"}>
                          Administrator
                        </MenuItem>
                        <MenuItem value={"normaluser"}>Normal User</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                </Grid>
              </Grid>
              <br></br>
              <Button variant="contained" sx={{ mb: 1 }} type="submit">
                {props.user === undefined ? "Create" : "Update"}
              </Button>
            </form>
          )}
        </Formik>
        {/* <Link to={"/auth/register"}>Register</Link> */}
      </Box>
    </Box>
  );
};

export default CreateUser;
