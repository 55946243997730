import React, { FC, useEffect, useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import { useInView } from "react-intersection-observer";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

import { IMInvoiceItem } from "../../models/Invoice";

import { useInvoices } from "../../hooks/useInvoice";

import Filters from "./Filters";
import Loadings from "../../compoment/common/Loadings";
import { BRANDS, CURRENCY } from "../../utils/constant";
import { formatePrice } from "../../utils/common";
import dayjs, { Dayjs } from 'dayjs';
const Invoice: FC = () => {
  const [searchParams] = useSearchParams();

  const filters = useMemo<{
    invoice: string;
    brands: string[];
    start_date: Dayjs;
    end_date: Dayjs;
  }>(() => {
    const filters = { 
      invoice: searchParams.get("invoice") || "",
      brands: searchParams.get("brands")?.split(",") || BRANDS,
      start_date: dayjs(searchParams.get("start_date") || "",'YYYY-MM-DD'),
      end_date: dayjs(searchParams.get("end_date") || "",'YYYY-MM-DD'),
    };
    return filters;
  }, [searchParams]);

  const { data, isLoading, isFetchingNextPage, fetchNextPage, hasNextPage } =
    useInvoices(filters);

  const { ref, inView } = useInView({ rootMargin: "500px" });

  useEffect(() => {
    if (inView) {
      fetchNextPage();
    }
  }, [inView, fetchNextPage]);

  return (
    <Box>
      <Filters />
      {isLoading ? (
        <Box
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        height={"calc( 100vh - 160px)"}
        >
          <Loadings />
        </Box>
      ) : (
        <TableContainer sx={{ maxHeight: "calc( 100vh - 160px)" }}>

          <Table stickyHeader aria-label="simple table">
            <TableHead>
              <TableRow
                 sx={{
                  "& > th ": {
                    fontWeight: 700,
                    backgroundColor: "#514943",
                    color: "white",
                    borderRight: "1px solid #8A837F",
                  },
                }}
              >
                <TableCell>Invoice</TableCell>
                <TableCell>Invoice Date</TableCell>
                {/* <TableCell>Order #</TableCell>
                <TableCell>Order Date</TableCell>
                <TableCell>Bill-to Name</TableCell>
                <TableCell>Status</TableCell> */}
                <TableCell>Grand Total (Base)</TableCell>
                <TableCell>Grand Total (Purchased)</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody  sx={{
                        "& > tr": {
                          border: 0 ,
                        },
                        "& > tr:nth-of-type(even)": {
                          backgroundColor: "#F5F5F5",
                        },
                      }}>
              {data?.pages?.map((page: any) => (
                <React.Fragment key={page.currentPage}>
                  {page?.items?.map((invoice: IMInvoiceItem) => (
                    <TableRow
                      key={invoice.brand + invoice.increment_id}
                      sx={{ "& > td ": { borderRight: "1px dashed #d6d6d6" } }}
                    >
                      <TableCell scope="row">{invoice.increment_id}</TableCell>
                      <TableCell align="left">{invoice.created_at}</TableCell>
                      {/* <TableCell>{invoice.order_id}</TableCell>
                      <TableCell>
                        {invoice.updated_at}
                      </TableCell>
                      <TableCell>
                        {"c6f4ef0c73533dc293d487232dd64b31"}
                      </TableCell>
                      <TableCell >
                        {"Status"}
                      </TableCell> */}
                      <TableCell >
                        {CURRENCY[invoice?.base_currency_code]}{" "}
                        {formatePrice(invoice?.base_grand_total)}
                      </TableCell>
                      <TableCell>
                      {CURRENCY[invoice?.base_currency_code]}{" "}
                        {formatePrice(invoice?.grand_total)}
                      </TableCell>                      
                      <TableCell>
                        <a
                          target={"_blank"}
                          href={`/invoicedetail/${invoice.brand}/${invoice.order_id}/${invoice.entity_id}`}
                          rel="noreferrer"
                        >
                          View
                        </a>
                      </TableCell>
                    </TableRow>
                  ))}
                </React.Fragment>
              ))}
              {hasNextPage && (
                <TableRow
                  ref={ref}
                  sx={{ height: "248px" }}
                  hidden={!hasNextPage}
                >
                  <TableCell colSpan={99} align="center">
                    {isFetchingNextPage && (
                      <Box display={"flex"} justifyContent={"center"}>
                        <Loadings />
                      </Box>
                    )}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Box>
  );
};

export default Invoice;
