import React, { FC, useEffect, useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import { useInView } from "react-intersection-observer";
import {
  Avatar,
  Box,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Typography,
} from "@mui/material";
import { useSelector } from "react-redux";

import { IMSkuItem } from "../../models/Sku";

import { useSkus } from "../../hooks/useSkus";

// import Filters from "./Filters";
import Loadings from "../../compoment/common/Loadings";
import {
  BRANDS,
  CURRENCY,
  BRANDS_SKU,
  IMAGE_FROM_WEBSITES,
  SKU_VISIBILITY,
  SKU_STATUS,
} from "../../utils/constant";
import { formatePrice } from "../../utils/common";
import dayjs, { Dayjs } from "dayjs";
import StatisticsByHour from "./statisticsByHour";
import StatisticsByBrand from "./statisticsByBrand";
import StatisticsByPeriod from "./statisticsByPeriod";
import {
  selectIsLogged,
  selectWaitAuthCheck,
  setUser,
  selectUser,
} from "../../redux/slices/authSlice";
import StatisticsByHourChart from "./statisticsByHourChart";
import StatisticsByBrandChart from "./statisticsByBrandChart";
import StatisticsByPeriodChart from "./statisticsByPeriodChart";
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const Statistics: FC = () => {
  // const [searchParams, setSearchParams] = useSearchParams();

  // const filters = useMemo<{
  //   //  id: string;
  //   sku: string;
  //   name: string;
  //   brands: string[];
  //   start_date: Dayjs;
  //   end_date: Dayjs;
  // }>(() => {
  //   const filters = {
  //     // id: searchParams.get("id") || "",
  //     sku: searchParams.get("sku") || "",
  //     name: searchParams.get("name") || "",
  //     brands: searchParams.get("brands")?.split(",") || BRANDS,
  //     start_date: dayjs(searchParams.get("start_date") || "", "YYYY-MM-DD"),
  //     end_date: dayjs(searchParams.get("end_date") || "", "YYYY-MM-DD"),
  //   };

  //   return filters;
  // }, [searchParams]);

  // const { data, isLoading, isFetchingNextPage, fetchNextPage, hasNextPage } =
  //   useSkus(filters);

  // const { ref, inView } = useInView({ rootMargin: "500px" });

  // useEffect(() => {
  //   if (inView) {
  //     fetchNextPage();
  //   }
  // }, [inView, fetchNextPage]);
  const currentUser = useSelector(selectUser);
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  return (
    <Box>
      {currentUser && currentUser.user_role === "administrator" ? (
        <Box>
          {/* <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab label="Table View" {...a11yProps(0)} />
              <Tab label="Chart View" {...a11yProps(1)} />
            </Tabs>
          </Box> */}
          {/* <CustomTabPanel value={value} index={0}> */}
            <StatisticsByHour />
            <StatisticsByBrand />
            <StatisticsByPeriod />
          {/* </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            <StatisticsByHourChart />
            <StatisticsByBrandChart />
            <StatisticsByPeriodChart />
          </CustomTabPanel> */}
        </Box>
      ) : (
        <Box sx={{ textAlign: "center", marginTop: "200px" }}>
          <Typography variant="h6">
            You can't access this Page. Because you have no pemission.
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default Statistics;
